/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import DownloadReportCard from "./DownloadReportCard.jsx";
import UploadReportCard from "./UploadReportCard.jsx";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { getCookie, handleLogout } from "../../common/utils/index.js";
import { toast } from "react-toastify";
import SvgLoader from "../../common/SvgLoader/index.js";
import moment from "moment";
import LineChart from "./LineChart.jsx";

export default function ClientAdminReports() {
  const [reportData, setReportData] = useState();
  const role = getCookie("roles");

  let colors = ["#603FDD", "#603FDDE5", "#603FDDBF", "#603FDD99", "#603FDD73"];

  useEffect(() => {
    if (role?.includes("BMQ Admin")) {
      axios
        .get(
          `${process.env.apiUrl}report?client_id=a9f3e741b2e24c7fa3498d59c49e6d30`,
          {
            headers: {
              Authorization: getCookie("accessToken"),
            },
          }
        )
        .then((response) => {
          console.log(response?.data?.response);
          setReportData(response?.data?.response);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) {
            toast.error("Session Expired. Please login again");
            handleLogout();
          }
        });
    } else if (role?.includes("Client Admin")) {
      axios
        .get(`${process.env.apiUrl}clientReport`, {
          headers: {
            Authorization: getCookie("accessToken"),
          },
        })
        .then((response) => {
          console.log(response?.data?.response);
          setReportData(response?.data?.response);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) {
            toast.error("Session Expired. Please login again");
            handleLogout();
          }
        });
    }
  }, []);

  const top5GenreChartConfig = {
    options: {
      chart: {
        type: "pie",
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val.toFixed(1) + "%";
        },
        dropShadow: {},
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: "40%",
            background: "transparent",
            // labels: {
            //   show: false,
            //   name: {
            //     show: true,
            //     fontSize: '22px',
            // fontFamily: "Montserrat, sans-serif",
            //     fontWeight: 600,
            //     color: undefined,
            //     offsetY: -10,
            //     formatter: function (val) {
            //       return val;
            //     },
            //   },
            //   value: {
            //     show: true,
            //     fontSize: '16px',
            // fontFamily: "Montserrat, sans-serif",
            //     fontWeight: 400,
            //     color: undefined,
            //     offsetY: 16,
            //     formatter: function (val) {
            //       return val;
            //     },
            //   },
            //   total: {
            //     show: false,
            //     showAlways: false,
            //     label: 'Total',
            //     fontSize: '22px',
            // fontFamily: "Montserrat, sans-serif",
            //     fontWeight: 600,
            //     color: '#373d3f',
            //     formatter: function (w) {
            //       return w.globals.seriesTotals.reduce((a, b) => {
            //         return a + b;
            //       }, 0);
            //     },
            //   },
            // },
          },
        },
      },
      fill: {
        // colors: undefined,
        // var colors = ['#603FDD', '#603FDDE5', '#603FDDBF', '#603FDD99', '#603FDD73'];
        colors: ["#603FDD", "#603FDD", "#603FDD", "#603FDD", "#603FDD"],
        // colors: [
        //   function ({ value, seriesIndex, w }) {
        //     console.log(value, seriesIndex, w)
        //     if (value < 55) {
        //       return '#603FDD80';
        //     } else if (value >= 55 && value < 80) {
        //       return '#603FDD60';
        //     } else {
        //       return '#603FDD40';
        //     }
        //   },
        // ],
        // opacity: 0.9,
        opacity: [1, 0.9, 0.75, 0.6, 0.45],
        type: "solid",
        // gradient: {
        //   shade: 'dark',
        //   type: 'horizontal',
        //   shadeIntensity: 0.5,
        //   gradientToColors: undefined,
        //   inverseColors: true,
        //   opacityFrom: 1,
        //   opacityTo: 1,
        //   stops: [0, 50, 100],
        //   colorStops: [],
        // },
        // image: {
        //   src: [],
        //   width: undefined,
        //   height: undefined,
        // },
        // pattern: {
        //   style: 'verticalLines',
        //   width: 6,
        //   height: 6,
        //   strokeWidth: 2,
        // },
      },
      stroke: {
        show: false, // Disables stroke on chart elements
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "right",
        horizontalAlign: "center",
        floating: false,
        fontSize: "13px",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 700,
        formatter: undefined,
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: 0,
        labels: {
          colors: "#fff",
        },
        markers: {
          size: 7,
          shape: undefined,
          strokeWidth: 0,
          offsetX: -4,
          offsetY: 0,
        },
        itemMargin: {
          horizontal: 5,
          vertical: 8,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          console.log(w);
          console.log(series);
          return `
            <div class="arrow_box"> 
                <span>
                    ${w.globals.labels[seriesIndex]} 
                        : 
                    ${series[seriesIndex]} Tracks
                </span>
            </div>`;
        },
      },
      colors: colors,
      labels: reportData?.top_genres
        ?.slice(0, 5)
        ?.map((item, index) => item?.genre__name),
    },
    series: reportData?.top_genres
      ?.slice(0, 5)
      ?.map((item, index) => item?.track_count),
  };

  const top5MoodsChartConfig = {
    options: {
      series: [
        {
          name: "Moods",
          data: reportData?.top_moods_listening
            ?.slice(0, 5)
            ?.map((item, index) => item?.count_of_plays),
        },
      ],
      chart: {
        type: "bar",
        height: 100,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 10,
          borderRadiusApplication: "around",
          borderRadiusWhenStacked: "last",
          // columnWidth: "100%",
          barHeight: "25px",
          distributed: false,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          dumbbellColors: undefined,
          isFunnel: false,
          isFunnel3d: true,
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: undefined,
              },
            ],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 0,
          },
          dataLabels: {
            position: "top",
            maxItems: 100,
            hideOverflowingLabels: false,
            orientation: "horizontal",
            total: {
              enabled: false,
              formatter: undefined,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 600,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "end",
        formatter: function (val, opt) {
          // console.log(opt.w.globals);
          return (
            opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            //+ " " +
            // "listenings"
          );
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
        style: {
          fontSize: "14px",
          colors: ["#fff"],
          fontFamily: "Montserrat, sans-serif",
          textTransform: "capitalize",
        },
      },
      xaxis: {
        categories: reportData?.top_moods_listening
          ?.slice(0, 5)
          ?.map((item, index) => item?.mood_name),
        labels: {
          show: false,
          style: {
            colors: "#000", // Customize x-axis label color
            fontFamily: "Montserrat, sans-serif",
          },
        },
        axisBorder: {
          show: false, // Hide x-axis border
        },
        axisTicks: {
          show: false, // Hide x-axis ticks
        },
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: "#000", // Customize y-axis label color
          },
        },
      },
      stroke: {
        width: 0,
        colors: ["#fff"],
      },
      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
            <div class="arrow_box"> 
                <span>
                    ${w.globals.labels[dataPointIndex]} 
                        : 
                    ${series[seriesIndex][dataPointIndex]} listenings
                </span>
            </div>`;
        },
      },
      colors: colors,
    },
  };

  return (
    <div
      className="client-admin-reports-container"
      style={{ marginTop: "20px" }}
    >
      {reportData && (
        <Row gutter={16}>
          <Col span={19}>
            <Row gutter={[16, 16]} className="">
              <Col span={10}>
                <Card
                  className="top-genre"
                  title="Top 5 Genre"
                  // extra={<a href="#">More</a>}
                  style={{ height: "100%" }}
                >
                  <ReactApexChart
                    type="donut"
                    // height={400}
                    width={450}
                    options={top5GenreChartConfig?.options}
                    series={top5GenreChartConfig?.series}
                  />
                </Card>
              </Col>
              <Col span={14}>
                {" "}
                <Card
                  className="top-moods"
                  title="Top 5 Moods (listenings)"
                  // extra={<a href="#">More</a>}
                >
                  <ReactApexChart
                    type="bar"
                    height={330}
                    // width={700}
                    options={top5MoodsChartConfig?.options}
                    series={top5MoodsChartConfig?.options?.series}
                  />
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  className="top-genre"
                  title="Downloaded"
                  // extra={<a href="#">More</a>}
                  style={{ height: "100%" }}
                >
                  <LineChart
                    reportData={reportData?.monthly_downloads}
                    name={"Downloads"}
                  />
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  className="top-genre"
                  title="Uploaded"
                  // extra={<a href="#">More</a>}
                  style={{ height: "100%", width: "100%" }}
                >
                  <LineChart
                    reportData={reportData?.monthly_uploads}
                    name={"Uploads"}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={5}>
            <Card className="top-genre" style={{ height: "100%" }}>
              <div className="stats-card-wrap">
                <div className="client-logo-wrap">
                  {/* <img src="/images/infosysLogo.png" alt="" /> */}
                  {/* <SvgLoader svgName="infosys-Logo" height={300} width={300} /> */}
                  <div className="summary-title">Summary statistics</div>
                </div>

                <div className="stats-wrap">
                  <div className="stats-value">
                    {reportData?.stats?.play_count}
                  </div>
                  <div className="stats-title">Total play count</div>
                </div>
                <div className="stats-wrap">
                  <div className="stats-value">
                    {reportData?.stats?.total_track_count}
                  </div>
                  <div className="stats-title">Unique tracks</div>
                </div>
                <div className="stats-wrap">
                  <div className="stats-value">
                    {reportData?.stats?.genres_count}
                  </div>
                  <div className="stats-title">Different Genre</div>
                </div>
              </div>
            </Card>{" "}
          </Col>
        </Row>
      )}
    </div>
  );
}
