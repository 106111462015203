/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-constant-condition */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
    Checkbox,
    Collapse,
    Dropdown,
    Menu,
    Pagination,
    Slider,
    Tooltip,
} from "antd";
import Search from "antd/es/transfer/search";
import { MdSort, MdOutlineKeyboardBackspace } from "react-icons/md";
import { GoHeartFill, GoHeart } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    getTrackListAction,
    getGenresListAction,
    getThemesListAction,
    getMoodsListAction,
} from "./logic";
import TrackCard from "../../components/TrackCard";
import TopNavigation from "../../components/TopNavigation/TopNavigation.jsx";
import { GrPowerReset } from "react-icons/gr";
import Loader from "../../components/Loader/Loader.jsx";

const ExplorePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    // const [genreId, setGenreId] = useState();
    const [orderBy, setOrderBy] = useState();
    const [pageNo, setPageNo] = useState(1);
    const [search /* setSearch */] = useState(
        location?.state?.trackId ? location?.state?.trackId : ""
    );
    const [selectedMood, setSelectedMood] = useState(
        location?.state?.moodId ? location?.state?.moodId : []
    );
    const [selectedTheme, setSelectedTheme] = useState(
        location?.state?.themeId ? location?.state?.themeId : []
    );
    const [selectedCharacter /* setSelectedCharacter */] = useState(
        location?.state?.characterId ? location?.state?.characterId : []
    );
    const [selectedGenre, setSelectedGenre] = useState(
        location?.state?.genreId ? location?.state?.genreId : []
    );
    const [resetList, setResetList] = useState(false);
    const [BPMvalue, setBPMvalue] = useState(
        location?.state?.bpmValue || [0, 300]
    );
    const [likeFilter, setLikeFilter] = useState(false);
    const [titleFlag, setTitleFlag] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [allFilterData, setAllFilterData] = useState();
    const [filteredData, setFilteredData] = useState();
    const [resetFlag, setResetFlag] = useState(false);
    const [visible, setVisible] = useState(false);

    const { data: genresList } = useSelector((state) => state?.genresList);
    const { data: themesList } = useSelector((state) => state?.themesList);
    const { data: moodsList } = useSelector((state) => state?.moodsList);

    useEffect(() => {
        dispatch(getGenresListAction({}));
        dispatch(getThemesListAction({}));
        dispatch(getMoodsListAction({}));
    }, []);

    useEffect(() => {
        // const selectedMoodFormatted = selectedMood.map((eachMood) => {
        //     return encodeURIComponent(eachMood)
        // })
        // const selectedGenreFormatted = selectedGenre.map((eachGenre) => {
        //     return encodeURIComponent(eachGenre)
        // })
        // const selectedThemeFormatted = selectedTheme.map((eachTheme) => {
        //     return encodeURIComponent(eachTheme)
        // })

        const data = {
            order_by: orderBy,
            page: pageNo,
            search: search,
            mood: selectedMood?.join(", "),
            theme: selectedTheme?.join(", "),
            genre: selectedGenre?.join(", "),
            min_bpm: BPMvalue[0],
            max_bpm: BPMvalue[1],
            is_liked_filter: likeFilter,
            character: selectedCharacter,
        };
        dispatch(getTrackListAction(data));
    }, [
        location,
        orderBy,
        pageNo,
        BPMvalue,
        selectedTheme,
        selectedGenre,
        selectedMood,
        resetList,
        likeFilter,
    ]);

    useEffect(() => {
        titleFlag &&
            navigate(location.pathname, {
                state: {
                    genreId: selectedGenre,
                    moodId: selectedMood,
                    themeId: selectedTheme,
                    bpmValue: BPMvalue,
                    title: "All Tracks",
                },
                replace: true,
            });
        setTitleFlag(true);
    }, [selectedTheme, selectedGenre, selectedMood, BPMvalue]);

    const { data: trackListData, loading: trackListDataLoading } = useSelector(
        (state) => state.trackListData
    );

    // useEffect(() => {
    //     console.log("object");
    //     resetFlag && (
    //         // setTimeout(() => {

    //         setResetFlag(false)
    //         // }, 2000)
    //     )

    // }, [resetFlag]);

    useEffect(() => {
        setAllFilterData({
            moodTopic: moodsList?.response?.moods,
            themeTopic: themesList?.response?.themes,
            genresTopic: genresList?.response?.genres,
        });
        setFilteredData({
            moodTopic: moodsList?.response?.moods,
            themeTopic: themesList?.response?.themes,
            genresTopic: genresList?.response?.genres,
        });
    }, [themesList, moodsList, genresList, resetFlag]);

    const onSearchSideFilter = (e) => {
        setSearchText(e);
        const searchValue = e.toLowerCase();

        const filtered = Object.fromEntries(
            Object.entries(allFilterData).map(([key, value]) => [
                key,
                searchValue
                    ? value.filter((item) =>
                        item.name.toLowerCase().includes(searchValue)
                    )
                    : value,
            ])
        );

        setFilteredData(filtered);
    };

    const onChangeComplete = (value) => {
        setBPMvalue(value);
        setPageNo(1);
    };

    const items = [
        {
            key: "Mood",
            label: "Mood",
            children: (
                <div>
                    {filteredData?.moodTopic?.length > 0 ? (
                        <>
                            {filteredData?.moodTopic?.map(
                                (item) =>
                                    selectedMood.includes(item?.name) && (
                                        <Checkbox
                                            key={item?.name}
                                            className="collapse-checkbox"
                                            onChange={(e) => onChangeMoodTopic(e, item?.name)}
                                            checked={selectedMood.includes(item?.name)}
                                        >
                                            {item?.name}
                                        </Checkbox>
                                    )
                            )}

                            {filteredData?.moodTopic?.map(
                                (item) =>
                                    !selectedMood.includes(item?.name) && (
                                        <Checkbox
                                            key={item?.name}
                                            className="collapse-checkbox"
                                            onChange={(e) => onChangeMoodTopic(e, item?.name)}
                                            checked={selectedMood.includes(item?.name)}
                                        >
                                            {item?.name}
                                        </Checkbox>
                                    )
                            )}
                        </>
                    ) : (
                        <div className="bpm-count">No Results</div>
                    )}
                </div>
            ),
        },

        {
            key: "genre",
            label: "Genre",
            children: (
                <div>
                    {filteredData?.genresTopic?.length > 0 ? (
                        <>
                            {filteredData?.genresTopic?.map(
                                (item) =>
                                    selectedGenre.includes(item?.name) && (
                                        <Checkbox
                                            key={item?.name}
                                            className="collapse-checkbox"
                                            onChange={(e) => onChangeGenreTopic(e, item?.name)}
                                            checked={selectedGenre.includes(item?.name)}
                                        >
                                            {item?.name}
                                        </Checkbox>
                                    )
                            )}

                            {filteredData?.genresTopic?.map(
                                (item) =>
                                    !selectedGenre.includes(item?.name) && (
                                        <Checkbox
                                            key={item?.name}
                                            className="collapse-checkbox"
                                            onChange={(e) => onChangeGenreTopic(e, item?.name)}
                                            checked={selectedGenre.includes(item?.name)}
                                        >
                                            {item?.name}
                                        </Checkbox>
                                    )
                            )}
                        </>
                    ) : (
                        <div className="bpm-count">No Results</div>
                    )}
                </div>
            ),
        },
        {
            key: "BPM",
            label: "BPM",
            children: (
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <div className="bpm-count">{BPMvalue[0]}</div>
                    <Slider
                        range
                        defaultValue={BPMvalue}
                        value={resetFlag ? BPMvalue : undefined}
                        max={300}
                        min={0}
                        style={{ width: "100%" }}
                        onChangeComplete={onChangeComplete}
                    />
                    <div className="bpm-count">
                        {BPMvalue[1] === 300 ? BPMvalue[1] + "+" : BPMvalue[1]}
                    </div>
                </div>
            ),
        },
        {
            key: "theme",
            label: "Purpose",
            children: (
                <div>
                    {filteredData?.themeTopic?.length > 0 ? (
                        <>
                            {filteredData?.themeTopic?.map(
                                (item) =>
                                    selectedTheme.includes(item?.name) && (
                                        <Checkbox
                                            key={item?.name}
                                            className="collapse-checkbox"
                                            onChange={(e) => onChangeThemeTopic(e, item?.name)}
                                            checked={selectedTheme.includes(item?.name)}
                                        >
                                            {item?.name}
                                        </Checkbox>
                                    )
                            )}

                            {filteredData?.themeTopic?.map(
                                (item) =>
                                    !selectedTheme.includes(item?.name) && (
                                        <Checkbox
                                            key={item?.name}
                                            className="collapse-checkbox"
                                            onChange={(e) => onChangeThemeTopic(e, item?.name)}
                                            checked={selectedTheme.includes(item?.name)}
                                        >
                                            {item?.name}
                                        </Checkbox>
                                    )
                            )}
                        </>
                    ) : (
                        <div className="bpm-count">No Results</div>
                    )}
                </div>
            ),
        },
    ];

    const onChangeMoodTopic = (e, idx) => {
        setPageNo(1);

        if (!e?.target?.checked) {
            setSelectedMood((prev) => prev.filter((item) => item !== idx));
        } else {
            setSelectedMood((prev) => [...prev, idx]);
        }
    };

    const onChangeThemeTopic = (e, idx) => {
        setPageNo(1);

        if (!e?.target?.checked) {
            setSelectedTheme((prev) => prev.filter((item) => item !== idx));
        } else {
            setSelectedTheme((prev) => [...prev, idx]);
        }
    };

    const onChangeGenreTopic = (e, idx) => {
        setPageNo(1);

        if (!e?.target?.checked) {
            setSelectedGenre((prev) => prev.filter((item) => item !== idx));
        } else {
            setSelectedGenre((prev) => [...prev, idx]);
        }
    };
    const handleResetFilters = () => {
        setSearchText("");
        setSelectedGenre([]);
        setSelectedMood([]);
        setSelectedTheme([]);
        setBPMvalue([0, 300]);
        setResetFlag(true);
        navigate(location.pathname, {
            replace: true,
        });
        setTimeout(() => {
            setResetFlag(false);
        }, 1000);
        setPageNo(1);
    };

    const handleClose = () => {
        setVisible(false);
    };

    const menuItems = [
        {
            key: "ASC",
            label: "Oldest to Newest",
            onClick: () => {
                setOrderBy("ASC");
            },
        },
        {
            key: "DESC",
            label: "Newest to Oldest",
            onClick: () => {
                setOrderBy("DESC");
            },
        },
    ];

    const { data: getPlayListData } = useSelector(
        (state) => state.getPlayListData
    );

    const handlePageChange = (page, pageSize) => {
        setPageNo(page);
    };

    function getMergedLength(arr1, arr2, arr3) {
        const mergedArray = [...arr1, ...arr2, ...arr3].filter(
            (item) => item !== undefined && item !== ""
        );
        return mergedArray.length;
    }

    return (
        <div className="home-page">
            <Header showSearch={true} />
            <div className="home-page-components">
                <TopNavigation />
            </div>
            <div className="home-page-components">
                <div className="menu-bar">
                    <div className="back-btn" onClick={() => navigate(-1)}>
                        <MdOutlineKeyboardBackspace />
                        Back
                    </div>
                    <div className="collapse-wraper">
                        <div className="search-reset-wraper">
                            <Search
                                placeholder=""
                                allowClear
                                value={searchText}
                                onChange={(e) => onSearchSideFilter(e.target.value)}
                                className="search-field"
                            />
                            <Tooltip title="Reset Selections" color="#dcaa3a">
                                <GrPowerReset
                                    size={30}
                                    style={{ cursor: "pointer" }}
                                    onClick={handleResetFilters}
                                />
                            </Tooltip>
                        </div>
                        <Collapse
                            className="filter-collapse"
                            defaultActiveKey={"Mood"}
                            expandIconPosition={"end"}
                            ghost
                            accordion
                            items={items}
                        />
                    </div>
                </div>
                <div className="home-content" style={{ height: "auto" }}>
                    <div className="genre-detail-wrap">
                        <div className="filters">
                            <span className="heading">
                                {getMergedLength(selectedGenre, selectedTheme, selectedMood) ===
                                    1
                                    ? location?.state?.title
                                        ? location?.state?.title
                                        : "All Tracks"
                                    : "All Tracks"}
                            </span>

                            <Dropdown
                                overlay={<Menu onClick={handleClose} items={menuItems} />}
                                visible={visible}
                                onVisibleChange={setVisible}
                                trigger={["click"]}
                                className="sort-by-filter"
                            >
                                <span className="chip-btn">
                                    <MdSort />
                                    <span>Sort by</span>
                                </span>
                            </Dropdown>
                            {/* <span className="chip-btn">
                                <HiPlay />
                                Play all
                            </span> */}
                            {/* <span className="chip-btn">
                                <SiApplemusic />
                                Add to collection
                            </span> */}
                            <span
                                className="chip-btn"
                                style={{ padding: "10px" }}
                                onClick={() => setLikeFilter((prev) => !prev)}
                            >
                                {likeFilter ? (
                                    <GoHeartFill
                                        size={17}
                                        color={"#ff0000"}
                                        className="all-icons"
                                    />
                                ) : (
                                    <GoHeart size={17} color={"#fff"} className="all-icons" />
                                )}
                            </span>
                        </div>
                        {trackListDataLoading ? (
                            <Loader height={0} marginTop={0} />
                        ) : (
                            trackListData && (
                                <TrackCard
                                    getPlayListData={getPlayListData}
                                    trackListData={trackListData.data}
                                    resetList={resetList}
                                    setResetList={setResetList}
                                    type="default"
                                />
                            )
                        )}
                        {console.log({ pageNo })}
                        <Pagination
                            size="small"
                            onChange={handlePageChange}
                            // defaultCurrent={pageNo}
                            // defaultCurrent={1}
                            current={pageNo}
                            total={trackListData?.total_count || 0}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExplorePage;
