import React, { useState } from "react";
import SvgLoader from "../../common/SvgLoader";
import { getCookie, handleLogout } from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { AutoComplete } from "antd";
import axios from "axios";
import { toast } from "react-toastify";

let timeOut;
function Header({ showSearch }) {
  const navigate = useNavigate();
  const role = getCookie("roles");

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);


  const getPanelValue = (searchText) => {
    axios
      .get(`${process.env.apiUrl}search?search=${searchText}`, {
        headers: {
          Authorization: getCookie("accessToken"),
        },
      })
      .then((response) => {



        const data = response?.data?.data.map((item) => ({
          label: `${Object.keys(item)} : ${Object.values(item)}`,
          value: `${Object.keys(item)} : ${Object.values(item)}`,
        }));

        setOptions(data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          toast.error("Session Expired. Please login again");
          handleLogout();
        }
        console.log(err);
      });

  }

  const onSelect = (data) => {

    const [key, value] = data.split(" : ");

    const jsonObject = {
      [key]: value
    };

    const reloadOnExplore = () => {
      if (window.location.pathname == "/explore") {
        window.location.reload()
      }
    }

    if (Object.keys(jsonObject)[0] === "Genre") {
      reloadOnExplore()
      navigate("/explore", {
        state: { genreId: Object.values(jsonObject)[0], title: `Genre: ${Object.values(jsonObject)[0]}` },
      })
    }

    if (Object.keys(jsonObject)[0] === "Mood") {
      reloadOnExplore()
      navigate("/explore", {
        state: { moodId: Object.values(jsonObject)[0], title: `Mood: ${Object.values(jsonObject)[0]}` },
      })
    }

    if (Object.keys(jsonObject)[0] === "Theme") {
      reloadOnExplore()
      navigate("/explore", {
        state: { themeId: Object.values(jsonObject)[0], title: `Purpose: ${Object.values(jsonObject)[0]}` },
      })
    }

    if (Object.keys(jsonObject)[0] === "Character") {
      reloadOnExplore()
      navigate("/explore", {
        state: { characterId: Object.values(jsonObject)[0], title: `Character: ${Object.values(jsonObject)[0]}` },
      })
    }

    if (Object.keys(jsonObject)[0] === "Track") {
      reloadOnExplore()
      navigate("/explore", {
        state: { trackId: Object.values(jsonObject)[0], title: `Track: ${Object.values(jsonObject)[0]}` },
      })
    }
  };

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <div className="header">
      <SvgLoader
        svgName="soniq-vault-logo"
        width={110}
        height={90}
        className="soniq-vault-logo"
        onClick={() => (role?.includes("Client Admin") ||
          role?.includes("Client User") ||
          role?.includes("Agency User")) ? navigate("/home") : ""}
      />
      <div className="search-box" style={{ visibility: showSearch ? "visible" : "hidden" }}>

        <AutoComplete
          value={value}
          options={options}
          style={{
            width: "100%",
          }}
          allowClear
          popupClassName="header-search-dropdown"
          onSelect={onSelect}
          onSearch={(text) => {
            clearTimeout(timeOut);
            if (text) {
              timeOut = setTimeout(() => {
                getPanelValue(text);
              }, 500);
            } else {
              setOptions([])
            }
          }}
          onChange={onChange}
          placeholder="Search Genre, Moods...."
          className="search-field"
        />
      </div>
      <div className="user-details" style={{ visibility: showSearch ? "visible" : "hidden" }}>
        <div className="user-name">{getCookie("user")}</div>
        <div className="logo">
          {/* <SvgLoader svgName="no-image-found" width={50} height={50} /> */}
        </div>
        {/* <Badge count={5} size="small" offset={[-10, 0]}>
          <HiOutlineBell color="#fff" size={40} />
        </Badge> */}
      </div>
    </div>
  );
}

export default Header;
