import { Menu } from "antd";
import React from "react";
import { getCookie, handleLogout } from "../../common/utils";
import { UploadOutlined } from "@ant-design/icons";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { FiFilePlus } from "react-icons/fi";
import { FaRegCompass } from "react-icons/fa";
import { RiHome5Line } from "react-icons/ri";
import { SlLogout } from "react-icons/sl";
import { BsFileMusic } from "react-icons/bs";

const TopNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const role = getCookie("roles");
  const userPermission = getCookie("permissions");

  const items = [
    {
      key: "home",
      icon: <RiHome5Line size={25} />,
      label: "Home",
    },
    {
      key: "search",
      icon: <FaRegCompass size={25} />,
      label: "Search",
    },
    {
      key: "explore",
      icon: <FaRegCompass size={25} />,
      label: "Advanced Search",
    },
    {
      key: "my-gallery",
      icon: <BsFileMusic size={25} />,
      label: "My Vault",
    },
    userPermission?.includes("request_brief_edit_stem") && {
      key: "request-new-track",
      icon: <FiFilePlus size={25} />,
      label: "Spark",
    },
    userPermission?.includes("upload_track") && {
      key: "uploaded-tracks",
      icon: <UploadOutlined style={{ fontSize: "25px" }} />,
      label: "Store",
    },
    (role?.includes("Client Admin") ||
      role?.includes("BMQ Admin") ||
      role?.includes("BMQ User")) && {
      key: "admin",
      icon: <MdOutlineAdminPanelSettings size={25} />,
      label: "Admin Panel",
    },
    {
      key: "logout",
      icon: <SlLogout size={30} />,
      label: "",
    },
  ];
  const onClick = (e) => {
    if (e.key === "home") {
      navigate("/home");
    }
    if (e.key === "search") {
      navigate("/search");
    }
    if (e.key === "explore") {
      navigate("/explore", {
        state: { genreId: "", title: "All Tracks" },
      });
    }
    if (e.key === "my-gallery") {
      navigate("/my-gallery");
    }
    if (e.key === "request-new-track") {
      navigate("/request-new-track");
    }
    if (e.key === "uploaded-tracks") {
      navigate("/uploaded-tracks");
    }
    if (e.key === "admin") {
      navigate("/admin");
    }
    if (e.key === "logout") {
      // navigate("/login");
      handleLogout();
    }
  };
  return (
    <div className="menu-bar" style={{ width: "100%" }}>
      <Menu
        onClick={onClick}
        defaultSelectedKeys={[location?.pathname?.slice(1)]}
        // defaultOpenKeys={["sub1"]}
        mode="horizontal"
        items={items}
        className="menu"
      />
    </div>
  );
};

export default TopNavigation;
