import React from "react";
import { ProgressBar } from "react-loader-spinner";

export default function Loader({ height, marginTop }) {
  return (
    <div
      className="custom-spin"
      style={{ height: `${height}vh`, marginTop: `${marginTop}vh` }}
    >
      <ProgressBar
        visible={true}
        height="110"
        width="110"
        barColor="#dcaa3a"
        borderColor="#D3DEF152"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
}
