/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */

import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Steps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import SvgLoader from "../../common/SvgLoader";
import { useDispatch, useSelector } from "react-redux";
import {
    getCharactersListAction,
    getGenresListAction,
    getMoodsListAction,
    getThemesListAction,
} from "../explorePage/logic";
import axios from "axios";
import { getCookie, handleLogout } from "../../common/utils";
import { toast } from "react-toastify";
import moment from "moment";
import TopNavigation from "../../components/TopNavigation/TopNavigation.jsx";

const RequestNewTrack = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userPermission = getCookie("permissions");

    const [stage, setStage] = useState(0);
    const [stepForm] = Form.useForm();
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (!userPermission?.includes("request_brief_edit_stem")) {
            navigate("/home");
        }
    }, []);

    useEffect(() => {
        dispatch(getGenresListAction({}));
        dispatch(getThemesListAction({}));
        dispatch(getMoodsListAction({}));
        dispatch(getCharactersListAction({}));
    }, []);

    const { data: genresList } = useSelector((state) => state?.genresList);
    const { data: moodsList } = useSelector((state) => state?.moodsList);
    const { data: charactersList } = useSelector(
        (state) => state?.charactersList
    );
    const { data: themesList } = useSelector((state) => state?.themesList);


    const [genresListOptions, setGenresListOptions] = useState();
    const [moodsListOptions, setMoodsListOptions] = useState();
    const [characterListOptions, setCharacterListOptions] = useState();
    const [themesListOptions, setThemesListOptions] = useState();
    const [firstDate, setFirstDate] = useState();
    const [finalDate, setFinalDate] = useState();

    const [syncNeeded, setSyncNeeded] = useState();
    const [stemsNeeded, setStemsNeeded] = useState();
    const [editsNeeded, setEditsNeeded] = useState();

    useEffect(() => {
        const genresMoods = genresList?.response?.genres?.map((genres) => ({
            label: genres.name,
            value: genres.id,
        }));

        setGenresListOptions(genresMoods);

        const optionsMoods = moodsList?.response?.moods?.map((mood) => ({
            label: mood.name,
            value: mood.id,
        }));
        setMoodsListOptions(optionsMoods);

        const charactersMoods = charactersList?.response?.characters?.map(
            (characters) => ({
                label: characters.name,
                value: characters.id,
            })
        );
        setCharacterListOptions(charactersMoods);

        const themesMoods = themesList?.response?.themes?.map(
            (themes) => ({
                label: themes.name,
                value: themes.id,
            })
        );
        setThemesListOptions(themesMoods);


    }, [moodsList, genresList, charactersList, themesList]);

    const handleNext = () => {
        stepForm
            .validateFields()
            .then((values) => {
                if (
                    "purpuse" in values &&
                    (!values?.purpuse || values?.purpuse?.length === 0)
                ) {
                    toast.error("Please select mandatory fields", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    return null;
                }

                // {Object.values(values).includes(undefined)}
                setStage(stage + 1);
                setFormData((prev) => ({ ...prev, ...values }));
            })
            .catch((errorInfo) => {
                console.log("Failed:", errorInfo);
            });
    };
    // const navigateCall = () => {
    //     setTimeout(() => {
    //         navigate("/home");
    //     }, [2000]);
    // };
    const finalFormData = new FormData();
    const onFinish = () => {
        const finalFormData = new FormData();

        stepForm
            .validateFields()
            .then((values) => {

                console.log({ values });
                if (!values?.deadline_final && !values?.deadline_first) {
                    toast.error("Please select mandatory fields");
                    return null;
                }
                setFormData((prev) => {
                    const updatedData = { ...prev, ...values };

                    finalFormData.append("purposes", updatedData?.purpuse || "");
                    finalFormData.append("durations", updatedData?.duration || 0);
                    finalFormData.append("genre", updatedData?.genre || "");
                    finalFormData.append("character", updatedData?.character || "");
                    finalFormData.append("moods", updatedData?.moods || "");
                    finalFormData.append("stems", stemsNeeded || "");
                    finalFormData.append(
                        "vocals",
                        updatedData?.vocals ? updatedData?.vocals[0] : ""
                    );
                    finalFormData.append("syncs", syncNeeded || "");
                    if (syncNeeded === "Yes") {
                        finalFormData.append("syncsYes", updatedData?.syncsYes || "");
                    }
                    finalFormData.append("edits", editsNeeded || "");
                    if (editsNeeded === "Yes") {
                        finalFormData.append("editsYes", updatedData?.editsYes || "");
                    }
                    finalFormData.append("licences", updatedData?.licences || "");
                    finalFormData.append(
                        "additional_notes",
                        updatedData?.additional_notes || ""
                    );
                    finalFormData.append("ref_url", updatedData?.ref_url || "");
                    if (firstDate) {
                        finalFormData.append(
                            "deadline_first",
                            moment(firstDate).format() || ""
                        );
                    }
                    if (finalDate) {
                        finalFormData.append(
                            "deadline_final",
                            moment(finalDate).format() || ""
                        );
                    }
                    finalFormData.append("brief_type", "Brief");

                    return updatedData;
                });

                axios
                    .post(
                        `${process.env.apiUrl}requestTrack`,

                        finalFormData,

                        {
                            headers: {
                                Authorization: getCookie("accessToken"),
                            },
                        }
                    )
                    .then((response) => {
                        toast.success(response?.data?.message || "Request successful", {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        // setTimeout(() => {
                        //     navigate("/home");
                        // }, [2000]);
                        setStage(3);
                    })
                    .catch((err) => {
                        if (err?.response?.status === 401) {
                            toast.error("Session Expired. Please login again");
                            handleLogout();
                        } else {
                            console.log({ err });
                            toast.error(err?.response?.data?.error || "Request Failed", {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        }
                        // navigateCall();
                    });
            })
            .catch((errorInfo) => {
                console.log("Failed:", errorInfo);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    // const purpuseList = [
    //     "Corporate",
    //     "Events",
    //     "Social Media",
    //     "Shorts and Reels",
    //     "Advertisements",
    //     "Podcasts",
    //     "Explainer Videos",
    //     "Apps",
    //     "Tutorials",
    // ];

    const [selectedcharacters, setSelectedcharacters] = useState([]);

    const onChangecharacter = (checkedValues) => {
        if (checkedValues.length > 2) {
            checkedValues.shift();
        }
        setSelectedcharacters(checkedValues);
    };

    const onChangeStems = (e) => {
        setStemsNeeded(e.target.value);
        stepForm.setFieldValue({
            edits: e.target.value,
        });
    };

    // const initialValues = {
    //     additional_notes: undefined,
    //     edits: undefined,
    //     licences: undefined,
    //     moods: [
    //         "1dbf392b-7dba-49ea-809e-2a508e97123c",
    //         "4102d395-7e32-4d68-9d17-32e8865b5b3d",
    //         "4d597eea-798b-4deb-a607-b659c3a31ad8",
    //     ],
    //     stems: undefined,
    //     syncs: ["Yes"],
    //     vocals: undefined,
    // };

    const onChangeSync = (e) => {
        // console.log({ e });
        // if (e.target.checked) {
        //     stepForm.setFieldsValue({
        //         syncs: [e.target.value],
        //     });
        // } else {
        //     stepForm.setFieldsValue({
        //         syncs: [],
        //     });
        // }

        setSyncNeeded(e.target.value);
        stepForm.setFieldValue({
            syncs: e.target.value,
        });
    };

    const onChangeEdits = (e) => {
        setEditsNeeded(e.target.value);
        stepForm.setFieldValue({
            edits: e.target.value,
        });
    };

    const [selectedMoods, setSelectedMoods] = useState([]);

    const onChangeMoods = (checkedValues) => {
        if (checkedValues.length > 3) {
            checkedValues.shift();
        }
        setSelectedMoods(checkedValues);
    };

    const handleValueChange = () => {
        for (const pair of finalFormData.entries()) {
            console.log(pair[0], pair[1]);
        }
    };

    useEffect(() => {
        stepForm.setFieldsValue(formData);
    }, [formData, stage, stepForm]);

    const Step1Form = () => {
        return (
            <Row className="step-1">
                <Col span={8}>
                    <div className="heding">
                        Select Purpose of use
                        <span style={{ color: "#ff0000", fontSize: "32px" }}>*</span> :
                    </div>
                    <div className="checkbox-wrap" style={{ maxHeight: "350px" }}>
                        <Form.Item label="" name="purpuse">
                            <Checkbox.Group options={themesListOptions} />
                        </Form.Item>
                    </div>
                    <div className="heding">Select Duration:</div>

                    <Form.Item label="" name="duration">
                        {/* <TimePicker
                                onChange={onChangeDuration}
                                popupStyle={{ fontSize: 20 }}
                                className="custom-timepicker"
                                format="ss" // Show only seconds
                                showHour={false} // Hide the hour column
                                showMinute={false}
                                defaultOpenValue={moment("00", "ss")}
                            /> */}

                        <Input
                            type="number"
                            min={0}
                            // max={3600}
                            // value={seconds}

                            // onChange={(e) => setSeconds(e?.target?.value)}
                            style={{ width: "200px", marginLeft: "35px" }}
                            placeholder="Time: 00 Sec"
                        />
                    </Form.Item>
                </Col>
                <Col span={1}>
                    <img src="/images/partition-line.png" alt="" />
                </Col>
                <Col span={7}>
                    <div className="heding">Select Genre:</div>

                    <div className="checkbox-wrap">
                        <Form.Item label="" name="genre">
                            <Checkbox.Group options={genresListOptions} />
                        </Form.Item>
                    </div>
                </Col>
                <Col span={1}>
                    <img src="/images/partition-line.png" alt="" />
                </Col>
                <Col span={7}>
                    <div className="heding">Select Character:</div>
                    <div className="note">(Select any two)</div>
                    <div className="checkbox-wrap">
                        <Form.Item label="" name="character">
                            <Checkbox.Group
                                options={characterListOptions}
                                onChange={onChangecharacter}
                                value={selectedcharacters}
                            />
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        );
    };

    const Step2Form = () => {
        return (
            <Row className="step-1">
                <Col span={8}>
                    <div className="heding">Select Moods:</div>
                    <div className="note">(Select Any 3)</div>
                    <div className="checkbox-wrap">
                        <Form.Item label="" name="moods">
                            <Checkbox.Group
                                options={moodsListOptions}
                                onChange={onChangeMoods}
                                value={selectedMoods}
                            />
                        </Form.Item>
                    </div>
                </Col>
                <Col span={1}>
                    <img src="/images/partition-line.png" alt="" />
                </Col>
                <Col span={7}>
                    <div className="heding">Stems Needed:</div>

                    <div className="checkbox-wrap">
                        <Form.Item label="" name="stems">
                            {/* <Checkbox.Group
                                    options={["Yes", "No"]}
                                    onChange={onChangeStems}
                                    value={selectedStems}
                                /> */}

                            <>
                                <Checkbox
                                    value="Yes"
                                    checked={stemsNeeded === "Yes"}
                                    onChange={(e) => onChangeStems(e)}
                                >
                                    Yes
                                </Checkbox>
                                <Checkbox
                                    value="No"
                                    checked={stemsNeeded === "No"}
                                    onChange={(e) => onChangeStems(e)}
                                >
                                    No
                                </Checkbox>
                            </>
                        </Form.Item>
                    </div>

                    <div className="heding">Vocals Needed:</div>

                    <div className="checkbox-wrap">
                        <Form.Item label="" name="vocals">
                            <Checkbox.Group options={["Male", "Female"]} />
                        </Form.Item>
                    </div>

                    <div className="heding">Sync Needed:</div>

                    <div className="checkbox-wrap">
                        <Form.Item label="" name="syncs">
                            {/* <Checkbox.Group
                                    options={["Yes", "No"]}
                                    onChange={onChangeSync}
                                    value={selectedSync}
                                /> */}
                            <>
                                <Checkbox
                                    value="Yes"
                                    checked={syncNeeded === "Yes"}
                                    onChange={(e) => onChangeSync(e)}
                                >
                                    Yes
                                </Checkbox>
                                <Checkbox
                                    value="No"
                                    checked={syncNeeded === "No"}
                                    onChange={(e) => onChangeSync(e)}
                                >
                                    No
                                </Checkbox>
                            </>
                        </Form.Item>
                        {syncNeeded === "Yes" && (
                            <Form.Item label="" name="syncsYes">
                                <Input placeholder="Add URL here" />
                            </Form.Item>
                        )}
                    </div>
                </Col>
                <Col span={1}>
                    <img src="/images/partition-line.png" alt="" />
                </Col>
                <Col span={7}>
                    <div className="heding">Edits Needed:</div>

                    <div className="checkbox-wrap">
                        <Form.Item label="" name="edits">
                            {/* <Checkbox.Group
                                    options={["Yes", "No"]}
                                    onChange={onChangeEdits}
                                    value={selectedEdits}
                                /> */}

                            <>
                                <Checkbox
                                    value="Yes"
                                    checked={editsNeeded === "Yes"}
                                    onChange={(e) => onChangeEdits(e)}
                                >
                                    Yes
                                </Checkbox>
                                <Checkbox
                                    value="No"
                                    checked={editsNeeded === "No"}
                                    onChange={(e) => onChangeEdits(e)}
                                >
                                    No
                                </Checkbox>
                            </>

                            {editsNeeded === "Yes" && (
                                <Form.Item label="" name="editsYes">
                                    <Input
                                        // type="number"
                                        // min={0}
                                        placeholder="Edits Requirement"
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </div>

                    <div className="heding">Licence:</div>

                    <div className="checkbox-wrap">
                        <Form.Item label="" name="licences">
                            <Checkbox.Group defaultValue={["Global, Digital"]} options={["Global, Digital"]} />
                        </Form.Item>
                    </div>

                    {/* <div className="heding" style={{ marginLeft: 0 }}>
                            Add Licences:<span>(Mass Media - OTT/TV/Cinema)</span>
                        </div> */}

                    {/* <div className="checkbox-wrap">
                            <Form.Item label="" name="addLicence">
                                <Select
                                    showSearch
                                    // style={{
                                    //     width: 200,
                                    // }}
                                    placeholder="Select"
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare((optionB?.label ?? "").toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: "United States",
                                            label: "United States",
                                        },
                                        {
                                            value: "United Kingdom",
                                            label: "United Kingdom",
                                        },
                                        {
                                            value: "India",
                                            label: "India",
                                        },
                                        {
                                            value: "Canada",
                                            label: "Canada",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div> */}
                    <div className="heding">Additional Notes:</div>

                    <div className="checkbox-wrap">
                        <Form.Item label="" name="additional_notes">
                            <TextArea rows={4} />
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        );
    };

    const Step3Form = () => {
        return (
            <Row className="step-1" style={{ paddingRight: "10%" }}>
                <Col span={12} style={{ marginTop: "5%" }}>
                    <div className="heding">Add URL of reference tracks if any:</div>
                    <div className="checkbox-wrap" style={{ width: "95%" }}>
                        <Form.Item label="" name="ref_url">
                            <Input placeholder="Add URL here" />
                        </Form.Item>
                    </div>
                </Col>
                <Col span={2}>
                    <img src="/images/partition-line.png" alt="" />
                </Col>
                <Col span={10} style={{ marginTop: "5%" }}>
                    <div className="heding">Deadlines:</div>

                    <div
                        className="checkbox-wrap"
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <div className="note" style={{ width: "30%" }}>
                            First Cut<span style={{ color: "#ff0000" }}>*</span>
                        </div>

                        <Form.Item label="" name="deadline_first">
                            <DatePicker
                                onChange={(momentDate) => {
                                    setFirstDate(momentDate?.format());
                                }}
                                value={firstDate}
                                disabledDate={(current) => {
                                    if (finalDate) {
                                        let customDate = moment(finalDate).format("YYYY-MM-DD");
                                        return (
                                            current && current > moment(customDate, "YYYY-MM-DD")
                                        );
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div
                        className="checkbox-wrap"
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <div className="note" style={{ width: "30%" }}>
                            Final Delivery<span style={{ color: "#ff0000" }}>*</span>
                        </div>

                        <Form.Item label="" name="deadline_final">
                            <DatePicker
                                onChange={(momentDate) => {
                                    setFinalDate(momentDate?.format());
                                }}
                                value={finalDate}
                                disabledDate={(current) => {
                                    let customDate = moment(firstDate).format("YYYY-MM-DD");
                                    // let today = moment().format("YYYY-MM-DD");
                                    return current && current < moment(customDate, "YYYY-MM-DD");
                                }}
                            />
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        );
    };

    const formSteps = [
        {
            title: "",
            content: <Step1Form />,
        },
        {
            title: "",
            content: <Step2Form />,
        },
        {
            title: "",
            content: <Step3Form />,
        },
    ];

    return (
        <div className="home-page">
            <Header showSearch={true} />
            <div className="home-page-components">
                <TopNavigation />
            </div>
            <div className="request-page-wraper">
                <div className="top-heading">
                    <div className="back-btn" onClick={() => navigate(-1)}>
                        <MdOutlineKeyboardBackspace />
                        Back
                    </div>
                    <div className="heading">Request New Track</div>
                </div>
                <div className="request-card-wrap">
                    <div className="steps-wrap">
                        <Steps current={stage}>
                            {formSteps?.map((item) => (
                                <Steps.Step key={item?.title} title={item?.title} />
                            ))}
                        </Steps>
                    </div>

                    <Form
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        id="test"
                        onValuesChange={handleValueChange}
                        // initialValues={initialValues}
                        // onSubmit={onFinish}
                        form={stepForm}
                    >
                        <div className="steps-content">{formSteps[stage]?.content}</div>

                        {stage < 3 && (
                            <>
                                <div className="disclaimer">
                                    Disclaimer: Select the fields according to the preferences of
                                    your music choice.*
                                </div>
                                <div className="btn-wraper">
                                    <Button
                                        className="form-btn1"
                                        onClick={() => navigate("/home")}
                                    >
                                        Cancel
                                    </Button>
                                    {stage > 0 && (
                                        <Button
                                            className="form-btn2"
                                            onClick={() => setStage(stage - 1)}
                                        >
                                            Back
                                        </Button>
                                    )}
                                    {stage === 2 ? (
                                        <Button
                                            className="form-btn2"
                                            type="primary"
                                            // htmlType="submit"
                                            onClick={onFinish}
                                        >
                                            Submit
                                        </Button>
                                    ) : (
                                        <Button className="form-btn2" onClick={() => handleNext()}>
                                            Next
                                        </Button>
                                    )}
                                </div>
                            </>
                        )}

                        {stage === 3 && (
                            <Row
                                className="step-1"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "12%",
                                    minHeight: "50vh",
                                }}
                            >
                                <div className="submit-page-wrpaer">
                                    <img src="" alt="" />
                                    <SvgLoader svgName="done" height={100} width={100} />
                                    <div className="heding1">
                                        Thank you for your request for a New Track
                                    </div>
                                    <div className="note1">
                                        On approval, Your request will be Sent to Brandmusiq{" "}
                                    </div>
                                    <div className="hiperlink" onClick={() => navigate("/home")}>
                                        Home
                                    </div>
                                </div>
                            </Row>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default RequestNewTrack;
