/* eslint-disable indent */
import { toast } from "react-toastify";
import { genericActionCreator } from "../../app/epics";
import { handleLogout } from "../../common/utils";
const INITIAL_STATE = {
    data: null,
    loading: false,
    error: false,
    flag: false,
};

const GET_TRACK_LIST = "GET_TRACK_LIST";
const GET_TRACK_LIST_SUCCESS = "GET_TRACK_LIST_SUCCESS";
const GET_TRACK_LIST_FAILURE = "GET_TRACK_LIST_FAILURE";
const GET_TRACK_LIST_RESET = "GET_TRACK_LIST_RESET";

const GET_TRACK_BLOB_RESET = "GET_TRACK_BLOB_RESET";
const STORE_TRACK_ID = "STORE_TRACK_ID";

const GET_GENRES_LIST = "GET_GENRES_LIST";
const GET_GENRES_LIST_SUCCESS = "GET_GENRES_LIST_SUCCESS";
const GET_GENRES_LIST_FAILURE = "GET_GENRES_LIST_FAILURE";
const GET_GENRES_LIST_RESET = "GET_GENRES_LIST_RESET";

const GET_THEMES_LIST = "GET_THEMES_LIST";
const GET_THEMES_LIST_SUCCESS = "GET_THEMES_LIST_SUCCESS";
const GET_THEMES_LIST_FAILURE = "GET_THEMES_LIST_FAILURE";
const GET_THEMES_LIST_RESET = "GET_THEMES_LIST_RESET";

const GET_MOODS_LIST = "GET_MOODS_LIST";
const GET_MOODS_LIST_SUCCESS = "GET_MOODS_LIST_SUCCESS";
const GET_MOODS_LIST_FAILURE = "GET_MOODS_LIST_FAILURE";
const GET_MOODS_LIST_RESET = "GET_MOODS_LIST_RESET";

const GET_CHARACTERS_LIST = "GET_CHARACTERS_LIST";
const GET_CHARACTERS_LIST_SUCCESS = "GET_CHARACTERS_LIST_SUCCESS";
const GET_CHARACTERS_LIST_FAILURE = "GET_CHARACTERS_LIST_FAILURE";
const GET_CHARACTERS_LIST_RESET = "GET_CHARACTERS_LIST_RESET";



export const getTrackListAction = (payload) =>
    genericActionCreator(
        GET_TRACK_LIST,
        payload,
        {
            url: `${process.env.apiUrl}getTrackList`,
            method: "GET",
            auth: true,
            headers: {
                // Authorization: getCookie("accessToken"),
                // "Content-Type": "ArrayBuffer",
                // ContentType: "ArrayBuffer",
            },
            // params: {
            //     genre_id: payload.genreId,
            //     order_by: payload.orderBy,
            //     page: payload.page,
            //     page_size: payload.pageSize,
            // },
        }
    );
export const getTrackListResetAction = () => ({
    type: GET_TRACK_LIST_RESET,
});

export function getTrackListReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_TRACK_LIST_RESET: {
            return INITIAL_STATE;
        }
        case GET_TRACK_LIST: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_TRACK_LIST_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_TRACK_LIST_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}

export const storeTrackIdAction = (payload) => ({
    type: STORE_TRACK_ID,
    payload,
});

export const getTrackBlobResetAction = () => ({
    type: GET_TRACK_BLOB_RESET,
});

export function storeTrackIdReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case STORE_TRACK_ID: {
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
                flag: true,
            };
        }
        default:
            return state;
    }
}

export const getGenresListAction = (payload) =>
    genericActionCreator(GET_GENRES_LIST, payload, {
        url: `${process.env.apiUrl}genres`,
        method: "GET",
        auth: true,

        params: {
            genre_id: payload.genreId,
            order_by: payload.orderBy,
            page: payload.page,
            page_size: payload.pageSize,
        },
    });
export const getGenresListResetAction = () => ({
    type: GET_GENRES_LIST_RESET,
});

export function getGenresListReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_GENRES_LIST_RESET: {
            return INITIAL_STATE;
        }
        case GET_GENRES_LIST: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_GENRES_LIST_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_GENRES_LIST_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}

export const getThemesListAction = (payload) =>
    genericActionCreator(GET_THEMES_LIST, payload, {
        url: `${process.env.apiUrl}themes`,
        method: "GET",
        auth: true,

        params: {
            genre_id: payload.genreId,
            order_by: payload.orderBy,
            page: payload.page,
            page_size: payload.pageSize,
        },
    });
export const getThemesListResetAction = () => ({
    type: GET_THEMES_LIST_RESET,
});

export function getThemesListReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_THEMES_LIST_RESET: {
            return INITIAL_STATE;
        }
        case GET_THEMES_LIST: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_THEMES_LIST_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_THEMES_LIST_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}

export const getMoodsListAction = (payload) =>
    genericActionCreator(GET_MOODS_LIST, payload, {
        url: `${process.env.apiUrl}moods`,
        method: "GET",
        auth: true,

        params: {
            genre_id: payload.genreId,
            order_by: payload.orderBy,
            page: payload.page,
            page_size: payload.pageSize,
        },
    });
export const getMoodsListResetAction = () => ({
    type: GET_MOODS_LIST_RESET,
});

export function getMoodsListReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_MOODS_LIST_RESET: {
            return INITIAL_STATE;
        }
        case GET_MOODS_LIST: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_MOODS_LIST_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_MOODS_LIST_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}


export const getCharactersListAction = (payload) =>
    genericActionCreator(GET_CHARACTERS_LIST, payload, {
        url: `${process.env.apiUrl}characters`,
        method: "GET",
        auth: true,

        params: {
            genre_id: payload.genreId,
            order_by: payload.orderBy,
            page: payload.page,
            page_size: payload.pageSize,
        },
    });
export const getCharactersListResetAction = () => ({
    type: GET_CHARACTERS_LIST_RESET,
});

export function getCharactersListReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_CHARACTERS_LIST_RESET: {
            return INITIAL_STATE;
        }
        case GET_CHARACTERS_LIST: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_CHARACTERS_LIST_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_CHARACTERS_LIST_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}
