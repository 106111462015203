/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Divider, Form, Input, Tooltip, Upload } from "antd";
import React, { useState } from "react";
import SvgLoader from "../../common/SvgLoader";
import { getCookie, handleLogout } from "../../common/utils";
import axios from "axios";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { FaRegFileAlt } from "react-icons/fa";
import Loader from "../Loader/Loader.jsx";

export default function UploadedTracksForm({
  setAllTrackData,
  uploading,
  setUploading,
  setUploadingTrack,
  uploadingTrack,
  setUploadingLicence,
  uploadingLicence,
  allTrackData,
  setAudioBlob,
  audioBlob,
  setLicenceBlob,
  licenceBlob,
  setShowUploadModal,
  // setTrackTitle,
  // trackTitle,
  blobSize,
}) {
  const [stepForm] = Form.useForm();

  const [trackName, setTrackName] = useState("");
  const [licenseName, setLicenceName] = useState("");
  const [trackTitle, setTrackTitle] = useState("");
  const [sendForApprovalLoading, setSendForApprovalLoading] = useState(false);

  const onFinish = (values) => {
    // stepForm
    //   .validateFields()
    //   .then(() => {
    //     setFormData((prev) => ({ ...prev, ...values }));
    //     setUploadSucess(true);
    //   })
    //   .catch((errorInfo) => {
    //     console.log('Failed:', errorInfo);
    //   });
    // console.log({ formData });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleValueChange = (v) => {
    if (v.track_title) {
      setTrackTitle(v.track_title);
    }
  };

  const handleUploadAction = async (file) => {
    try {
      setUploading(true);
      const blob = new Blob([file?.file], { type: file?.file?.type });
      setAudioBlob(blob);
      const response = await axios.post(
        `${process.env.apiUrl}admin/get_mood_list`,
        { file_url: file.file },
        {
          headers: {
            Authorization: getCookie("accessToken"),
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      if (response?.status === 200 && response.data.data) {
        // console.log("Upload Successful", response.data);
        // let data = response.data.data;
        // data.moods = data.moods.random();
        setAllTrackData(response.data.data);
        // setShowUploadTrackToProcess(false);
        setUploading(false);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      console.log("Uppload Track Error", err);
    }
  };

  const handleUploadFile = ({ file }) => {
    setUploadingTrack(true);
    setTrackName(file?.name);
    // const blob = new Blob([file?.file], { type: file?.file?.type });
    setAudioBlob(file);
  };

  const handleUploadLicence = ({ file }) => {
    setUploadingLicence(true);
    setLicenceName(file?.name);
    // const blob = new Blob([file?.file], { type: file?.file?.type });
    setLicenceBlob(file);
  };

  const handleDelete = () => {
    setAllTrackData(false);
  };

  const sendForApproval = async () => {
    setSendForApprovalLoading(true);
    const formData = new FormData();
    formData.append("title", trackTitle);
    formData.append("file", audioBlob);
    formData.append("type", "Track");
    formData.append("licence", licenceBlob);

    try {
      setUploading(true);
      const response = await axios.post(
        `${process.env.apiUrl}uploadAgentTrackView`,

        formData,

        {
          headers: {
            Authorization: getCookie("accessToken"),
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      if (response?.status === 200 && response.data.data) {
        toast.success("Track Uploaded Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setShowUploadModal(false);
        setTrackName("");
        setLicenceName("");
        setAudioBlob("");
        setUploading(false);
        setSendForApprovalLoading(false);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      console.log("Uppload Track Error", err);
      setSendForApprovalLoading(false);
    }
  };

  return (
    <div className="upload-track-for-processing">
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        // onSubmit={onFinish}
        form={stepForm}
        onValuesChange={handleValueChange}
      >
        <div className="step-1">
          <div className="title-wrapper">
            <div style={{ display: "flex" }}>
              <SvgLoader svgName="track-upload-icon" height={60} width={60} />
            </div>
            <Form.Item>
              <div className="upload-back-btn-wraper">
                {sendForApprovalLoading ? (
                  <div style={{ margin: "-40px 60px 0px 0" }}>
                    <Loader height={0} marginTop={0} />
                  </div>
                ) : (
                  <Button
                    className="upload-back-btn"
                    onClick={sendForApproval}
                    disabled={!audioBlob || !licenceBlob}
                  >
                    Send For Approval
                  </Button>
                )}
              </div>
            </Form.Item>
          </div>

          <Divider />

          <Form.Item
            className="track-title-form-item"
            label=""
            name="track_title"
          >
            <Input
              className="track-title-input"
              placeholder="Type Track Name"
              onChange={(e) => setTrackTitle(e?.target?.value)}
            />
          </Form.Item>

          <div className="checkbox-wrap-upload">
            {audioBlob ? (
              <div className="file-upload-card">
                <SvgLoader svgName={"track-thumbnail"} width={50} height={50} />
                <div className="file-upload-card-text">
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      className="file-upload-text"
                      style={{ fontSize: "20px" }}
                    >
                      {trackName}
                    </span>

                    <span onClick={() => setAudioBlob(undefined)}>
                      <RiDeleteBinLine
                        size={25}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </div>
                  <div className="file-upload-text-wrap">
                    <span
                      className="file-upload-text"
                      style={{ color: "#A9ACB4" }}
                    >
                      {audioBlob ? (audioBlob?.size / 1000000).toFixed(2) : "0"}{" "}
                      MB
                    </span>
                    <span className="file-upload-text">•</span>

                    <span className="file-upload-text">
                      <SvgLoader svgName={"done"} width={20} height={20} />
                      Completed
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <Form.Item label="" name="file_url">
                <Tooltip
                  title={trackTitle ? "" : "Please enter track name"}
                  color="#dcaa3a"
                >
                  <Upload
                    // customRequest={handleUploadAction}
                    customRequest={handleUploadFile}
                    showUploadList={false}
                    onChange={onFinish}
                    accept=".mp3"
                    previewFile={(blob) => {
                      console.log({ blob });
                    }}
                  >
                    <Button
                      disabled={!trackTitle || trackTitle === ""}
                      icon={
                        <SvgLoader
                          svgName="upload-track-outline-white-icon"
                          className="upload-white-icon"
                          height={40}
                          width={40}
                        />
                      }
                    >
                      <div>
                        <span style={{ color: "#fff" }}>
                          Choose a file or drag & drop it here
                        </span>{" "}
                        <br />
                        Supports MP3, FLAC, WAV formats, up to 50MB
                      </div>
                    </Button>
                  </Upload>
                </Tooltip>
              </Form.Item>
            )}
          </div>

          {audioBlob && (
            <div className="checkbox-wrap-upload">
              <div className="track-details">
                Add License/ Terms and Conditions
              </div>
              {licenceBlob ? (
                <div className="file-upload-card">
                  {/* <SvgLoader
                    svgName={"track-thumbnail"}
                    width={50}
                    height={50}
                  /> */}
                  <FaRegFileAlt size={50} color="#fff" />
                  <div className="file-upload-card-text">
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        className="file-upload-text"
                        style={{ fontSize: "20px" }}
                      >
                        {licenseName}
                      </span>

                      <span onClick={() => setLicenceBlob(undefined)}>
                        <RiDeleteBinLine
                          size={25}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </div>
                    <div className="file-upload-text-wrap">
                      <span
                        className="file-upload-text"
                        style={{ color: "#A9ACB4" }}
                      >
                        {licenceBlob
                          ? (licenceBlob?.size / 1000000).toFixed(2)
                          : "0"}{" "}
                        MB
                      </span>
                      <span className="file-upload-text">•</span>

                      <span className="file-upload-text">
                        <SvgLoader svgName={"done"} width={20} height={20} />
                        Completed
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <Form.Item label="" name="licence_url">
                  <Upload
                    // customRequest={handleUploadAction}
                    customRequest={handleUploadLicence}
                    showUploadList={false}
                    onChange={onFinish}
                    // accept=".mp3"
                    previewFile={(blob) => {
                      console.log({ blob });
                    }}
                  >
                    <Button
                      icon={
                        <SvgLoader
                          svgName="upload-track-outline-white-icon"
                          className="upload-white-icon"
                          height={40}
                          width={40}
                        />
                      }
                    >
                      <div>
                        <span style={{ color: "#fff" }}>
                          Click to Upload or drag and drop
                        </span>{" "}
                        <br />
                        (Max. File size: 25 MB){" "}
                      </div>
                    </Button>
                  </Upload>
                </Form.Item>
              )}
            </div>
          )}
        </div>
      </Form>
    </div>
  );
}
