/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { getCookie, handleLogout } from "../../common/utils/index.js";
import { toast } from "react-toastify";
import LineChart from "./LineChart.jsx";

export default function ClientAdminReports({ selectedClients }) {
  const [reportData, setReportData] = useState();
  const role = getCookie("roles");

  let colors = ["#603FDD", "#603FDDE5", "#603FDDBF", "#603FDD99", "#603FDD73"];

  useEffect(() => {
    if (role?.includes("BMQ Admin")) {
      axios
        .get(`${process.env.apiUrl}report?client_id=${selectedClients}`, {
          headers: {
            Authorization: getCookie("accessToken"),
          },
        })
        .then((response) => {
          setReportData(response?.data?.response);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) {
            toast.error("Session Expired. Please login again");
            handleLogout();
          }
        });
    } else if (role?.includes("Client Admin")) {
      axios
        .get(`${process.env.apiUrl}clientReport`, {
          headers: {
            Authorization: getCookie("accessToken"),
          },
        })
        .then((response) => {
          setReportData(response?.data?.response);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) {
            toast.error("Session Expired. Please login again");
            handleLogout();
          }
        });
    }
  }, [selectedClients]);

  const top5GenreChartConfig = {
    options: {
      chart: {
        type: "pie",
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val.toFixed(1) + "%";
        },
        dropShadow: {},
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: "40%",
            background: "transparent",
          },
        },
      },
      fill: {
        colors: ["#603FDD", "#603FDD", "#603FDD", "#603FDD", "#603FDD"],

        opacity: [1, 0.9, 0.75, 0.6, 0.45],
        type: "solid",
      },
      stroke: {
        show: false,
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "right",
        horizontalAlign: "center",
        floating: false,
        fontSize: "12px",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 700,
        formatter: undefined,
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: 0,
        labels: {
          colors: "#fff",
        },
        markers: {
          size: 7,
          shape: undefined,
          strokeWidth: 0,
          offsetX: -4,
          offsetY: 0,
        },
        itemMargin: {
          horizontal: 5,
          vertical: 8,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, w }) {
          return `
            <div class="arrow_box"> 
                <span>
                    ${w.globals.labels[seriesIndex]} 
                        : 
                    ${series[seriesIndex]} Tracks
                </span>
            </div>`;
        },
      },
      colors: colors,
      labels: reportData?.top_genres
        ?.slice(0, 5)
        ?.map((item) => item?.genre__name),
    },
    series: reportData?.top_genres
      ?.slice(0, 5)
      ?.map((item) => item?.track_count),
  };

  const top5MoodsChartConfig = {
    options: {
      series: [
        {
          name: "Moods",
          data: reportData?.top_moods_listening
            ?.slice(0, 5)
            ?.map((item) => item?.count_of_plays),
        },
      ],
      chart: {
        type: "bar",
        height: 100,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 10,
          borderRadiusApplication: "around",
          borderRadiusWhenStacked: "last",
          barHeight: "25px",
          distributed: false,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          dumbbellColors: undefined,
          isFunnel: false,
          isFunnel3d: true,
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: undefined,
              },
            ],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 0,
          },
          dataLabels: {
            position: "top",
            maxItems: 100,
            hideOverflowingLabels: false,
            orientation: "horizontal",
          },
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "end",
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: -20,
        // offsetY: 0,
        dropShadow: {
          enabled: true,
        },
        style: {
          fontSize: "12px",
          colors: ["#fff"],
          fontFamily: "Montserrat, sans-serif",
          textTransform: "capitalize",
        },
      },
      xaxis: {
        categories: reportData?.top_moods_listening
          ?.slice(0, 5)
          ?.map((item) => item?.mood_name),
        labels: {
          show: false,
          style: {
            colors: "#000", // Customize x-axis label color
            fontFamily: "Montserrat, sans-serif",
          },
        },
        axisBorder: {
          show: false, // Hide x-axis border
        },
        axisTicks: {
          show: false, // Hide x-axis ticks
        },
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: "#000", // Customize y-axis label color
          },
        },
      },
      stroke: {
        width: 0,
        colors: ["#fff"],
      },
      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
            <div class="arrow_box"> 
                <span>
                    ${w.globals.labels[dataPointIndex]} 
                        : 
                    ${series[seriesIndex][dataPointIndex]} listenings
                </span>
            </div>`;
        },
      },
      colors: colors,
    },
  };

  return (
    <div
      className="client-admin-reports-container"
      style={{ marginTop: "20px" }}
    >
      {reportData && (
        <Row gutter={16}>
          <Col span={20}>
            <Row gutter={[16, 16]} className="">
              <Col span={10}>
                <Card
                  className="top-genre1"
                  title="Top 5 Genre"
                  style={{ height: "100%", overflow: "auto" }}
                >
                  {reportData?.top_genres?.length > 0 ? (
                    <ReactApexChart
                      type="donut"
                      width={400}
                      options={top5GenreChartConfig?.options}
                      series={top5GenreChartConfig?.series}
                    />
                  ) : (
                    <div style={{ padding: "24px" }}>No data available</div>
                  )}
                </Card>
              </Col>
              <Col span={14}>
                {" "}
                <Card
                  className="top-genre2"
                  title="Top 5 Moods (Listenings)"
                  style={{ height: "100%" }}
                >
                  {reportData?.top_moods?.length > 0 ? (
                    <ReactApexChart
                      type="bar"
                      height={300}
                      options={top5MoodsChartConfig?.options}
                      series={top5MoodsChartConfig?.options?.series}
                    />
                  ) : (
                    <div style={{ padding: "24px" }}>No data available</div>
                  )}
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  className="top-genre"
                  title="Downloaded"
                  style={{ height: "100%" }}
                >
                  {reportData?.monthly_downloads?.length > 0 ? (
                    <LineChart
                      reportData={reportData?.monthly_downloads}
                      name={"Downloads"}
                    />
                  ) : (
                    <div>No data available</div>
                  )}
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  className="top-genre"
                  title="Uploaded"
                  style={{ height: "100%", width: "100%" }}
                >
                  {reportData?.monthly_uploads?.length > 0 ? (
                    <LineChart
                      reportData={reportData?.monthly_uploads}
                      name={"Uploads"}
                    />
                  ) : (
                    <div>No data available</div>
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Card
              className="top-genre"
              title="Summary statistics"
              style={{ height: "100%" }}
            >
              <div className="stats-card-wrap">
                <div className="stats-wrap">
                  <div className="stats-value">
                    {reportData?.stats?.play_count}
                  </div>
                  <div className="stats-title">Total play count</div>
                </div>
                <div className="stats-wrap">
                  <div className="stats-value">
                    {reportData?.stats?.total_track_count}
                  </div>
                  <div className="stats-title">Unique tracks</div>
                </div>
                <div className="stats-wrap">
                  <div className="stats-value">
                    {reportData?.stats?.genres_count}
                  </div>
                  <div className="stats-title">Different Genre</div>
                </div>
              </div>
            </Card>{" "}
          </Col>
        </Row>
      )}
    </div>
  );
}
