/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */

import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
    MdOutlineKeyboardBackspace,
    MdOutlineLibraryMusic,
    MdOutlineComment,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import { BiLike } from "react-icons/bi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
    getGalleryCountAction,
    getMyBriefsAction,
    getMyCollectionAction,
    getMyDownloadsAction,
    getMyLikesAction,
    getMyNotesAction,
    getPlayListAction,
} from "./logic";
import RequestTable from "../../components/ClientAdminRequestTable/RequestTable.jsx";
import { getCookie } from "../../common/utils/index.js";
import Loader from "../../components/Loader/Loader.jsx";
import BriefRequestDetailsModal from "../../components/BriefRequestDetailsModal/BriefRequestDetailsModal.jsx";
import TrackCard from "../../components/TrackCard/index.js";
import TopNavigation from "../../components/TopNavigation/TopNavigation.jsx";
import { Pagination } from "antd";

const MenuCard = ({ selectedMenu, title, icon: Icon, count }) => {
    return (
        <div
            className={`card-square ${selectedMenu === title && "card-square-active"
                }`}
            key={title}
        >
            <div className="icon-background">
                <Icon size={50} />
            </div>
            <div className="text-country">{title}</div>
            <div className="text-songs-count">{count}</div>
        </div>
    );
};
const MyGallery = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedMenu, setSelectedMenu] = useState();
    // const [selectedBriefRequest, setSelectedBriefRequest] = useState(false);
    const [showBriefRequestDetailsModal, setShowBriefRequestDetailsModal] =
        useState(false);
    const role = getCookie("roles");
    const [resetList, setResetList] = useState(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [playlistData, setPlaylistData] = useState();

    const userPermission = getCookie("permissions");

    useEffect(() => {
        if (userPermission?.includes("request_brief_edit_stem")) {
            setSelectedMenu("My Briefs");
        } else {
            setSelectedMenu("My Collection");
        }
    }, []);

    useEffect(() => {
        if (playlistData?.id) {
            dispatch(
                getMyCollectionAction({ playlist_id: playlistData?.id }, "", {
                    page: selectedPage,
                    page_size: 10,
                })
            );
        }
        if (selectedMenu === "My Downloads") {
            dispatch(
                getMyDownloadsAction([], "", {
                    page: selectedPage,
                    page_size: 10,
                })
            );
        }

        if (selectedMenu === "Likes") {
            dispatch(
                getMyLikesAction([], "", {
                    page: selectedPage,
                    page_size: 10,
                })
            );
        }

        dispatch(getGalleryCountAction({}));

        if (selectedMenu === "My Briefs") {
            {
                userPermission?.includes("request_brief_edit_stem") &&
                    dispatch(
                        getMyBriefsAction([], "", {
                            page: selectedPage,
                            page_size: 10,
                        })
                    );
            }
        }

        if (selectedMenu === "My Notes") {
            dispatch(
                getMyNotesAction([], "", {
                    page: selectedPage,
                    page_size: 10,
                })
            );
        }
        if (selectedMenu === "My Collection") {
            dispatch(getPlayListAction({}));
        }
    }, [resetList, selectedPage, selectedMenu]);

    const onChangeGalleryTabs = (tab) => {
        setSelectedMenu(tab);
        setSelectedPage(1);
    };

    const { data: getGalleryCountData } = useSelector(
        (state) => state.getGalleryCountData
    );

    const { data: getMyBriefsData, loading: getMyBriefsLoading } = useSelector(
        (state) => state.getMyBriefsData
    );
    const { data: getPlayListData, loading: getPlayListLoading } = useSelector(
        (state) => state.getPlayListData
    );
    const { data: getMyCollectionData, loading: getMyCollectionLoading } =
        useSelector((state) => state.getMyCollectionData);
    const { data: getMyDownloadsData, loading: getMyDownloadsLoading } =
        useSelector((state) => state.getMyDownloadsData);
    const { data: getMyLikesData, loading: getMyLikesLoading } = useSelector(
        (state) => state.getMyLikesData
    );
    const { data: getMyNotesData, loading: getMyNotesLoading } = useSelector(
        (state) => state.getMyNotesData
    );

    // const handleModalOpen = (data) => {
    //     setSelectedBriefRequest(data);
    //     setShowBriefRequestDetailsModal(true);
    // };

    const handlePageChange = (e) => {
        setSelectedPage(e);
    };

    const briefRequestColumns = [
        {
            title: "",
            dataIndex: "key",
            key: "key",
            width: 50,
            render: (name, record, index) => {
                return <span>{(selectedPage - 1) * 10 + index + 1}</span>;
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 200,
            render: () => {
                return <>Brief Request</>;
            },
        },
        {
            title: "Request Date",
            dataIndex: "created_at",
            key: "created_at",
            width: 200,
            render: (created_at) => moment(created_at).format("DD-MM-YYYY"),
        },
        {
            title: "Deadline",
            dataIndex: "deadline_final",
            key: "deadline_final",
            width: 200,
            render: (deadline_final) =>
                deadline_final ? moment(deadline_final).format("DD-MM-YYYY") : "-",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            width: 100,
            render: (type) => {
                if (type !== "Brief") {
                    return (
                        <span
                        // onClick={() => handleModalOpen(record)}
                        // style={{ cursor: "pointer" }}
                        >
                            {type ? type : "New Brief"}
                        </span>
                    );
                } else {
                    return <span>{type ? type : "New Brief"}</span>;
                }
            },
        },
        {
            title: "Link to Track",
            dataIndex: "linkToTrack",
            key: "linkToTrack",
            width: 200,
            render: (link, record) => {
                if (record.type === "Brief") {
                    return "-";
                } else {
                    return (
                        <span
                            style={{ cursor: "pointer", textDecoration: "underline" }}
                            onClick={() =>
                                navigate(`/track-details?${record?.parent_track_id}`)
                            }
                        >
                            {link ? link : "Master Track"}
                        </span>
                    );
                }
            },
        },

        {
            title: "Status",
            dataIndex: "final_status",
            key: "final_status",
            width: 150,
            render: (status) => (
                <div>
                    {status === "PENDING" && (
                        <div className="ar-pending-text" style={{ border: "none" }}>
                            Pending
                        </div>
                    )}
                    {status === "APPROVED" && (
                        <div className="ar-approved-text">Approved</div>
                    )}
                    {status === "REJECTED" && (
                        <div className="ar-rejected-text">Rejected</div>
                    )}
                </div>
            ),
        },
    ];

    const handleCollection = (data) => {
        setSelectedMenu("Collection Details");
        setPlaylistData(data);
        dispatch(
            getMyCollectionAction({ playlist_id: data?.id }, "", {
                page: selectedPage,
                page_size: 10,
            })
        );
    };

    function formatDateFromTimestamp(timestamp) {
        // Convert timestamp to milliseconds
        const date = new Date(timestamp * 1);

        // Extract day, month, and year
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();

        // Format date as dd-mm-yyyy
        return `${day}-${month}-${year}`;
    }
    return (
        <div className="home-page">
            <Header showSearch={true} />
            <div className="home-page-components">
                <TopNavigation />
            </div>
            <BriefRequestDetailsModal
                // selectedBriefRequest={selectedBriefRequest}
                showBriefRequestDetailsModal={showBriefRequestDetailsModal}
                setShowBriefRequestDetailsModal={setShowBriefRequestDetailsModal}
                formatDateFromTimestamp={formatDateFromTimestamp}
                role={role}
            // title={selectedBriefRequest?.type}
            // selectedClients={selectedClients}
            />
            <div className="gallery-page-components">
                <div className="menu-bar">
                    <div className="top-heading">
                        {" "}
                        <div className="back-btn" onClick={() => navigate(-1)}>
                            <MdOutlineKeyboardBackspace />
                            Back
                        </div>
                        {/* <div className="heading">My Vault</div> */}
                    </div>

                    <div className="card-wraper">
                        {userPermission?.includes("request_brief_edit_stem") && (
                            <div
                                style={{ marginBottom: "20px" }}
                                onClick={() => onChangeGalleryTabs("My Briefs")}
                            >
                                <MenuCard
                                    selectedMenu={selectedMenu}
                                    title="My Briefs"
                                    icon={AiOutlineFileAdd}
                                    count={
                                        getGalleryCountData?.data?.my_brief === 1
                                            ? `${getGalleryCountData?.data?.my_brief} Request`
                                            : `${getGalleryCountData?.data?.my_brief || 0} Requests`
                                    }
                                />
                            </div>
                        )}
                        <div
                            style={{ marginBottom: "20px" }}
                            onClick={() => onChangeGalleryTabs("My Collection")}
                        >
                            <MenuCard
                                selectedMenu={selectedMenu}
                                title="My Collection"
                                icon={MdOutlineLibraryMusic}
                                count={
                                    getGalleryCountData?.data?.my_collection === 1
                                        ? `${getGalleryCountData?.data?.my_collection} Track`
                                        : `${getGalleryCountData?.data?.my_collection || 0} Tracks`
                                }
                            />
                        </div>
                        <div
                            style={{ marginBottom: "20px" }}
                            onClick={() => onChangeGalleryTabs("My Downloads")}
                        >
                            <MenuCard
                                selectedMenu={selectedMenu}
                                title="My Downloads"
                                icon={FiDownload}
                                count={
                                    getGalleryCountData?.data?.my_download === 1
                                        ? `${getGalleryCountData?.data?.my_download} Track`
                                        : `${getGalleryCountData?.data?.my_download || 0} Tracks`
                                }
                            />
                        </div>
                        <div
                            style={{ marginBottom: "20px" }}
                            onClick={() => onChangeGalleryTabs("Likes")}
                        >
                            <MenuCard
                                selectedMenu={selectedMenu}
                                title="Likes"
                                icon={BiLike}
                                count={
                                    getGalleryCountData?.data?.my_likes === 1
                                        ? `${getGalleryCountData?.data?.my_likes} Track`
                                        : `${getGalleryCountData?.data?.my_likes || 0} Tracks`
                                }
                            />
                        </div>
                        <div
                            style={{ marginBottom: "20px" }}
                            onClick={() => onChangeGalleryTabs("My Notes")}
                        >
                            <MenuCard
                                selectedMenu={selectedMenu}
                                title="My Notes"
                                icon={MdOutlineComment}
                                count={
                                    getGalleryCountData?.data?.my_notes === 1
                                        ? `${getGalleryCountData?.data?.my_notes} Track`
                                        : `${getGalleryCountData?.data?.my_notes || 0} Tracks`
                                }
                            />
                        </div>
                        {/* <div>
                            <MenuCard title="Request New Brief" icon={FaPlus} count="" />
                        </div> */}
                    </div>
                </div>
                <div className="main-gallery-component">
                    {selectedMenu === "My Briefs" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={50} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Briefs</div>
                                    {/* <div className="description">All briefs</div> */}
                                    <div className="description">
                                        {getGalleryCountData?.data?.my_brief === 1
                                            ? `${getGalleryCountData?.data?.my_brief} Request`
                                            : `${getGalleryCountData?.data?.my_brief || 0} Requests`}
                                    </div>
                                    <div className="note">
                                        Tracks requested by you on Soniq Vault will be shown here.
                                    </div>
                                    {/* <div className="note">You can change this in Settings.</div> */}
                                </div>
                            </div>
                            <div className="gallery-briefs-table-wrap">
                                {getMyBriefsLoading ? (
                                    <Loader height={0} marginTop={0} />
                                ) : (
                                    <div
                                        className="client-admin-tabs-section"
                                        style={{ padding: 0 }}
                                    >
                                        <RequestTable
                                            columns={briefRequestColumns}
                                            dataSource={getMyBriefsData?.response}
                                            showPagination={true}
                                            pagination={{
                                                total: getMyBriefsData?.total_count,
                                                current: selectedPage,
                                                onChange: handlePageChange,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {selectedMenu === "My Collection" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={50} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Collection</div>
                                    <div className="description">
                                        {Array.isArray(getPlayListData?.playlist) ? (
                                            `${getPlayListData?.playlist.length} Playlist`
                                        ) : (
                                            <></>
                                        )}
                                    </div>

                                    <div className="description">
                                        {getGalleryCountData?.data?.my_collection === 1
                                            ? `${getGalleryCountData?.data?.my_collection} Track`
                                            : `${getGalleryCountData?.data?.my_collection || 0
                                            } Tracks`}
                                    </div>
                                    <div className="note">Browse your playlists and tracks</div>
                                </div>
                            </div>

                            <div className="gallery-collection-card-wrap">
                                {getPlayListLoading ? (
                                    <Loader height={0} marginTop={0} />
                                ) : (
                                    <>
                                        {getPlayListData?.playlist?.map((item) => (
                                            <div
                                                className="collection-card"
                                                key={item?.playlist_name}
                                                onClick={() => handleCollection(item)}
                                            >
                                                <div className="title">{item?.playlist_name}</div>
                                                <div className="count">{item?.total_songs} Tracks</div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {selectedMenu === "Collection Details" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={50} />
                                </div>
                                <div className="card-details">
                                    <div className="title">
                                        {getMyCollectionData?.playlist_name}
                                    </div>
                                    <div className="description"></div>
                                    <div className="description">
                                        {getMyCollectionData?.record_count === 1
                                            ? `${getMyCollectionData?.record_count} Track`
                                            : `${getMyCollectionData?.record_count || 0} Tracks`}
                                    </div>
                                    <div className="note">Browse your playlists and tracks</div>
                                </div>
                            </div>
                            {getMyCollectionLoading ? (
                                <Loader height={0} marginTop={0} />
                            ) : (
                                getMyCollectionData && (
                                    <>
                                        <TrackCard
                                            getPlayListData={getPlayListData}
                                            trackListData={getMyCollectionData.tracks}
                                            resetList={resetList}
                                            setResetList={setResetList}
                                            type="default"
                                        />
                                        <Pagination
                                            size="small"
                                            onChange={handlePageChange}
                                            defaultCurrent={getMyCollectionData?.page}
                                            total={getMyCollectionData?.total_count || 0}
                                        />
                                    </>
                                )
                            )}
                        </div>
                    )}

                    {selectedMenu === "My Downloads" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={50} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Downloads</div>
                                    {/* <div className="description">All Downloads</div> */}
                                    <div className="description">
                                        {getGalleryCountData?.data?.my_download === 1
                                            ? `${getGalleryCountData?.data?.my_download} Track`
                                            : `${getGalleryCountData?.data?.my_download || 0} Tracks`}
                                    </div>
                                    <div className="note">View tracks downloaded by you</div>
                                </div>
                            </div>

                            {getMyDownloadsLoading ? (
                                <Loader height={0} marginTop={0} />
                            ) : (
                                getMyDownloadsData && (
                                    <>
                                        <TrackCard
                                            getPlayListData={getPlayListData}
                                            trackListData={getMyDownloadsData.tracks}
                                            resetList={resetList}
                                            setResetList={setResetList}
                                            type="default"
                                        />

                                        <Pagination
                                            size="small"
                                            onChange={handlePageChange}
                                            defaultCurrent={getMyDownloadsData?.page}
                                            total={getMyDownloadsData?.total_count || 0}
                                        />
                                    </>
                                )
                            )}
                        </div>
                    )}

                    {selectedMenu === "Likes" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <AiOutlineFileAdd size={50} />
                                </div>
                                <div className="card-details">
                                    <div className="title">Likes</div>
                                    {/* <div className="description">All Likes</div> */}
                                    <div className="description">
                                        {getGalleryCountData?.data?.my_likes === 1
                                            ? `${getGalleryCountData?.data?.my_likes} Track`
                                            : `${getGalleryCountData?.data?.my_likes || 0} Tracks`}
                                    </div>
                                    <div className="note">View tracks liked by you</div>
                                </div>
                            </div>
                            {getMyLikesLoading ? (
                                <Loader height={0} marginTop={0} />
                            ) : (
                                getMyLikesData && (
                                    <>
                                        <TrackCard
                                            getPlayListData={getPlayListData}
                                            trackListData={getMyLikesData.tracks}
                                            resetList={resetList}
                                            setResetList={setResetList}
                                            type="default"
                                        />
                                        <Pagination
                                            size="small"
                                            onChange={handlePageChange}
                                            defaultCurrent={getMyLikesData?.page}
                                            total={getMyLikesData?.total_count || 0}
                                        />
                                    </>
                                )
                            )}
                        </div>
                    )}

                    {selectedMenu === "My Notes" && (
                        <div className="main-warper">
                            <div className="top-details">
                                <div className="card-icon">
                                    <MdOutlineComment size={50} />
                                </div>
                                <div className="card-details">
                                    <div className="title">My Notes</div>
                                    {/* <div className="description">All Tracks</div> */}
                                    <div className="description">
                                        {getGalleryCountData?.data?.my_notes === 1
                                            ? `${getGalleryCountData?.data?.my_notes} Track`
                                            : `${getGalleryCountData?.data?.my_notes || 0} Tracks`}
                                    </div>
                                    <div className="note">
                                        View your notes/comments for various tracks
                                    </div>
                                </div>
                            </div>
                            {getMyNotesLoading ? (
                                <Loader height={0} marginTop={0} />
                            ) : (
                                getMyNotesData && (
                                    <>
                                        <TrackCard
                                            getPlayListData={getPlayListData}
                                            trackListData={getMyNotesData.tracks}
                                            resetList={resetList}
                                            setResetList={setResetList}
                                            type="notes"
                                        />
                                        <Pagination
                                            size="small"
                                            onChange={handlePageChange}
                                            defaultCurrent={getMyNotesData?.page}
                                            total={getMyNotesData?.total_count || 0}
                                        />
                                    </>
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyGallery;
