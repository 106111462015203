/* eslint-disable indent */
import { Table } from "antd";
import React from "react";

export default function RequestTable({
  columns,
  dataSource,
  showPagination,
  pagination,
}) {
  return (
    <div className="client-admin-request-table">
      <Table
        dataSource={dataSource}
        columns={columns}
        rowHoverable={false}
        // pagination={false}
        pagination={
          showPagination
            ? {
                ...pagination,
                position: ["bottom"],
                defaultPageSize: 10,
                defaultCurrent: 1,
              }
            : false
        }
        scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
      />
    </div>
  );
}
