/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
import { Divider, Modal, Tag, Button } from "antd";
import React, { useEffect, useState } from "react";
import UploadMultipleTracksForm from "../UploadedTracksForm/UploadMultipleTracksForm.jsx";
import ReactApexChart from "react-apexcharts";
import { HiPause, HiPlay } from "react-icons/hi";
import WavesurferPlayer from "@wavesurfer/react";
import axios from "axios";
import { getCookie, handleLogout } from "../../common/utils/index.js";
import { toast } from "react-toastify";

export default function UploadTracksModal({
  setShowUploadModal,
  showUploadModal,
  briefRequestId,
}) {
  const [audioBlob, setAudioBlob] = useState();
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [, /* showUploadTrackToProcess */ setShowUploadTrackToProcess] =
    useState(true);

  const [allTrackData, setAllTrackData] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [trackTitle, setTrackTitle] = useState(false);
  const [blobSize /* setBlobSize */] = useState(false);
  const [uploadedTracks, setUploadedTracks] = useState([]);
  const [uploadedTracksDetail, setUploadedTracksDetail] = useState();

  const [rasaPredictionsKeys, setRasaPredictionsKeys] = useState([]);
  const [rasaPredictionsValues, setRasaPredictionsValues] = useState([]);
  const [currentView, setCurrentView] = useState("upload");
  const [heading, setHeading] = useState();
  const [thirdDetail, setThirdDetail] = useState();
  const blankAudio = "/music/silent_quarter-second.mp3";

  useEffect(() => {
    setRasaPredictionsKeys(
      Object?.keys(uploadedTracksDetail?.data?.rasa_predictions || [])
    );
    setRasaPredictionsValues(
      Object?.values(uploadedTracksDetail?.data?.rasa_predictions || [])
    );
  }, [uploadedTracksDetail]);

  const config = {
    options: {
      plotOptions: {
        radar: {
          polygons: {
            strokeColor: "#ffffff",
            strokeWidth: 2,
          },
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            colors: ["#ffffff"],
            fontSize: "16px",
            fontFamily: "Montserrat-Regular",
            fontWeight: 600,
          },
          offsetY: 5,
        },
        range: 1,
      },
      yaxis: {
        show: false,
        // stepSize: 0.1,
      },

      colors: ["#17B3FF"],
      labels: rasaPredictionsKeys,
      stroke: {
        width: 2,
        colors: ["#ffffff"],
      },
      fill: {
        opacity: 0.6,
      },

      markers: {
        size: 4,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
            <div class="arrow_box"> 
                <span>
                    ${w.globals.labels[dataPointIndex]} 
                        : 
                    ${series[seriesIndex][dataPointIndex]} 
                </span>
            </div>`;
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
    series: [
      {
        name: "",
        data: rasaPredictionsValues,
      },
    ],
  };

  const handleBack = () => {
    if (currentView === "details") {
      setCurrentView("upload");
    } else if (currentView === "third") {
      setCurrentView("details");
    } else if (currentView === "upload") {
      setShowUploadModal(false);
    }
  };

  useEffect(() => {
    console.log({ uploadedTracksDetail });
    if (uploadedTracksDetail) {
      axios
        .get(
          `${process.env.apiUrl}playTrackOption?track_id=${uploadedTracksDetail?.id}`,
          {
            headers: {
              Authorization: getCookie("accessToken"),
            },
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const blob = new Blob([response.data]);

          setAudioBlob(URL.createObjectURL(blob));
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            toast.error("Session Expired. Please login again");
            handleLogout();
          }
          // console.log(error);
        });
    }
  }, [uploadedTracksDetail, currentView]);

  const onPlayPause = () => {
    wavesurfer?.playPause();
  };

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };
  return (
    <Modal
      wrapClassName="upload-track-modal-wrap"
      title=""
      centered
      open={showUploadModal}
      onOk={() => setShowUploadModal(false)}
      onCancel={() => setShowUploadModal(false)}
      width={900}
      footer={false}
      closable={false}
    >
      {currentView === "upload" && (
        <>
          <UploadMultipleTracksForm
            setViewDetails={setViewDetails}
            setAllTrackData={setAllTrackData}
            setShowUploadTrackToProcess={setShowUploadTrackToProcess}
            viewDetails={viewDetails}
            uploading={uploading}
            setUploading={setUploading}
            allTrackData={allTrackData}
            setAudioBlob={setAudioBlob}
            audioBlob={audioBlob}
            setTrackTitle={setTrackTitle}
            trackTitle={trackTitle}
            blobSize={blobSize}
            briefRequestId={briefRequestId}
            uploadedTracks={uploadedTracks}
            setUploadedTracks={setUploadedTracks}
            uploadedTracksDetail={uploadedTracksDetail}
            setUploadedTracksDetail={setUploadedTracksDetail}
            setCurrentView={setCurrentView}
            setShowUploadModal={setShowUploadModal}
          />
          <div className="upload-back-btn-wraper">
            <Button className="upload-back-btn" onClick={handleBack}>
              Back
            </Button>
          </div>
        </>
      )}

      {currentView === "details" && (
        <div className="radar-and-player">
          {/* <div className="title-wrapper">
            <SvgLoader svgName="track-upload-icon" height={60} width={60} />
            <div label="" name="track-title">
              <Input placeholder="Type Track Name" />
            </div>
          </div> */}
          <div className="radar-and-tags" style={{ display: "flex" }}>
            <ReactApexChart
              options={config.options}
              series={config.series}
              type="radar"
              height="300"
              width={300}
              className="apex-chart-mood-radar"
            />
            {/* <div className="radar-tags-divider"></div> */}

            <img
              src="/images/partition-line.png"
              style={{ height: "300px" }}
              alt=""
            />

            <div className="tags-wrapper">
              <div className="tag-line-wraper">
                <div className="tag-title">Moods: </div>

                {uploadedTracksDetail?.data?.mood_prob &&
                  Object?.entries(uploadedTracksDetail?.data?.mood_prob)
                    ?.slice(0, 3)
                    ?.map((item) => (
                      <Tag
                        color="#dcaa3a"
                        style={{ color: "#000" }}
                        key={item[0]}
                      >
                        {item[0]}
                      </Tag>
                    ))}
                <div
                  className="tag-view-more"
                  onClick={() => {
                    setCurrentView("third");
                    setHeading("Moods");
                    setThirdDetail(uploadedTracksDetail?.data?.mood_prob);
                  }}
                >
                  View More
                </div>
              </div>
              <div className="tag-line-wraper">
                <div className="tag-title">Genre: </div>
                {uploadedTracksDetail?.data?.genre_class &&
                  Object?.entries(uploadedTracksDetail?.data?.genre_class)
                    ?.slice(0, 3)
                    ?.map((item, idx) => (
                      <Tag color="#603FDD" key={item[0]}>
                        {item[0]}
                      </Tag>
                    ))}
                <div
                  className="tag-view-more"
                  onClick={() => {
                    setCurrentView("third");
                    setHeading("Genre");
                    setThirdDetail(uploadedTracksDetail?.data?.genre_class);
                  }}
                >
                  View More
                </div>
              </div>
              {/* <div className="tag-line-wraper">
                <div className="tag-title">Theme: </div>
                {uploadedTracksDetail?.data?.theme_prob &&
                  Object?.entries(uploadedTracksDetail?.data?.theme_prob)
                    ?.slice(0, 3)
                    ?.map((item, idx) => (
                      <Tag color="#AD2756" key={item[0]}>
                        {item[0]}
                      </Tag>
                    ))}
                <div
                  className="tag-view-more"
                  onClick={() => {
                    setCurrentView("third");
                    setHeading("Theme");
                    setThirdDetail(uploadedTracksDetail?.data?.theme_prob);
                  }}
                >
                  View More
                </div>
              </div> */}
            </div>
          </div>
          <Divider />

          <div className="music-player-wrap">
            <div className="track-title">
              {uploadedTracksDetail?.title
                ? uploadedTracksDetail?.title
                : "Music Title"}
            </div>
            {/* <div className="track-details">
              <span>
                <div>
                  Genre: {allTrackData?.GENRE?.map((item) => item + ", ")}
                </div>
                <div>
                  Rasa: {allTrackData?.RASA?.map((item) => item + ", ")}
                </div>
              </span>
              <span style={{ borderRight: "1px solid #fff" }}></span>
              <span>
                <div>BPM: {allTrackData?.BPM}</div>
              </span>
            </div> */}

            <div className="music-player-wave-wraper">
              {audioBlob &&
                (isPlaying === true ? (
                  <HiPause
                    className="pause-btn"
                    size={40}
                    color="#fff"
                    onClick={() => onPlayPause()}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <HiPlay
                    size={40}
                    color="#fff"
                    onClick={() => onPlayPause()}
                    style={{ cursor: "pointer" }}
                  />
                ))}
              <div>
                <div>
                  <WavesurferPlayer
                    height={60}
                    waveColor="#363636"
                    progressColor="#dcaa3a"
                    interact={audioBlob}
                    autoplay={false}
                    url={audioBlob || blankAudio}
                    onReady={(e) => onReady(e)}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="upload-back-btn-wraper">
            <Button className="upload-back-btn" onClick={handleBack}>
              Back
            </Button>
          </div>
        </div>
      )}

      {currentView === "third" && (
        <div style={{ color: "#ffffff" }} className="view-more-tags-modal">
          <h3>{heading}</h3>
          {thirdDetail ? (
            <div style={{ height: "70vh", overflow: "auto" }}>
              {Object.entries(thirdDetail).map(([key, value]) => (
                <>
                  <div className="value-wrap" key={key}>
                    <p className="capitalize-first-letter">{key}:</p>
                    <p>{(value + 0).toFixed(4)}</p>
                    {/* <hr /> */}
                  </div>
                  <Divider />
                </>
              ))}
            </div>
          ) : (
            <div>No Data Found</div>
          )}
          <div className="upload-back-btn-wraper">
            <Button className="upload-back-btn" onClick={handleBack}>
              Back
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
