import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import ErrorPage from "./common/ErrorPage";
import "../public/styles/main.scss";
import "./app.css";
import LoginPage from "./pages/login";
import Demo from "./pages/demo";
import SignupPage from "./pages/signup";
import TrackDetails from "./pages/trackDetails";
import RequestNewTrack from "./pages/RequestNewTrack";
import UploadTrack from "./pages/UploadTrack";
import ClientAdmin from "./pages/ClientAdmin/index.jsx";
import { Bounce, ToastContainer } from "react-toastify";
import RequestEditStems from "./pages/EditStemsRequest/index.js";
import MyGallery from "./pages/MyGallery";
import ExplorePage from "./pages/explorePage";
import AgencyUserUploadTrack from "./pages/AgencyUserUploadTrack/AgencyUserUploadTrack.jsx";
import Search from "./pages/search/index.js";
import Home from "./pages/home/index.js";

// Create a layout component that includes the MusicPlayer
const MainLayout = () => (
  <div>
    {/* <MusicPlayerState /> */}
    <Outlet />
  </div>
);

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/signup",
        element: <SignupPage />,
      },
      {
        path: "/",
        element: <LoginPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/search",
        element: (
          // <ProtectedRoute>
          <Search />
          // </ProtectedRoute>
        ),
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/demo",
        element: <Demo />,
      },
      {
        path: "/explore",
        element: (
          <ExplorePage
            currentPlaying={"currentPlaying"}
            SetCurrentPlaying={"SetCurrentPlaying"}
          />
        ),
      },
      {
        path: "/track-details",
        element: <TrackDetails />,
      },
      {
        path: "/my-gallery",
        element: <MyGallery />,
      },
      {
        path: "/request-new-track",
        element: <RequestNewTrack />,
      },
      {
        path: "/upload-track",
        element: <UploadTrack />,
      },
      {
        path: "/admin",
        element: <ClientAdmin />,
      },
      {
        path: "/uploaded-tracks",
        element: <AgencyUserUploadTrack />,
      },
      {
        path: "/request-edits-stems",
        element: <RequestEditStems />,
      },
    ],
  },
]);

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      transition={Bounce}
    />
  </Provider>
);
