/* eslint-disable no-unused-vars */
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Tabs,
  Tooltip,
  Upload,
} from "antd";
import React, { useState } from "react";
import SvgLoader from "../../common/SvgLoader";
import Loader from "../Loader/Loader.jsx";
import axios from "axios";
import { getCookie, handleLogout } from "../../common/utils/index.js";
import { toast } from "react-toastify";

const UploadEditStemsModal = ({
  addMoreModal,
  setAddMoreModal,
  track,
  loading,
  activeTab,
  setActiveTab,
  uploadedEditsList,
  uploadedStemsList,
  refreshFlag,
  setRefreshFlag,
}) => {
  const [trackTitle, setTrackTitle] = useState("");
  const [trackName, setTrackName] = useState("");
  const [audioBlob, setAudioBlob] = useState();
  const [addUploadFlag, setAddUploadFlag] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [stepForm] = Form.useForm();

  const handleValueChange = (v) => {
    if (v.track_title) {
      setTrackTitle(v.track_title);
    }
  };

  console.log({ track });

  const handleUploadFile = async ({ file }) => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("title", trackTitle);
    formData.append("file", file);
    formData.append("type", activeTab);
    formData.append("parent_track_id", track?.master_track_id);
    formData.append("track_option_parent_track_id", track?.id);

    // setUploadingTrack(true);
    setTrackName(file?.file?.name);
    const blob = new Blob([file?.file], { type: file?.file?.type });
    // setAudioBlob(blob);

    try {
      //   setUploading(true);
      const response = await axios.post(
        `${process.env.apiUrl}uploadAgentTrackView`,
        formData,
        {
          headers: {
            Authorization: getCookie("accessToken"),
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      if (response?.status === 200 && response.data.data) {
        setRefreshFlag((prev) => !prev);
        setAddUploadFlag(false);
        toast.success("Track Uploaded Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // setShowUploadModal(false);
        setTrackName("");
        setAudioBlob("");
        setTrackTitle("");
        setUploadLoading(false);
        // setUploading(false);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      console.log("Uppload Track Error", err);
      setUploadLoading(false);
    }
  };

  const items = [
    {
      key: "1",
      label: "Edits",
      children: loading ? (
        <Loader height={0} marginTop={0} />
      ) : (
        <>
          {uploadedEditsList?.map((item, idx) => (
            <div key={idx} style={{ display: "flex", gap: "15px" }}>
              <div className="title-4" style={{ marginTop: "20px" }}>
                {idx + 1}.
              </div>
              <div className="file-upload-card">
                <SvgLoader svgName={"track-thumbnail"} width={50} height={50} />
                <div className="file-upload-card-text">
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      className="file-upload-text"
                      style={{ fontSize: "20px" }}
                    >
                      {item?.title}
                    </span>
                  </div>
                  <div className="file-upload-text-wrap">
                    <span
                      className="file-upload-text"
                      style={{ color: "#A9ACB4" }}
                    >
                      {item ? (item?.size / 1000000).toFixed(2) : "0"} MB
                    </span>
                    <span className="file-upload-text">•</span>

                    <span className="file-upload-text">
                      <SvgLoader svgName={"done"} width={20} height={20} />
                      Completed
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {(addUploadFlag || uploadedEditsList?.length === 0) && (
            <div
              className="upload-track-for-processing"
              style={{ minHeight: "auto", width: "70%" }}
            >
              <Form
                name="basic"
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                // onSubmit={onFinish}
                form={stepForm}
                onValuesChange={handleValueChange}
              >
                <Form.Item
                  className="track-title-form-item"
                  label=""
                  name="track_title"
                >
                  <Input
                    className="track-title-input"
                    placeholder="Type Track Name"
                    onChange={(e) => setTrackTitle(e?.target?.value)}
                    value={trackTitle}
                  />
                </Form.Item>
                <Tooltip
                  title={trackTitle ? "" : "Please enter track name"}
                  color="#dcaa3a"
                >
                  <Upload
                    // customRequest={handleUploadAction}
                    customRequest={handleUploadFile}
                    showUploadList={false}
                    // onChange={onFinish}
                    accept=".mp3"
                    previewFile={(blob) => {
                      console.log({ blob });
                    }}
                  >
                    <Button
                      disabled={!trackTitle || trackTitle === ""}
                      icon={
                        <SvgLoader
                          svgName="upload-track-outline-white-icon"
                          className="upload-white-icon"
                          height={30}
                          width={30}
                        />
                      }
                    >
                      <div>
                        <span style={{ color: "#fff" }}>
                          Choose a file or drag & drop it here
                        </span>{" "}
                        <br />
                        Supports MP3, FLAC, WAV formats, up to 50MB
                      </div>
                    </Button>
                  </Upload>
                </Tooltip>
              </Form>
            </div>
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Stems",
      children: loading ? (
        <Loader height={0} marginTop={0} />
      ) : (
        <>
          {uploadedStemsList?.map((item, idx) => (
            <div className="file-upload-card" key={idx}>
              <SvgLoader svgName={"track-thumbnail"} width={50} height={50} />
              <div className="file-upload-card-text">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    className="file-upload-text"
                    style={{ fontSize: "20px" }}
                  >
                    {item?.title}
                  </span>
                </div>
                <div className="file-upload-text-wrap">
                  <span
                    className="file-upload-text"
                    style={{ color: "#A9ACB4" }}
                  >
                    {item ? (item?.size / 1000000).toFixed(2) : "0"} MB
                  </span>
                  <span className="file-upload-text">•</span>

                  <span className="file-upload-text">
                    <SvgLoader svgName={"done"} width={20} height={20} />
                    Completed
                  </span>
                </div>
              </div>
            </div>
          ))}
          {(addUploadFlag || uploadedStemsList?.length === 0) && (
            <div
              className="upload-track-for-processing"
              style={{ minHeight: "auto", width: "70%" }}
            >
              <Form
                name="basic"
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                // onSubmit={onFinish}
                form={stepForm}
                onValuesChange={handleValueChange}
              >
                <Form.Item
                  className="track-title-form-item"
                  label=""
                  name="track_title"
                >
                  <Input
                    className="track-title-input"
                    placeholder="Type Track Name"
                    onChange={(e) => setTrackTitle(e?.target?.value)}
                    value={trackTitle}
                  />
                </Form.Item>
                <Tooltip
                  title={trackTitle ? "" : "Please enter track name"}
                  color="#dcaa3a"
                >
                  <Upload
                    // customRequest={handleUploadAction}
                    customRequest={handleUploadFile}
                    showUploadList={false}
                    // onChange={onFinish}
                    accept=".mp3"
                    previewFile={(blob) => {
                      console.log({ blob });
                    }}
                  >
                    <Button
                      disabled={!trackTitle || trackTitle === ""}
                      icon={
                        <SvgLoader
                          svgName="upload-track-outline-white-icon"
                          className="upload-white-icon"
                          height={30}
                          width={30}
                        />
                      }
                    >
                      <div>
                        <span style={{ color: "#fff" }}>
                          Choose a file or drag & drop it here
                        </span>{" "}
                        <br />
                        Supports MP3, FLAC, WAV formats, up to 50MB
                      </div>
                    </Button>
                  </Upload>
                </Tooltip>
              </Form>
            </div>
          )}
        </>
      ),
    },
  ];

  const onChangeTab = (key) => {
    if (key == 1) {
      setActiveTab("Edits");
      setTrackTitle("");
    }
    if (key == 2) {
      setActiveTab("Stems");
      setTrackTitle("");
    }
  };
  return (
    <>
      <Modal
        wrapClassName="upload-track-modal-wrap"
        title=""
        centered
        open={addMoreModal}
        onOk={() => setAddMoreModal(false)}
        onCancel={() => setAddMoreModal(false)}
        width={900}
        footer={false}
        closable={false}
      >
        <>
          <div className="title-wrapper">
            <div style={{ display: "flex" }}>
              <SvgLoader svgName="track-upload-icon" height={60} width={60} />
            </div>
            <div className="upload-back-btn-wraper"></div>
          </div>

          <Divider />
          <div style={{ padding: "2% 4%" }}>
            <div className="file-upload-card">
              <SvgLoader svgName={"track-thumbnail"} width={50} height={50} />
              <div className="file-upload-card-text">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    className="file-upload-text"
                    style={{ fontSize: "20px" }}
                  >
                    {track?.title}
                  </span>
                </div>
                <div className="file-upload-text-wrap">
                  <span
                    className="file-upload-text"
                    style={{ color: "#A9ACB4" }}
                  >
                    {track ? (track?.size / 1000000).toFixed(2) : "0"} MB
                  </span>
                  <span className="file-upload-text">•</span>

                  <span className="file-upload-text">
                    <SvgLoader svgName={"done"} width={20} height={20} />
                    Completed
                  </span>
                </div>
              </div>
            </div>
            <div className="tabs-section" style={{ padding: "0 2%" }}>
              <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />
            </div>
          </div>
          <div className="upload-back-btn-wraper" style={{ gap: "5%" }}>
            {uploadLoading ? (
              <div style={{ margin: "-40px 60px 0px 0" }}>
                <Loader height={0} marginTop={0} />
              </div>
            ) : (
              <Button
                className="upload-back-btn"
                onClick={() => setAddUploadFlag(true)}
              >
                Add More
              </Button>
            )}
            <Button
              className="upload-back-btn"
              onClick={() => setAddMoreModal(false)}
            >
              Back
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default UploadEditStemsModal;
