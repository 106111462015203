/* eslint-disable indent */
import { toast } from "react-toastify";
import { genericActionCreator } from "../../app/epics";
import { handleLogout } from "../../common/utils";
const INITIAL_STATE = {
    data: null,
    loading: false,
    error: false,
    flag: false,
};

const GET_AUDIO_ANALYSIS = "GET_AUDIO_ANALYSIS";
const GET_AUDIO_ANALYSIS_SUCCESS = "GET_AUDIO_ANALYSIS_SUCCESS";
const GET_AUDIO_ANALYSIS_FAILURE = "GET_AUDIO_ANALYSIS_FAILURE";
const GET_AUDIO_ANALYSIS_RESET = "GET_AUDIO_ANALYSIS_RESET";

export const getAudioAnalysisAction = (payload) =>
    genericActionCreator(GET_AUDIO_ANALYSIS, payload, {
        url: `${process.env.apiUrl}audioAnalysis`,
        method: "GET",
        auth: true,

        params: {
            genre_id: payload.genreId,
            order_by: payload.orderBy,
            page: payload.page,
            page_size: payload.pageSize,
        },
    });
export const getAudioAnalysisResetAction = () => ({
    type: GET_AUDIO_ANALYSIS_RESET,
});

export function getAudioAnalysisReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_AUDIO_ANALYSIS_RESET: {
            return INITIAL_STATE;
        }
        case GET_AUDIO_ANALYSIS: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_AUDIO_ANALYSIS_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_AUDIO_ANALYSIS_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}
