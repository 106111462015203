/* eslint-disable indent */
import { Button, Card, Tooltip } from "antd";
import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import TopNavigation from "../../components/TopNavigation/TopNavigation.jsx";
import { getCookie } from "../../common/utils/index.js";
import { useDispatch } from "react-redux";
import { getAudioAnalysisAction } from "./logic.js";

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userPermission = getCookie("permissions");

    // useEffect(() => {
    //     if (!role?.includes("Client Admin") ||
    //         !role?.includes("Client User") ||
    //         !role?.includes("Agency User")) {
    //         navigate("/login");
    //     }
    // }, []);

    useEffect(() => {
        dispatch(getAudioAnalysisAction({}));
    }, [])

    return (
        <div className="login-page" style={{ padding: 0 }}>
            {" "}
            <Header showSearch={true} />
            <div className="home-page-components">
                <TopNavigation />
            </div>
            <div className="home-page-card-wrap">
                <Card className="home-page-card">
                    <div className="home-page-card-inner">
                        <div className="card-title">Search</div>
                        <div className="card-content">
                            Explore Tracks: Browse through a diverse collection of tracks and
                            use filters to refine your search by mood, genre, Purpose, and
                            more. Discover the perfect soundtrack for any moment.
                        </div>
                    </div>
                    <Button className="card-btn" onClick={() => navigate("/search")}>
                        Search
                    </Button>
                </Card>
                <Card className="home-page-card">
                    <div className="home-page-card-inner">
                        <div className="card-title">Store</div>
                        <div className="card-content">
                            Track Submission: A dedicated space for external agencies to
                            upload and store custom audio tracks created on request.
                            Effortlessly manage and organize the tracks you contribute to the
                            platform, making them accessible to users as part of the library.
                        </div>
                    </div>
                    <Tooltip
                        title={
                            userPermission?.includes("upload_track")
                                ? ""
                                : "You do not have permission to store. Please send an email to admin to request the permissions on: ashutosh@brandmusiq.com"
                        }
                        color="#dcaa3a"
                    >
                        <Button
                            className="card-btn"
                            onClick={() => navigate("/uploaded-tracks")}
                            disabled={!userPermission?.includes("upload_track")}
                        >
                            Store
                        </Button>
                    </Tooltip>
                </Card>
                <Card className="home-page-card">
                    <div className="home-page-card-inner">
                        <div className="card-title">Spark</div>
                        <div className="card-content">
                            Track Request: Submit a request for custom tracks tailored to your
                            specific needs. Specify criteria such as mood, genre, Purpose, use
                            case, duration, and more to help us deliver the perfect audio
                            track for your project.
                        </div>
                    </div>
                    <Tooltip
                        title={
                            userPermission?.includes("request_brief_edit_stem")
                                ? ""
                                : "You do not have permission to spark. Please send an email to admin to request the permissions on: ashutosh@brandmusiq.com"
                        }
                        color="#dcaa3a"
                    >
                        <Button
                            className="card-btn"
                            onClick={() => navigate("/request-new-track")}
                            disabled={!userPermission?.includes("request_brief_edit_stem")}
                        >
                            Spark
                        </Button>
                    </Tooltip>
                </Card>
            </div>
        </div>
    );
};

export default Home;
