/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */

import React, { useState } from "react";
import Header from "../../components/Header";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import SvgLoader from "../../common/SvgLoader";
import axios from "axios";
import { getCookie, handleLogout } from "../../common/utils";
import { toast } from "react-toastify";
import moment from "moment";

const RequestEditStems = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [stage, setStage] = useState(0);
    const [editsStemsForm] = Form.useForm();

    const [syncNeeded, setSyncNeeded] = useState();
    const [licences, setLicences] = useState("STANDARD");
    const [firstDate, setFirstDate] = useState();
    const [finalDate, setFinalDate] = useState();

    const onFinish = (data) => {
        if (!firstDate && !finalDate) {
            toast.error("Please select mandatory fields");
            return null;
        }

        const editStemsData =
            location?.state?.type === "Stems"
                ? {
                    track_id: location?.state?.track_id,
                    deadline_final: moment(finalDate).format(),
                    type: location?.state?.type,
                }
                : {
                    track_id: location?.state?.track_id,
                    duration: data?.duration,
                    vocals_needed: data?.vocals,
                    sync_needed: syncNeeded,
                    licences: licences,
                    additional_notes: data?.additional_notes,
                    deadline_first: moment(firstDate).format(),
                    deadline_final: moment(finalDate).format(),
                    type: location?.state?.type,
                };

        editsStemsForm
            .validateFields()
            .then(() => {
                axios
                    .post(
                        `${process.env.apiUrl}trackEditsStems`,

                        editStemsData,

                        {
                            headers: {
                                Authorization: getCookie("accessToken"),
                            },
                        }
                    )
                    .then((response) => {
                        toast.success(response?.data?.message || "Request successful", {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setStage(1);
                    })
                    .catch((err) => {
                        if (err?.response?.status === 401) {
                            toast.error("Session Expired. Please login again");
                            handleLogout();
                        } else {
                            toast.error(err?.response?.data?.error || "Request Failed", {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        }
                        // setTimeout(() => {
                        //     navigate("/home");
                        // }, [2000]);
                    });
            })
            .catch((errorInfo) => {
                if (errorInfo.response.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                }
                // console.log("Failed:", errorInfo);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onChangeSync = (e) => {
        setSyncNeeded(e.target.value);
        editsStemsForm.setFieldValue({
            syncs: e.target.value,
        });
    };

    return (
        <div className="home-page">
            <Header showSearch={true} />

            <div className="request-page-wraper">
                <div className="top-heading">
                    <div className="back-btn" onClick={() => navigate(-1)}>
                        <MdOutlineKeyboardBackspace />
                        Back
                    </div>
                    <div className="heading">Request New {location?.state?.type}</div>
                </div>
                <div
                    className="request-card-wrap"
                    style={{
                        width: location?.state?.type === "Stems" && "500px",
                        margin: "auto",
                        marginTop: location?.state?.type === "Stems" && "20px"
                    }}
                >
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        id="test"
                        // onValuesChange={handleValueChange}
                        form={editsStemsForm}
                    >
                        {stage === 0 && (
                            <>
                                <div className="steps-content">
                                    {location?.state?.type === "Stems" ? (
                                        <Row className="step-1" style={{ alignItems: "center", minHeight: "250px" }}>
                                            <div
                                                className="checkbox-wrap"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div className="note" style={{ width: "30%" }}>
                                                    Final Delivery
                                                    <span style={{ color: "#ff0000" }}>*</span>
                                                </div>

                                                <Form.Item label="" name="deadline_final">
                                                    <DatePicker
                                                        onChange={(momentDate) => {
                                                            setFinalDate(momentDate?.format());
                                                        }}
                                                        value={finalDate}
                                                        disabledDate={(current) => {
                                                            const firstDateMoment = moment(firstDate);
                                                            const today = moment();
                                                            return (
                                                                current &&
                                                                (current < firstDateMoment || current < today)
                                                            );
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Row>
                                    ) : (
                                        <Row className="step-1">
                                            <Col span={8}>
                                                <div className="heding">Select Duration (Sec):</div>

                                                <Form.Item label="" name="duration">
                                                    <Input
                                                        type="number"
                                                        min={0}
                                                        style={{ width: "200px", marginLeft: "35px" }}
                                                        placeholder="Time: 00 Sec"
                                                    />
                                                </Form.Item>

                                                <div className="heding">Vocals Needed:</div>

                                                <div className="checkbox-wrap">
                                                    <Form.Item label="" name="vocals">
                                                        <Checkbox.Group options={["Male", "Female"]} />
                                                    </Form.Item>
                                                </div>

                                                <div className="heding">Sync Needed:</div>

                                                <div className="checkbox-wrap">
                                                    <Form.Item label="">
                                                        <>

                                                            <Checkbox
                                                                value="Yes"
                                                                checked={syncNeeded === "Yes"}
                                                                onChange={(e) => setLicences(e)}
                                                            >
                                                                Yes
                                                            </Checkbox>


                                                            <Checkbox
                                                                value="No"
                                                                checked={syncNeeded === "No"}
                                                                onChange={(e) => onChangeSync(e)}
                                                            >
                                                                No
                                                            </Checkbox>

                                                        </>
                                                    </Form.Item>
                                                    {syncNeeded === "Yes" && (
                                                        <Form.Item label="" name="syncsYes">
                                                            <Input placeholder="Add URL here" />
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col span={1}>
                                                <img src="/images/partition-line.png" alt="" style={{ height: "400px" }} />
                                            </Col>
                                            <Col span={7}>
                                                <div className="heding">Licence:</div>

                                                <div className="checkbox-wrap">
                                                    {/* <Form.Item label="" name="licences">
                                                        <Checkbox
                                                            checked
                                                            disabled
                                                            value={"Global, Digital"}
                                                        >
                                                            Global, Digital
                                                        </Checkbox>
                                                    </Form.Item> */}
                                                    <Form.Item label="">
                                                        <>
                                                            <Tooltip
                                                                title={"Includes Corporate events, social media, youtube"}
                                                                color="#dcaa3a"
                                                            >
                                                                <Checkbox
                                                                    value="STANDARD"
                                                                    checked={licences === "STANDARD"}
                                                                    onChange={(e) => setLicences(e.target.value)}
                                                                >
                                                                    STANDARD
                                                                </Checkbox>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={"Includes Corporate events, social media, youtube, TV, Cinema, OTT"}
                                                                color="#dcaa3a"
                                                            >
                                                                <Checkbox
                                                                    value="PREMIUM"
                                                                    checked={licences === "PREMIUM"}
                                                                    onChange={(e) => setLicences(e.target.value)}
                                                                >
                                                                    PREMIUM
                                                                </Checkbox>
                                                            </Tooltip>
                                                        </>
                                                    </Form.Item>
                                                </div>

                                                <div className="heding">Additional Notes:</div>

                                                <div className="checkbox-wrap" style={{ width: "80%" }}>
                                                    <Form.Item label="" name="additional_notes">
                                                        <TextArea rows={4} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={1}>
                                                <img src="/images/partition-line.png" alt="" style={{ height: "400px" }} />
                                            </Col>
                                            <Col span={7}>
                                                <div className="heding">Deadlines:</div>

                                                <div
                                                    className="checkbox-wrap"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className="note" style={{ width: "30%" }}>
                                                        First Cut<span style={{ color: "#ff0000" }}>*</span>
                                                    </div>

                                                    <Form.Item label="" name="deadline_first">
                                                        <DatePicker
                                                            onChange={(momentDate) => {
                                                                setFirstDate(momentDate?.format());
                                                            }}
                                                            value={firstDate}
                                                            // disabledDate={(current) => {
                                                            //     if (finalDate) {
                                                            //         let customDate = moment(finalDate).format("YYYY-MM-DD");
                                                            //         return (
                                                            //             current && current > moment(customDate, "YYYY-MM-DD")
                                                            //         );
                                                            //     }
                                                            // }}
                                                            disabledDate={(current) => {
                                                                const finalDateMoment = moment(finalDate);
                                                                const today = moment();
                                                                if (finalDateMoment > today) {
                                                                    return (
                                                                        current &&
                                                                        (current > finalDateMoment ||
                                                                            current < today)
                                                                    );
                                                                } else return current && current < today;
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div
                                                    className="checkbox-wrap"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className="note" style={{ width: "30%" }}>
                                                        Final Delivery
                                                        <span style={{ color: "#ff0000" }}>*</span>
                                                    </div>

                                                    <Form.Item label="" name="deadline_final">
                                                        <DatePicker
                                                            onChange={(momentDate) => {
                                                                setFinalDate(momentDate?.format());
                                                            }}
                                                            value={finalDate}
                                                            disabledDate={(current) => {
                                                                const firstDateMoment = moment(firstDate);
                                                                const today = moment();
                                                                return (
                                                                    current &&
                                                                    (current < firstDateMoment || current < today)
                                                                );
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </div>

                                <>
                                    <div className="disclaimer">
                                        {/* Disclaimer: Select the fields according to the preferences
                                        of your music choice.* */}
                                    </div>
                                    <div className="btn-wraper">
                                        <Button className="form-btn1" onClick={() => navigate(-1)}>
                                            Cancel
                                        </Button>

                                        <Button
                                            className="form-btn2"
                                            type="primary"
                                            htmlType="submit"
                                        // onClick={onFinish}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </>
                            </>
                        )}

                        {stage === 1 && (
                            <Row
                                className="step-1"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "12%",
                                    minHeight: "50vh",
                                }}
                            >
                                <div className="submit-page-wrpaer"
                                    style={{
                                        width: location?.state?.type === "Stems" && "90%",

                                    }}
                                >
                                    <img src="" alt="" />
                                    <SvgLoader svgName="done" height={100} width={100} />
                                    <div className="heding1">
                                        Thank you for your request for a New Track
                                    </div>
                                    <div className="note1">
                                        On approval, Your request will be Sent to Brandmusiq{" "}
                                    </div>
                                    <div className="hiperlink" onClick={() => navigate(-1)}>
                                        Home
                                    </div>
                                </div>
                            </Row>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default RequestEditStems;
