/* eslint-disable indent */
import { toast } from "react-toastify";
import { genericActionCreator } from "../../app/epics";
import { getCookie, handleLogout } from "../../common/utils";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  flag: false,
};

// Access Request Action Types
const GET_ACCESS_REQUEST_LIST = "GET_ACCESS_REQUEST_LIST";
const GET_ACCESS_REQUEST_LIST_SUCCESS = "GET_ACCESS_REQUEST_LIST_SUCCESS";
const GET_ACCESS_REQUEST_LIST_FAILURE = "GET_ACCESS_REQUEST_LIST_FAILURE";
const GET_ACCESS_REQUEST_LIST_RESET = "GET_ACCESS_REQUEST_LIST_RESET";

// Brief Request Action Types
const GET_BRIEF_REQUEST_LIST = "GET_BRIEF_REQUEST_LIST";
const GET_BRIEF_REQUEST_LIST_SUCCESS = "GET_BRIEF_REQUEST_LIST_SUCCESS";
const GET_BRIEF_REQUEST_LIST_FAILURE = "GET_BRIEF_REQUEST_LIST_FAILURE";
const GET_BRIEF_REQUEST_LIST_RESET = "GET_BRIEF_REQUEST_LIST_RESET";

// Accepted Brief Request Action Types
const GET_ACCEPTED_BRIEF_REQUEST_LIST = "GET_ACCEPTED_BRIEF_REQUEST_LIST";
const GET_ACCEPTED_BRIEF_REQUEST_LIST_SUCCESS =
  "GET_ACCEPTED_BRIEF_REQUEST_LIST_SUCCESS";
const GET_ACCEPTED_BRIEF_REQUEST_LIST_FAILURE =
  "GET_ACCEPTED_BRIEF_REQUEST_LIST_FAILURE";
const GET_ACCEPTED_BRIEF_REQUEST_LIST_RESET =
  "GET_ACCEPTED_BRIEF_REQUEST_LIST_RESET";

// Tracks Received Action Types
const GET_TRACKS_RECEIVED_LIST = "GET_TRACKS_RECEIVED_LIST";
const GET_TRACKS_RECEIVED_LIST_SUCCESS = "GET_TRACKS_RECEIVED_LIST_SUCCESS";
const GET_TRACKS_RECEIVED_LIST_FAILURE = "GET_TRACKS_RECEIVED_LIST_FAILURE";
const GET_TRACKS_RECEIVED_LIST_RESET = "GET_TRACKS_RECEIVED_LIST_RESET";

export const getAccessRequestListAction = (payload, url, pagination) =>
  genericActionCreator(GET_ACCESS_REQUEST_LIST, payload, {
    url: `${process.env.apiUrl}admin/accessRequest?page=${pagination?.page ?? 1
      }&page_size=${pagination?.page_size ?? 10}`,
    method: "GET",
    auth: true,
    headers: {
      Authorization: getCookie("accessToken"),
    },
    // params: {
    //   genre_id: payload.genreId,
    //   order_by: payload.orderBy,
    //   page: payload.page,
    //   page_size: payload.pageSize,
    // },
  });

export const getBriefRequestListAction = (payload, url, pagination) =>
  genericActionCreator(GET_BRIEF_REQUEST_LIST, payload, {
    url: `${process.env.apiUrl}admin/${url ? url + "&" : "briefRequestClientAdmin?"
      }page=${pagination?.page ?? 1}&page_size=${pagination?.page_size ?? 10}`,
    method: "GET",
    auth: true,
    headers: {
      Authorization: getCookie("accessToken"),
    },
    // params: {
    //   genre_id: payload.genreId,
    //   order_by: payload.orderBy,
    //   page: payload.page,
    //   page_size: payload.pageSize,
    // },
  });

export const getAcceptedBriefRequestListAction = (payload, url, pagination) =>
  genericActionCreator(GET_ACCEPTED_BRIEF_REQUEST_LIST, payload, {
    url: `${process.env.apiUrl}admin/${url ? url : "briefRequestBMQUser?"
      }page=${pagination?.page ?? 1}&page_size=${pagination?.page_size ?? 10}`,
    method: "GET",
    auth: true,
    headers: {
      Authorization: getCookie("accessToken"),
    },
    // params: {
    //   genre_id: payload.genreId,
    //   order_by: payload.orderBy,
    //   page: payload.page,
    //   page_size: payload.pageSize,
    // },
  });



export const getTracksReceivedListAction = (payload, url, pagination) =>
  genericActionCreator(GET_TRACKS_RECEIVED_LIST, payload, {
    url: `${process.env.apiUrl}admin/${url ? url : "trackReceivedClientAdmin?"
      }page=${pagination?.page ?? 1}&page_size=${pagination?.page_size ?? 10}`,

    method: "GET",
    auth: true,
    headers: {
      Authorization: getCookie("accessToken"),
    },
    // params: {
    //   genre_id: payload.genreId,
    //   order_by: payload.orderBy,
    //   page: payload.page,
    //   page_size: payload.pageSize,
    // },
  });

export const getAccessRequestListResetAction = () => ({
  type: GET_ACCESS_REQUEST_LIST_RESET,
});

export const getBriefRequestListResetAction = () => ({
  type: GET_BRIEF_REQUEST_LIST_RESET,
});

export const getAcceptedBriefRequestListResetAction = () => ({
  type: GET_ACCEPTED_BRIEF_REQUEST_LIST_RESET,
});

export const getTracksReceivedListResetAction = () => ({
  type: GET_TRACKS_RECEIVED_LIST_RESET,
});

export function getAccessRequestListReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case GET_ACCESS_REQUEST_LIST_RESET: {
      return INITIAL_STATE;
    }
    case GET_ACCESS_REQUEST_LIST: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case GET_ACCESS_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case GET_ACCESS_REQUEST_LIST_FAILURE: {
      if (action?.payload?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}

export function getBriefRequestListReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case GET_BRIEF_REQUEST_LIST_RESET: {
      return INITIAL_STATE;
    }
    case GET_BRIEF_REQUEST_LIST: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case GET_BRIEF_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case GET_BRIEF_REQUEST_LIST_FAILURE: {
      if (action?.payload?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}

export function bmqAcceptedBriefRequestReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case GET_ACCEPTED_BRIEF_REQUEST_LIST_RESET: {
      return INITIAL_STATE;
    }
    case GET_ACCEPTED_BRIEF_REQUEST_LIST: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case GET_ACCEPTED_BRIEF_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case GET_ACCEPTED_BRIEF_REQUEST_LIST_FAILURE: {
      if (action?.payload?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}

export function tracksRecievedReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case GET_TRACKS_RECEIVED_LIST_RESET: {
      return INITIAL_STATE;
    }
    case GET_TRACKS_RECEIVED_LIST: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case GET_TRACKS_RECEIVED_LIST_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case GET_TRACKS_RECEIVED_LIST_FAILURE: {
      if (action?.payload?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}
