/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
    MdOutlineInsertComment,
    MdOutlineKeyboardBackspace,
    MdOutlineLayers,
    MdOutlineModeEdit,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import WavesurferPlayer from "@wavesurfer/react";
import { Button, Divider, Form, Input, Modal, Popover, Table, Tabs, Tag } from "antd";
import { BsDownload } from "react-icons/bs";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { LuLink } from "react-icons/lu";
import { HiPause, HiPlay, HiStop } from "react-icons/hi";
import axios from "axios";
import { getCookie, handleLogout } from "../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import { getEditsDetailsAction, getTrackDetailsAction } from "./logic";
import Loader from "../../components/Loader/Loader.jsx";
import SvgLoader from "../../common/SvgLoader/index.js";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import moment from "moment";
import TopNavigation from "../../components/TopNavigation/TopNavigation.jsx";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { getPlayListAction } from "../MyGallery/logic.js";

const TrackDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [wavesurfer, setWavesurfer] = useState(null);
    const [wavesurfer1, setWavesurfer1] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    // const [duration, setDuration] = useState(0);
    const [audioBlob, setAudioBlob] = useState();
    const [audioBlob1, setAudioBlob1] = useState();
    const [TabValue, setTabValue] = useState("Edits");
    const blankAudio = "/music/silent_quarter-second.mp3";


    const [editStemsTableData, setEditStemsTableData] = useState([]);
    // const [editStemsDownload, setEditStemsDownload] = useState([]);
    const [commentsList, setCommentsList] = useState([]);
    const [open, setOpen] = useState(false);
    const [openIdx, setOpenIdx] = useState(false);
    const [comment, setComment] = useState("");
    const [resetList, setResetList] = useState(false);
    const [currentSongIndex, setCurrentSongIndex] = useState();
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [heading, setHeading] = useState();
    const [detailModdsGenre, setDetailModdsGenre] = useState();
    const [clickedTrack, setClickedTrack] = useState("");
    const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);
    const [trackAddedPlaylist, setTrackAddedPlaylist] = useState([]);
    const [showPlaylistFlag, setShowPlaylistFlag] = useState(true);

    const { data: getTrackDetailsData } = useSelector(
        (state) => state.getTrackDetailsData
    );
    const { data: getEditsDetailsData, loading: editsStemsTableLoading } =
        useSelector((state) => state.getEditsDetailsData);

    const { data: getPlayListData } = useSelector(
        (state) => state.getPlayListData
    );

    const config = {
        options: {
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColor: "#ffffff",
                        strokeWidth: 2,
                    },
                },
            },
            xaxis: {
                labels: {
                    show: true,
                    style: {
                        colors: ["#ffffff"],
                        fontSize: "12px",
                        fontFamily: "Montserrat-Regular",
                        fontWeight: 600,
                    },
                    offsetY: 5,
                },
            },
            yaxis: {
                show: false,
            },

            colors: ["#17B3FF"],
            labels:
                getTrackDetailsData?.response?.data[0]?.characters.map(
                    (item) => item.name
                ) || [],
            stroke: {
                width: 2,
                colors: ["#ffffff"],
            },
            fill: {
                opacity: 0.6,
            },
            // markers: {
            //     // size: (seriesIndex) => (seriesIndex === 0 ? 0 : 5),
            //     size: 5,
            //     // colors: "#FF0000",
            //     // colors: (seriesIndex) => (seriesIndex === 0 ? "#ffffff" : "#FA3750"),
            //     // colors: ["#8AC900", "#FFAE00", "#CD53FF", "#FA3750", "#008DD2", "#FFFFFF", "#FFFFFF", "#E31E23", "#009B13", "#F5B3B6", "#FFFFFF", "#FF7100"],

            // },
            markers: {
                size: 4,
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return `
                    <div class="arrow_box"> 
                        <span>
                            ${w.globals.labels[dataPointIndex]} 
                                : 
                            ${series[seriesIndex][dataPointIndex]} 
                        </span>
                    </div>`;
                },
            },
            chart: {
                toolbar: {
                    show: false,
                },
            },
        },
        series: [
            {
                name: "",
                data:
                    getTrackDetailsData?.response?.data[0]?.characters.map(
                        (item) => item.score
                    ) || [],
            },
            // {
            //     name: "",
            //     data: ["100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%"],
            // },
        ],
    };

    const userPermission = getCookie("permissions");

    useEffect(() => {
        dispatch(getPlayListAction({}));
    }, [showPlaylistFlag, clickedTrack /* trackAddedPlaylist */]);


    useEffect(() => {
        dispatch(getTrackDetailsAction({ id: location?.search?.substring(1) }));

        axios
            .get(
                `${process.env.apiUrl}playTrack?track_id=${location?.search?.substring(
                    1
                )}`,
                {
                    headers: {
                        Authorization: getCookie("accessToken"),
                    },
                    responseType: "arraybuffer",
                }
            )
            .then((response) => {
                setIsPlaying(true);
                const blob = new Blob([response.data]);

                setAudioBlob(URL.createObjectURL(blob));
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                }
                // console.log(err);
            });
    }, []);

    useEffect(() => {
        setEditStemsTableData([]);

        dispatch(
            getEditsDetailsAction({
                id: location?.search?.substring(1),
                type: TabValue,
            })
        );
    }, [resetList]);



    const showAddPlaylistModal = (song) => {
        setClickedTrack(song);
        setIsAddPlaylistModalOpen(true);

        axios
            .get(`${process.env.apiUrl}trackInPlaylist?track_id=${song?.id}`, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setTrackAddedPlaylist(response?.data?.data);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                }
            });
    };
    const handleCancel = () => {
        setShowPlaylistFlag(true);
        setIsAddPlaylistModalOpen(false);
    };
    useEffect(() => {
        const newTableData = getEditsDetailsData?.response?.data.map(
            (item, idx) => {
                return {
                    key: idx,
                    title: (
                        <div className="edit-stems-tabs-name">
                            <span className="edit-stems-title">{item?.title}</span>
                            <span className="edit-stems-player">

                                {currentSongIndex === idx &&
                                    (isPlaying1 ? (
                                        <HiStop
                                            className="pause-btn"
                                            size={30}
                                            color="#fff"
                                            onClick={() => onPlayPause1()}
                                            style={{ cursor: "pointer", zIndex: 1 }}
                                        />
                                    ) : (
                                        <HiPlay
                                            size={30}
                                            color="#fff"
                                            onClick={() => onPlayPause1()}
                                            style={{ cursor: "pointer", zIndex: 1 }}
                                        />
                                    ))}
                                {currentSongIndex !== idx && (
                                    <HiPlay
                                        size={30}
                                        color="#fff"
                                        onClick={() => playSong(item, idx)}
                                        style={{ cursor: "pointer", zIndex: 1 }}
                                    />
                                )}

                                <WavesurferPlayer
                                    height={40}
                                    width={150}
                                    waveColor="#363636"
                                    progressColor="#dcaa3a"
                                    interact={currentSongIndex === idx && audioBlob1}
                                    autoplay={currentSongIndex === idx && audioBlob1}
                                    url={
                                        (currentSongIndex === idx && audioBlob1)
                                            ? audioBlob1
                                            : blankAudio
                                    }
                                    onReady={(e) => onReady1(e)}
                                    onPlay={() => setIsPlaying1(true)}
                                    onPause={() => setIsPlaying1(false)}

                                />
                            </span>
                        </div>
                    ),
                    duration: item?.duration ? Math.ceil(item?.duration) + " Sec" : "-",

                    bpm: Math.round(item?.bpm),
                    btn: (
                        <div
                            className="audio-player-icons"
                            style={{ justifyContent: "flex-end" }}
                        >
                            <Popover
                                content={
                                    <div>
                                        <div className="comment-text">
                                            My Note{" "}
                                            <IoClose
                                                size={20}
                                                color="#fff"
                                                className="all-icons"
                                                onClick={hide}
                                            />
                                        </div>
                                        <Divider />
                                        {commentsList.length !== 0 && (
                                            <>
                                                <div className="comment-text">
                                                    <ul>
                                                        {commentsList?.map((commentItem) => (
                                                            <li key={commentItem}>{commentItem}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <Divider />
                                            </>
                                        )}
                                        <div className="comment-text">
                                            <textarea
                                                className="comment-text"
                                                rows="3"
                                                placeholder="add a comment"
                                                onChange={(e) => setComment(e.target.value)}
                                            />
                                            <SvgLoader
                                                svgName="save-comment"
                                                width={40}
                                                height={40}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleComment(item)}
                                            />
                                        </div>
                                    </div>
                                }
                                trigger="click"
                                open={open && openIdx === idx}
                                onOpenChange={() => handleOpenChange(item, idx)}
                                placement="bottomRight"
                                arrow={false}
                            >
                                <MdOutlineInsertComment
                                    size={20}
                                    color="#fff"
                                    className="all-icons"
                                />
                            </Popover>
                            {item?.is_liked ? (
                                <GoHeartFill
                                    size={20}
                                    color={"#ff0000"}
                                    className="all-icons"
                                    onClick={() => handleFavorite(item)}
                                />
                            ) : (
                                <GoHeart
                                    size={20}
                                    color={"#fff"}
                                    className="all-icons"
                                    onClick={() => handleFavorite(item)}
                                />
                            )}
                            <FiPlus size={20} color="#fff" className="all-icons"
                                onClick={() => showAddPlaylistModal(item)}

                            />
                            <LuLink
                                size={20}
                                color="#fff"
                                className="all-icons"
                                onClick={() => {
                                    const regex = /api\/v0\/?/g;
                                    navigator.clipboard
                                        .writeText(
                                            `${process.env.apiUrl.replace(
                                                regex,
                                                ""
                                            )}track-details?${item?.id}`
                                        )
                                        .then(
                                            () => {
                                                toast.success("Link copied to clipboard", {
                                                    position: "top-center",
                                                    autoClose: 2000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                });
                                            },
                                            () => {
                                                console.error("Failed to copy");
                                            }
                                        );
                                }}
                            />
                        </div>
                    ),
                };
            }
        );

        setEditStemsTableData(newTableData);

        // setEditStemsDownload(
        //     getEditsDetailsData?.response?.data?.map((item) => item?.id)
        // );
    }, [
        getEditsDetailsData,
        open,
        comment,
        isPlaying1,
        isPlaying,
        wavesurfer1,
        audioBlob1,
        commentsList,
    ]);

    const onPlayPause = () => {
        wavesurfer1 && wavesurfer1.pause();
        wavesurfer && wavesurfer.playPause();
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
            .toString()
            .padStart(2, "0")}`;
    };

    const onChangeTab = (key) => {
        if (isPlaying1) {
            setAudioBlob1(null)
            setCurrentSongIndex(false)
            setIsPlaying1(false);
            wavesurfer1 && wavesurfer1.playPause();
        } else {
            setIsPlaying1(false);

            setCurrentSongIndex(false);
            setAudioBlob1(null);
        }

        setEditStemsTableData([]);
        if (key == 1) {
            setTabValue("Edits");
            dispatch(
                getEditsDetailsAction({
                    id: location?.search?.substring(1),
                    type: "Edits",
                })
            );
        }
        if (key == 2) {
            setTabValue("Stem");
            dispatch(
                getEditsDetailsAction({
                    id: location?.search?.substring(1),
                    type: "Stem",
                })
            );
        }
    };

    const hide = () => {
        setOpen(false);
        setComment("");
    };

    const handleOpenChange = (song, idx) => {
        setOpen(true);
        setOpenIdx(idx);
        setCommentsList([]);

        axios
            .get(`${process.env.apiUrl}note?track_id=${song?.id}`, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setCommentsList(response?.data?.data);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                }
                // console.log(err);
            });
    };

    const handleComment = (song) => {
        const data = {
            track_id: song?.id,
            note_text: comment,
        };

        axios
            .post(`${process.env.apiUrl}note`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setComment("");
                hide();
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                }
                // console.log(err);
                hide();
            });

        // hide();
    };

    const handleFavorite = (song) => {
        const data = {
            track_id: song?.id,
            action: !song?.is_liked,
        };

        axios
            .post(`${process.env.apiUrl}like`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setResetList((prev) => !prev);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                }
                // console.log(err);
            });
    };

    const onReady1 = (ws) => {
        if (isPlaying && wavesurfer) {
            wavesurfer?.pause();
        }
        setWavesurfer1(ws);
        setIsPlaying1(true);
    };

    const onPlayPause1 = () => {

        // Abhi - If nothing works
        setAudioBlob1(false);
        setIsPlaying1(false);
        // setCurrentSongIndex(false);

        if (isPlaying && wavesurfer) {
            wavesurfer?.pause();
        }
        wavesurfer1 && wavesurfer1.playPause();
    };

    const playSong = (song, idx) => {
        setCurrentSongIndex(idx);
        axios
            .get(`${process.env.apiUrl}playTrack?track_id=${song.id}`, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
                responseType: "arraybuffer",
            })
            .then((response) => {
                const blob = new Blob([response.data]);

                setAudioBlob1(URL.createObjectURL(blob));
                setIsPlaying1(true);
            })
            .catch((err) => {
                // console.log(err);
                if (err?.response?.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                } else {
                    toast.error(err?.response?.request?.statusText || "File Not Found", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
    };

    const columns = [
        {
            title: "Music Title",
            dataIndex: "title",
            key: "title",
            width: "40%",
        },
        {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
        },
        {
            title: "BPM",
            dataIndex: "bpm",
            key: "bpm",
        },
        {
            title: (
                <div className="table-header-btn-wrap">
                    {userPermission?.includes("request_brief_edit_stem") && (
                        <Button
                            className="header-btn"
                            shape="round"
                            icon={<MdOutlineLayers />}
                            onClick={() =>
                                navigate("/request-edits-stems", {
                                    state: {
                                        type: "Stems",
                                        track_id: location?.search?.substring(1),
                                    },
                                })
                            }
                        >
                            Request Stems
                        </Button>
                    )}
                    {userPermission?.includes("request_brief_edit_stem") && (
                        <Button
                            className="header-btn"
                            shape="round"
                            icon={<MdOutlineModeEdit />}
                            onClick={() =>
                                navigate("/request-edits-stems", {
                                    state: {
                                        type: "Edits",
                                        track_id: location?.search?.substring(1),
                                    },
                                })
                            }
                        >
                            Request New Edit
                        </Button>
                    )}

                    {(userPermission?.includes("download_edits") ||
                        userPermission?.includes("download_stems") ||
                        userPermission?.includes("download_song")) &&
                        (downloadLoading ? (
                            <div style={{ width: "10%" }}>
                                <Loader height={0} marginTop={-5} />
                            </div>
                        ) : (
                            <BsDownload
                                size={25}
                                color="#fff"
                                style={{ cursor: "pointer" }}
                                onClick={() => downloadTrack()}
                            />
                        ))}
                </div>
            ),
            dataIndex: "btn",
            key: "btn",
        },
    ];

    const items = [
        {
            key: "1",
            label: "Edits",
            children: editsStemsTableLoading ? (
                <Loader height={0} marginTop={0} />
            ) : (
                <Table
                    dataSource={editStemsTableData}
                    columns={columns}
                    bordered={false}
                // scroll={{ y: 55 * 5 }}
                />
            ),
        },
        {
            key: "2",
            label: "Stems",
            children: <Table dataSource={editStemsTableData} columns={columns} />,
        },
    ];

    const onReady = (ws) => {
        wavesurfer1 && wavesurfer1?.pause();
        ws.on("audioprocess", () => {
            setCurrentTime(ws.getCurrentTime());
        });
        setWavesurfer(ws);
        setIsPlaying(false);
        // setDuration(ws.getDuration());
    };

    const downloadTrack = () => {
        setDownloadLoading(true);
        axios
            .get(
                `${process.env.apiUrl}download?track_ids=${location?.search?.substring(
                    1
                )}`,
                {
                    headers: {
                        Authorization: getCookie("accessToken"),
                    },
                    responseType: "arraybuffer",
                }
            )
            .then((response) => {
                const blob = new Blob([response.data], { type: "application/zip" });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.zip");
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                setDownloadLoading(false);
            })
            .catch((err) => {
                // console.log(err);
                setDownloadLoading(false);
                if (err?.response?.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                } else {
                    toast.error(err?.response?.request?.statusText || "File Not Found", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
    };

    const openDetailModal = (heading, data) => {
        setHeading(heading);
        setDetailModdsGenre(data);
        setShowDetailsModal(true);
    };

    const downloadMasterTrack = () => {
        setDownloadLoading(true);
        axios
            .get(
                `${process.env.apiUrl}masterDownload?track_id=${location?.search?.substring(
                    1
                )}`,
                {
                    headers: {
                        Authorization: getCookie("accessToken"),
                    },
                    responseType: "arraybuffer",
                }
            )
            .then((response) => {
                const contentType = response.headers.get("Content-Type");
                const audioType = contentType.includes("audio/mpeg") ? "audio/mpeg" : "audio/wav";

                const blob = new Blob([response.data], { type: audioType });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", getTrackDetailsData?.response?.data[0]?.title);
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                setDownloadLoading(false);
            })
            .catch((err) => {
                // console.log(err);
                setDownloadLoading(false);
                if (err?.response?.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                } else {
                    toast.error(err?.response?.request?.statusText || "File Not Found", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
    };

    const addTrackToPlaylist = (song, addRemove) => {
        const data = {
            track_id: clickedTrack?.id,
            playlist_id: song?.id,
            is_add: addRemove,
        };
        axios
            .post(`${process.env.apiUrl}addToPlaylist`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setResetList((prev) => !prev);
                setShowPlaylistFlag(true);
                toast.success(response?.data?.message || "success", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                handleCancel();
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                } else {
                    toast.error(err?.response?.request?.statusText || "Error", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
    };

    const createNewPlaylist = (value) => {
        const data = {
            playlist_name: value?.playlistName,
        };
        axios
            .post(`${process.env.apiUrl}CreatePlaylist`, data, {
                headers: {
                    Authorization: getCookie("accessToken"),
                },
            })
            .then((response) => {
                setResetList((prev) => !prev);
                setShowPlaylistFlag(true);
                toast.success(response?.data?.message || "success", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    toast.error("Session Expired. Please login again");
                    handleLogout();
                } else {
                    toast.error(err?.response?.request?.statusText || "Error", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
    };

    return (
        <div className="home-page">
            <Modal
                wrapClassName="upload-track-modal-wrap"
                title=""
                centered
                open={showDetailsModal}
                onOk={() => setShowDetailsModal(false)}
                onCancel={() => setShowDetailsModal(false)}
                width={750}
                footer={false}
                closable={false}
            >
                <div style={{ color: "#ffffff" }} className="view-more-tags-modal">
                    <h3>{heading}</h3>
                    {detailModdsGenre ? (
                        <div style={{ height: "60vh", overflow: "auto", paddingRight: "1px" }}>
                            {Object?.entries(detailModdsGenre)?.map(([key, value]) => (
                                <>
                                    <div className="value-wrap" key={key}>
                                        <p className="capitalize-first-letter">{value?.name}:</p>
                                        <p>{(value.score + 0)?.toFixed(4)}</p>
                                    </div>
                                    <Divider />
                                </>
                            ))}
                        </div>
                    ) : (
                        <div className="title-3">No Tracks Present</div>
                    )}
                    <div className="upload-back-btn-wraper">
                        <Button
                            className="upload-back-btn"
                            onClick={() => setShowDetailsModal(false)}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                centered
                open={isAddPlaylistModalOpen}
                onCancel={handleCancel}
                footer={false}
            >
                <div className="plylist-title">Add to Playlist</div>
                <Divider />
                {showPlaylistFlag ? (
                    <div className="outer-wraper">
                        {getPlayListData && getPlayListData?.playlist?.length !== 0 ? (
                            <div
                                className="outer-wraper"
                                style={{ maxHeight: "40vh", overflow: "auto" }}
                            >
                                {getPlayListData?.playlist?.map((item) => (
                                    <div
                                        key={item?.playlist_name}
                                        className="collection-card-wrap"
                                    >
                                        <div
                                            className="collection-card"
                                        >
                                            <div className="title">{item?.playlist_name}</div>
                                            <div className="count">{item?.total_songs} Songs</div>
                                        </div>
                                        {trackAddedPlaylist?.includes(item?.id) ? (
                                            <Button
                                                className="add-playlist-btn"
                                                style={{ background: "#AD2756" }}
                                                onClick={() => addTrackToPlaylist(item, false)}
                                            >
                                                Remove from Playlist
                                            </Button>
                                        ) : (
                                            <Button
                                                className="add-playlist-btn"
                                                onClick={() => addTrackToPlaylist(item, true)}
                                            >
                                                {" "}
                                                Add to Playlist
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={{ color: "#fff" }}>No Playlist Found</div>
                        )}
                        <Divider />

                        <div className="bottom-btn-wrap">
                            <Button
                                className="add-playlist-btn"
                                onClick={() => handleCancel()}
                            >
                                {" "}
                                Cancel
                            </Button>

                            <Button
                                className="add-playlist-btn"
                                onClick={() => setShowPlaylistFlag(false)}
                            >
                                {" "}
                                Create new Playlist
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Form
                        name="basic"
                        onFinish={createNewPlaylist}
                        className="outer-wraper"
                    >
                        <Form.Item
                            label="Playlist Name"
                            name="playlistName"
                            rules={[
                                {
                                    required: true,
                                    message: "Playlist Name can not be empty!",
                                },
                            ]}
                        >
                            <Input className="input-box" placeholder="Playlist Name" />
                        </Form.Item>
                        <Divider />
                        <div className="bottom-btn-wrap">
                            <Button
                                className="add-playlist-btn"
                                onClick={() => setShowPlaylistFlag(true)}
                            >
                                {" "}
                                Cancel
                            </Button>

                            <Button className="add-playlist-btn" htmlType="submit">
                                {" "}
                                Create
                            </Button>
                        </div>
                    </Form>
                )}
            </Modal>
            <Header showSearch={true} />
            <div className="home-page-components">

                <TopNavigation />
            </div>
            <div className="home-page-components">
                <div
                    className="back-btn"
                    style={{ position: "absolute" }}
                    onClick={() => navigate(-1)}
                >
                    <MdOutlineKeyboardBackspace />
                    Back
                </div>
                <div className="radar-and-player">
                    {getTrackDetailsData?.response?.data[0]?.characters.length !== 0 ? (
                        <ReactApexChart
                            options={config.options}
                            series={config.series}
                            type="radar"
                            height="400"
                            width={450}
                        />
                    ) : (
                        <div className="no-data-taxt">Track characters <br /> not available</div>
                    )}
                    <div className="music-player-wrap">
                        <div className="track-title" title={getTrackDetailsData?.response?.data[0]?.title}>
                            {getTrackDetailsData?.response?.data[0]?.title}
                        </div>
                        <div className="track-details">
                            <span>
                                <div>
                                    <span style={{ fontWeight: "bolder", fontSize: "18px" }} >Moods: </span>&nbsp;{" "}
                                    {getTrackDetailsData?.response?.data[0]?.moods.length === 0 && "-"}
                                    {getTrackDetailsData?.response?.data[0]?.moods
                                        ?.slice(0, 3)
                                        .map((itm) =>
                                            <Tag
                                                color="#dcaa3a"
                                                style={{ color: "#000" }}
                                                key={itm?.name}
                                            >
                                                {itm?.name}
                                            </Tag>
                                        )
                                    }
                                    {getTrackDetailsData?.response?.data[0]?.moods.length > 3 && (
                                        <Button
                                            className="header-btn"
                                            shape="round"
                                            style={{
                                                fontSize: "12px",
                                                margin: "10px",
                                                height: "auto",
                                            }}
                                            onClick={() =>
                                                openDetailModal(
                                                    "Moods",
                                                    getTrackDetailsData?.response?.data[0]?.moods
                                                )
                                            }
                                        >
                                            View More
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    <span style={{ fontWeight: "bolder", fontSize: "18px" }}>Genre:</span> &nbsp;{" "}
                                    {getTrackDetailsData?.response?.data[0]?.genres.length === 0 && "-"}
                                    {getTrackDetailsData?.response?.data[0]?.genres
                                        ?.slice(0, 2)
                                        .map((itm) =>

                                            <Tag
                                                color="#603FDD"
                                                style={{ color: "#fff" }}
                                                key={itm?.name}
                                            >
                                                {itm?.name}
                                            </Tag>
                                        )
                                    }
                                    {getTrackDetailsData?.response?.data[0]?.genres.length >
                                        2 && (
                                            <Button
                                                className="header-btn"
                                                shape="round"
                                                style={{
                                                    fontSize: "12px",
                                                    margin: "10px",
                                                    height: "auto",
                                                }}
                                                onClick={() =>
                                                    openDetailModal(
                                                        "Genres",
                                                        getTrackDetailsData?.response?.data[0]?.genres
                                                    )
                                                }
                                            >
                                                View More
                                            </Button>
                                        )}
                                </div>

                            </span>
                            <span style={{ borderRight: "1px solid #fff" }}></span>
                            <span>
                                <div style={{ marginBottom: "10px" }}>
                                    Duration: {/* {formatTime(duration)} */}{getTrackDetailsData?.response?.data[0]?.duration ? Math.ceil(getTrackDetailsData?.response?.data[0]?.duration) + " Sec" : "-"}
                                </div>
                                <div>
                                    BPM: &nbsp; {Math.round(getTrackDetailsData?.response?.data[0]?.bpm)}
                                </div>
                            </span>
                        </div>

                        <div className="music-player-wave-wraper">
                            {audioBlob &&
                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                    {isPlaying === true ? (
                                        <HiPause
                                            className="pause-btn"
                                            size={40}
                                            color="#fff"
                                            onClick={() => onPlayPause()}
                                            style={{ cursor: "pointer", zIndex: 1 }}
                                        />
                                    ) : (
                                        <HiPlay
                                            size={40}
                                            color="#fff"
                                            onClick={() => onPlayPause()}
                                            style={{ cursor: "pointer", zIndex: 1 }}
                                        />
                                    )}
                                    {(userPermission?.includes("download_edits") ||
                                        userPermission?.includes("download_stems") ||
                                        userPermission?.includes("download_song")) &&
                                        (downloadLoading ? (
                                            <div style={{ width: "10%" }}>
                                                <AiOutlineLoading3Quarters
                                                    size={40}
                                                    color="#fff"
                                                    className="all-icons" />
                                            </div>
                                        ) : (
                                            <BsDownload
                                                size={40}
                                                color="#fff"
                                                className="all-icons"
                                                style={{ cursor: "pointer", zIndex: 1 }}
                                                onClick={() => downloadMasterTrack()}
                                            />
                                        ))}
                                </div>
                            }

                            <div>
                                <div>
                                    <WavesurferPlayer
                                        height={80}
                                        waveColor="#363636"
                                        progressColor="#dcaa3a"
                                        autoplay={false}
                                        interact={audioBlob}
                                        url={audioBlob || blankAudio}
                                        onReady={(e) => onReady(e)}
                                        onPlay={() => setIsPlaying(true)}
                                        onPause={() => setIsPlaying(false)}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="tabs-section">
                <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />
            </div>
        </div>
    );
};

export default TrackDetails;
