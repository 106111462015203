/* eslint-disable no-unused-vars */
import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ reportData, name }) => {
  const monthNameShort = (monthNumber) => {
    const monthMoment = moment([2023, monthNumber - 1]);
    return monthMoment.format("MMM");
  };

  const lineChartConfig = {
    options: {
      chart: {
        // width: 1000,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      series: [
        {
          name: { name },
          data: reportData?.map((item, index) => item?.count),
        },
      ],
      xaxis: {
        categories: reportData?.map(
          (item, index) => monthNameShort(item?.month) + " " + item?.year
        ),
        labels: {
          style: {
            colors: "#fff",
            fontSize: "12px",
            fontFamily: "Montserrat-Regular",
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#fff",
            fontSize: "12px",
            fontFamily: "Montserrat-Regular",
            fontWeight: 400,
          },
        },
        min: 0,
      },
      grid: {
        show: false,
      },
      colors: ["#603FDD"],
      stroke: {
        curve: "smooth",
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
            <div class="arrow_box"> 
                <span>
                    ${w.globals.categoryLabels[dataPointIndex]} 
                        : 
                    ${series[seriesIndex][dataPointIndex]} ${name}
                </span>
            </div>`;
        },
      },
    },
  };
  return (
    <ReactApexChart
      type="line"
      height={330}
      // width={"auto"}
      options={lineChartConfig.options}
      series={lineChartConfig.options.series}
    />
  );
};

export default LineChart;
