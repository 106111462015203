/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { genericActionCreator } from "../../app/epics";
import { getCookie } from "../../common/utils";

const INITIAL_STATE = {
    data: null,
    loading: false,
    error: false,
    flag: false,
};

const POST_SIGNUP = "POST_SIGNUP";
const POST_SIGNUP_SUCCESS = "POST_SIGNUP_SUCCESS";
const POST_SIGNUP_FAILURE = "POST_SIGNUP_FAILURE";
const POST_SIGNUP_RESET = "POST_SIGNUP_RESET";

export const postSignupAction = (payload) =>
    genericActionCreator(POST_SIGNUP, payload, {
        url: `${process.env.apiUrl}register`,
        method: "POST",
        auth: true,
    });

export const postSignupResetAction = () => ({
    type: POST_SIGNUP_RESET,
});

export function postSignupReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case POST_SIGNUP_RESET: {
            return INITIAL_STATE;
        }
        case POST_SIGNUP: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case POST_SIGNUP_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case POST_SIGNUP_FAILURE: {
            return {
                ...state,
                data: null,
                loading: false,
                error: action.payload.response,
                flag: false,
            };
        }
        default:
            return state;
    }
}
