/* eslint-disable indent */
import { toast } from "react-toastify";
import { genericActionCreator } from "../../app/epics";
import { handleLogout } from "../../common/utils";

const INITIAL_STATE = {
    data: null,
    loading: false,
    error: false,
    flag: false,
};

const GET_GALLERY_COUNT = "GET_GALLERY_COUNT";
const GET_GALLERY_COUNT_SUCCESS = "GET_GALLERY_COUNT_SUCCESS";
const GET_GALLERY_COUNT_FAILURE = "GET_GALLERY_COUNT_FAILURE";
const GET_GALLERY_COUNT_RESET = "GET_GALLERY_COUNT_RESET";

const GET_MY_BRIEFS = "GET_MY_BRIEFS";
const GET_MY_BRIEFS_SUCCESS = "GET_MY_BRIEFS_SUCCESS";
const GET_MY_BRIEFS_FAILURE = "GET_MY_BRIEFS_FAILURE";
const GET_MY_BRIEFS_RESET = "GET_MY_BRIEFS_RESET";

const GET_PLAYLIST = "GET_PLAYLIST";
const GET_PLAYLIST_SUCCESS = "GET_PLAYLIST_SUCCESS";
const GET_PLAYLIST_FAILURE = "GET_PLAYLIST_FAILURE";
const GET_PLAYLIST_RESET = "GET_PLAYLIST_RESET";

const GET_MY_COLLECTION = "GET_MY_COLLECTION";
const GET_MY_COLLECTION_SUCCESS = "GET_MY_COLLECTION_SUCCESS";
const GET_MY_COLLECTION_FAILURE = "GET_MY_COLLECTION_FAILURE";
const GET_MY_COLLECTION_RESET = "GET_MY_COLLECTION_RESET";

const GET_MY_DOWNLOADS = "GET_MY_DOWNLOADS";
const GET_MY_DOWNLOADS_SUCCESS = "GET_MY_DOWNLOADS_SUCCESS";
const GET_MY_DOWNLOADS_FAILURE = "GET_MY_DOWNLOADS_FAILURE";
const GET_MY_DOWNLOADS_RESET = "GET_MY_DOWNLOADS_RESET";

const GET_MY_LIKES = "GET_MY_LIKES";
const GET_MY_LIKES_SUCCESS = "GET_MY_LIKES_SUCCESS";
const GET_MY_LIKES_FAILURE = "GET_MY_LIKES_FAILURE";
const GET_MY_LIKES_RESET = "GET_MY_LIKES_RESET";

const GET_MY_NOTES = "GET_MY_NOTES";
const GET_MY_NOTES_SUCCESS = "GET_MY_NOTES_SUCCESS";
const GET_MY_NOTES_FAILURE = "GET_MY_NOTES_FAILURE";
const GET_MY_NOTES_RESET = "GET_MY_NOTES_RESET";

export const getGalleryCountAction = (payload) =>
    genericActionCreator(GET_GALLERY_COUNT, payload.params, {
        url: `${process.env.apiUrl}myGallery`,
        method: "GET",
        auth: true,
    });

export const getGalleryCountResetAction = () => ({
    type: GET_GALLERY_COUNT_RESET,
});

export function getGalleryCountReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_GALLERY_COUNT_RESET: {
            return INITIAL_STATE;
        }
        case GET_GALLERY_COUNT: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_GALLERY_COUNT_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_GALLERY_COUNT_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}

export const getMyBriefsAction = (payload, url, pagination) =>
    genericActionCreator(GET_MY_BRIEFS, payload.params, {
        url: `${process.env.apiUrl}myBriefs?page=${pagination?.page ?? 1
            }&page_size=${pagination?.page_size ?? 10}`,
        method: "GET",
        auth: true,
    });

export const getMyBriefsResetAction = () => ({
    type: GET_MY_BRIEFS_RESET,
});

export function getMyBriefsReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_MY_BRIEFS_RESET: {
            return INITIAL_STATE;
        }
        case GET_MY_BRIEFS: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_MY_BRIEFS_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_MY_BRIEFS_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}

export const getPlayListAction = (payload) =>
    genericActionCreator(GET_PLAYLIST, payload.params, {
        url: `${process.env.apiUrl}playlist`,
        method: "GET",
        auth: true,
    });

export const getPlayListResetAction = () => ({
    type: GET_PLAYLIST_RESET,
});

export function getPlayListReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_PLAYLIST_RESET: {
            return INITIAL_STATE;
        }
        case GET_PLAYLIST: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_PLAYLIST_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_PLAYLIST_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}

export const getMyCollectionAction = (payload, url, pagination) =>
    genericActionCreator(GET_MY_COLLECTION, "", {
        url: `${process.env.apiUrl}getCollections?playlist_id=${payload?.playlist_id}&page=${pagination?.page ?? 1
            }&page_size=${pagination?.page_size ?? 10}`,
        method: "GET",
        auth: true,
    });

export const getMyCollectionResetAction = () => ({
    type: GET_MY_COLLECTION_RESET,
});

export function getMyCollectionReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_MY_COLLECTION_RESET: {
            return INITIAL_STATE;
        }
        case GET_MY_COLLECTION: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_MY_COLLECTION_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_MY_COLLECTION_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}

export const getMyDownloadsAction = (payload, url, pagination) =>
    genericActionCreator(GET_MY_DOWNLOADS, payload.params, {
        url: `${process.env.apiUrl}myDownload?page=${pagination?.page ?? 1
            }&page_size=${pagination?.page_size ?? 10}`,
        method: "GET",
        auth: true,
    });

export const getMyDownloadsResetAction = () => ({
    type: GET_MY_DOWNLOADS_RESET,
});

export function getMyDownloadsReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_MY_DOWNLOADS_RESET: {
            return INITIAL_STATE;
        }
        case GET_MY_DOWNLOADS: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_MY_DOWNLOADS_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_MY_DOWNLOADS_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}

export const getMyLikesAction = (payload, url, pagination) =>
    genericActionCreator(GET_MY_LIKES, payload.params, {
        url: `${process.env.apiUrl}myLikes?page=${pagination?.page ?? 1
            }&page_size=${pagination?.page_size ?? 10}`,
        method: "GET",
        auth: true,
    });

export const getMyLikesResetAction = () => ({
    type: GET_MY_LIKES_RESET,
});

export function getMyLikesReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_MY_LIKES_RESET: {
            return INITIAL_STATE;
        }
        case GET_MY_LIKES: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_MY_LIKES_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_MY_LIKES_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}

export const getMyNotesAction = (payload, url, pagination) =>
    genericActionCreator(GET_MY_NOTES, payload.params, {
        url: `${process.env.apiUrl}myNotes?page=${pagination?.page ?? 1
            }&page_size=${pagination?.page_size ?? 10}`,
        method: "GET",
        auth: true,
    });

export const getMyNotesResetAction = () => ({
    type: GET_MY_NOTES_RESET,
});

export function getMyNotesReducer(state, action = null) {
    if (typeof state === "undefined") {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case GET_MY_NOTES_RESET: {
            return INITIAL_STATE;
        }
        case GET_MY_NOTES: {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
                flag: false,
            };
        }
        case GET_MY_NOTES_SUCCESS: {
            return {
                ...state,
                data: action.payload.response,
                loading: false,
                error: false,
                flag: true,
            };
        }
        case GET_MY_NOTES_FAILURE: {
            if (action?.payload?.status === 401) {
                toast.error("Session Expired. Please login again");
                handleLogout();
            }
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
                flag: false,
            };
        }
        default:
            return state;
    }
}
