/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/index.js";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Modal, Tabs } from "antd";
import RequestTable from "../../components/ClientAdminRequestTable/RequestTable.jsx";
import AccessRequestDetailsModal from "../../components/AccessRequestDetailsModal/AccessRequestDetailsModal.jsx";
import UploadedTracksForm from "../../components/UploadedTracksForm/UploadedTracksForm.jsx";
import { getCookie, handleLogout } from "../../common/utils/index.js";
import TopNavigation from "../../components/TopNavigation/TopNavigation.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import SvgLoader from "../../common/SvgLoader/index.js";
import { RiDeleteBinLine } from "react-icons/ri";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import UploadEditStemsModal from "../../components/UploadEditStemsModal/index.jsx";

export default function AgencyUserUploadTrack() {
  const navigate = useNavigate();
  const userPermission = getCookie("permissions");

  const [showAccessRequestDetailsModal, setShowAccessRequestDetailsModal] =
    useState(false);
  const [selectedAccessRequest, setSelectedAccessRequest] = useState(false);
  const [audioBlob, setAudioBlob] = useState();
  const [licenceBlob, setLicenceBlob] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [allTrackData, setAllTrackData] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadingTrack, setUploadingTrack] = useState(false);
  const [uploadingLicence, setUploadingLicence] = useState(false);
  const [trackTitle, setTrackTitle] = useState(false);
  const [trackUploadsList, setTrackUploadsList] = useState(false);
  // const [blobSize, setBlobSize] = useState(false);
  const [addMoreModal, setAddMoreModal] = useState(false);
  const [activeTrack, setActiveTrack] = useState();
  const [activeTab, setActiveTab] = useState("Edits");
  const [uploadedEditsList, setUploadedEditsList] = useState([]);
  const [uploadedStemsList, setUploadedStemsList] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(false);

  useEffect(() => {
    if (!userPermission?.includes("upload_track")) {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.apiUrl}trackOptionAgentList`, {
        headers: {
          Authorization: getCookie("accessToken"),
        },
      })
      .then((response) => {
        setTrackUploadsList(response?.data?.response);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          toast.error("Session Expired. Please login again");
          handleLogout();
        }
      });
  }, [showUploadModal]);
  // const [checkBlob, setCheckBlob] = useState();
  // useEffect(() => {
  //   if (audioBlob !== undefined) {
  //     setBlobSize(audioBlob?.size);
  //     const demoBlob = URL.createObjectURL(audioBlob);
  //     setCheckBlob(demoBlob);
  //   }
  // }, [audioBlob !== undefined]);

  console.log({ activeTrack });
  useEffect(() => {
    if (activeTrack) {
      axios
        .get(`${process.env.apiUrl}trackOptionEditsStems`, {
          params: { track_id: activeTrack?.id, type: activeTab },
          headers: {
            Authorization: getCookie("accessToken"),
          },
        })
        .then((response) => {
          if (activeTab === "Edits") {
            setUploadedEditsList(response?.data?.response?.data);
          }
          if (activeTab === "Stems") {
            setUploadedStemsList(response?.data?.response?.data);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) {
            toast.error("Session Expired. Please login again");
            handleLogout();
          }
        });
    }
  }, [activeTrack, refreshFlag]);

  const accessRequestColumns = [
    {
      title: "",
      dataIndex: "key",
      key: "key",
      width: "5%",
      render: (name, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "Track Name",
      dataIndex: "name",
      key: "name",
      width: "35%",
      render: (title, record) => {
        return (
          <>
            <span className="ar-name-data">{record?.title}</span>
            <br />
            <span className="ar-name-email">
              {record?.uploaded_by ? record?.uploaded_by : ""}
            </span>
          </>
        );
      },
    },
    {
      title: "Uploaded On",
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
      render: (created_at) => moment(created_at).format("DD-MM-YYYY"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
      render: (title, record) => {
        return (
          <>
            <span>{record?.type}</span>
          </>
        );
      },
    },
    {
      title: "Master",
      dataIndex: "master",
      key: "master",
      width: "15%",
      render: (parent_track_id, record) => {
        return (
          <div>
            {record?.parent_track_id ? (
              <span
                className="ar-name-data"
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() =>
                  navigate(`/track-details?${record?.parent_track_id}`)
                }
              >
                Master Track
              </span>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      key: "status",
      width: "20%",
      render: (bmq_employee_status, record) => (
        <div>
          {record?.bmq_employee_status === "PENDING" && (
            <>
              <div className="ar-approved-text">Pending</div>
              {/* {record?.type === "Track" && (
                <span
                  className="ar-pending-text"
                  onClick={() => handleAddMore(event, record)}
                >
                  Add More
                </span>
              )} */}
            </>
          )}
          {record?.bmq_employee_status === "APPROVED" && (
            <>
              {" "}
              <div className="ar-approved-text">Approved</div>
              {record?.type === "Track" && (
                <span
                  className="ar-pending-text"
                  onClick={() => handleAddMore(event, record)}
                >
                  Add More
                </span>
              )}
            </>
          )}
          {record?.bmq_employee_status === "REJECTED" && (
            <div className="ar-rejected-text">Rejected</div>
          )}
        </div>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Your Uploads",
      children: (
        <RequestTable
          columns={accessRequestColumns}
          dataSource={trackUploadsList}
        />
      ),
    },
  ];

  const handleViewARDetails = (e, data) => {
    setSelectedAccessRequest(data);
    setShowAccessRequestDetailsModal(true);
  };

  const handleAddMore = (e, data) => {
    setActiveTrack(data);
    setAddMoreModal(true);
  };

  const handleBack = () => {
    // if (currentView === "details") {
    //   setCurrentView("upload");
    // } else if (currentView === "third") {
    //   setCurrentView("details");
    // } else if (currentView === "upload") {
    //   setShowUploadModal(false);
    // }
    setShowUploadModal(false);
  };

  return (
    <div className="home-page">
      <UploadEditStemsModal
        addMoreModal={addMoreModal}
        setAddMoreModal={setAddMoreModal}
        track={activeTrack}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        uploadedEditsList={uploadedEditsList}
        uploadedStemsList={uploadedStemsList}
        refreshFlag={refreshFlag}
        setRefreshFlag={setRefreshFlag}
      />
      <Modal
        wrapClassName="upload-track-modal-wrap"
        title=""
        centered
        open={showUploadModal}
        onOk={() => setShowUploadModal(false)}
        onCancel={() => setShowUploadModal(false)}
        width={900}
        footer={false}
        closable={false}
      >
        <>
          <UploadedTracksForm
            // setViewDetails={setViewDetails}
            setAllTrackData={setAllTrackData}
            // setShowUploadTrackToProcess={setShowUploadTrackToProcess}
            // viewDetails={viewDetails}
            uploading={uploading}
            setUploading={setUploading}
            setUploadingTrack={setUploadingTrack}
            uploadingTrack={uploadingTrack}
            setUploadingLicence={setUploadingLicence}
            uploadingLicence={uploadingLicence}
            allTrackData={allTrackData}
            setAudioBlob={setAudioBlob}
            audioBlob={audioBlob}
            setLicenceBlob={setLicenceBlob}
            licenceBlob={licenceBlob}
            setTrackTitle={setTrackTitle}
            trackTitle={trackTitle}
            setShowUploadModal={setShowUploadModal}
          />

          <div className="upload-back-btn-wraper">
            <Button className="upload-back-btn" onClick={handleBack}>
              Back
            </Button>
          </div>
        </>
      </Modal>

      <Header showSearch={true} />
      <div className="home-page-components">
        <TopNavigation />
      </div>

      <div className="uploaded-tracks-component">
        <div
          className="back-btn"
          style={{ position: "absolute" }}
          onClick={() => navigate(-1)}
        >
          <MdOutlineKeyboardBackspace />
          Back
        </div>

        <Button
          onClick={() => {
            setShowUploadModal(true);
          }}
          className="btn-upload-track"
          size="large"
        >
          Upload New Track
        </Button>

        <div className="client-admin-tabs-section">
          <AccessRequestDetailsModal
            selectedAccessRequest={selectedAccessRequest}
            showAccessRequestDetailsModal={showAccessRequestDetailsModal}
            setShowAccessRequestDetailsModal={setShowAccessRequestDetailsModal}
          />

          <Tabs
            className="client-admin-tabs"
            defaultActiveKey="1"
            items={items}
            // onChange={onChangeTab}
          />
        </div>
      </div>
    </div>
  );
}
