/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/index.js";
import SvgLoader from "../../common/SvgLoader/index.js";
// import SquareCard from "../../components/SquareCard";
import CircularCard from "../../components/CircularCard/index.js";
import {
  ClockCircleOutlined,
  DownloadOutlined,
  HeartOutlined,
  // MoreOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomeCardsAction,
  getRecentlyAddedAction,
  getSuggestionsAction,
} from "./logic.js";
import { getCookie, handleLogout } from "../../common/utils/index.js";
import WavesurferPlayer from "@wavesurfer/react";
import axios from "axios";
import { HiPause, HiPlay } from "react-icons/hi";
import { toast } from "react-toastify";
import TopNavigation from "../../components/TopNavigation/TopNavigation.jsx";
import Loader from "../../components/Loader/Loader.jsx";
import { RiMusic2Line } from "react-icons/ri";

const Search = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [genreId /* setGenreId */] = useState("");
  const [audioBlob, setAudioBlob] = useState();
  const blankAudio = "/music/silent_quarter-second.mp3";
  const [isPlaying, setIsPlaying] = useState(false);
  const [wavesurfer, setWavesurfer] = useState(null);
  const [currentSongIndex, setCurrentSongIndex] = useState();
  const [isSuggestion, setIsSuggestion] = useState();

  useEffect(() => {
    dispatch(getHomeCardsAction({}));
    dispatch(getRecentlyAddedAction({}));
    dispatch(getSuggestionsAction({}));
  }, []);

  const { data: homeCardData, loading: homeDataloading } = useSelector(
    (state) => state.homeCardData
  );
  const { data: recentlyAddedTracks } = useSelector(
    (state) => state.recentlyAddedTracks
  );
  const { data: suggestionsData } = useSelector(
    (state) => state.suggestionsData
  );

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(true);
  };

  const onPlayPause = () => {
    wavesurfer?.playPause();
    // setIsPlaying(false);
  };

  const playSong = (song, idx) => {
    setCurrentSongIndex(idx);

    axios
      .get(`${process.env.apiUrl}playTrack?track_id=${song.id}`, {
        headers: {
          Authorization: getCookie("accessToken"),
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data]);

        setAudioBlob(URL.createObjectURL(blob));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          toast.error("Session Expired. Please login again");
          handleLogout();
        } else {
          toast.error(err?.response?.request?.statusText || "File Not Found", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });

    setIsPlaying(true);
  };
  return (
    <div className="home-page">
      {" "}
      <Header showSearch={true} />
      <div className="home-page-components" style={{ display: "block" }}>
        <TopNavigation />
        {homeDataloading ? (
          <Loader />
        ) : (
          <div className="home-content" style={{ padding: "0 5%" }}>
            {/* {homeCardData && */}
            <div className="section-genre">
              <div className="section-genre-title">Top Genre</div>
              {homeCardData?.data?.genres?.length > 0 ? (
                <div className="section-genre-top">
                  {homeCardData?.data?.genres?.map((e, i) => {
                    return (
                      <div
                        key={e?.name}
                        onClick={() =>
                          navigate("/explore", {
                            state: {
                              genreId: [e?.name],
                              title: `Genre: ${e?.name}`,
                            },
                          })
                        }
                      >
                        <CircularCard
                          key={e?.name}
                          name={e?.name}
                          count={e?.count}
                          icon={e?.logo_url?.split(" ")[0]}
                          index={i}
                          imageHeight={150}
                          imageWidth={180}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="title-3"> No Tracks Present</div>
              )}
            </div>
            {/* } */}
            <div className="section-mood">
              <div className="section-mood-title">Top Mood</div>
              {homeCardData?.data?.moods?.length > 0 ? (
                <div className="section-mood-top">
                  {homeCardData?.data?.moods?.map((e, i) => {
                    return (
                      <div
                        key={e?.name}
                        onClick={() =>
                          navigate("/explore", {
                            state: {
                              moodId: [e?.name],
                              title: `Mood: ${e?.name}`,
                            },
                          })
                        }
                      >
                        <CircularCard
                          name={e?.name}
                          count={e?.count}
                          icon={e?.logo_url?.split(" ")[0]}
                          index={i}
                          key={e?.name}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="title-3"> No Tracks Present</div>
              )}
            </div>
            <div className="section-genre">
              <div className="section-genre-title">Top Purpose</div>
              {homeCardData?.data?.themes?.length > 0 ? (
                <div className="section-genre-top">
                  {homeCardData?.data?.themes?.map((e, i) => {
                    return (
                      <div
                        key={e?.name}
                        onClick={() =>
                          navigate("/explore", {
                            state: {
                              themeId: [e?.name],
                              title: `Theme: ${e?.name}`,
                            },
                          })
                        }
                      >
                        <CircularCard
                          key={e?.name}
                          name={e?.name}
                          count={e?.count}
                          icon={e?.logo_url?.split(" ")[0]}
                          index={i}
                          imageHeight={150}
                          imageWidth={180}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="title-3"> No Tracks Present</div>
              )}
            </div>
            {/* <div className="section-mood">
            <div className="section-mood-title">Popular/Trending</div>
            <div className="section-mood-top">
              {homeDataMood?.map((e, i) => {
                return <CircularCard data={e} index={i} key={i} />;
              })}
            </div>
          </div> */}
            <div className="section-recently-suggestion">
              <div className="section-recently">
                <div className="title-4">
                  <span>Recently Added</span>
                  {/* <span
                  className="title-see-all"
                  onClick={() =>
                    navigate("/explore", {
                      state: { genreId: genreId, title: "All Tracks" },
                    })
                  }
                >
                  See All
                </span> */}
                </div>

                {recentlyAddedTracks?.data?.length > 0 ? (
                  <div className="track-list">
                    {recentlyAddedTracks?.data?.map((e, i) => {
                      return (
                        <div className="track-details" key={e?.title}>
                          <span className="track-desc">
                            <div className="track-image">
                              <SvgLoader
                                svgName={`genre-${e?.genres.length > 0
                                  ? e?.genres[0]?.logo_url?.split(" ")[0]
                                  : "no-image-found"
                                  }-icon`}
                                // svgName="no-image-found"
                                height={30}
                                width={30}
                              />
                            </div>
                            <div
                              className="title-5"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/track-details?${e?.id}`)
                              }
                            >
                              {e?.title} <br />{" "}
                              <span className="song-type">
                                {e?.genres
                                  ?.map((e) => String(e.name))
                                  ?.join(", ")}
                              </span>
                            </div>
                          </span>
                          <div className="play-count">
                            {!isSuggestion && currentSongIndex === i ? (
                              isPlaying ? (
                                <HiPause
                                  className="pause-btn"
                                  size={30}
                                  color="#fff"
                                  onClick={() => onPlayPause(e, i)}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <HiPlay
                                  size={30}
                                  color="#fff"
                                  onClick={() => onPlayPause(e, i)}
                                  style={{ cursor: "pointer" }}
                                />
                              )
                            ) : (
                              <HiPlay
                                size={30}
                                color="#fff"
                                onClick={() => {
                                  setIsSuggestion(false);
                                  playSong(e, i);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                            <WavesurferPlayer
                              height={0}
                              width={0}
                              waveColor="#363636"
                              progressColor="#dcaa3a"
                              autoplay={true}
                              url={
                                currentSongIndex === i ? audioBlob : blankAudio
                              }
                              onReady={(e) => onReady(e)}
                              onPlay={() => setIsPlaying(true)}
                              onPause={() => setIsPlaying(false)}
                            />
                            <span className="viewed-count">
                              {e?.play_count} Plays
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="title-3"> No Tracks Present</div>
                )}
              </div>
              <div className="section-suggestion">
                <div className="title-4">
                  <span>Suggestions</span>
                  {/* <span
                  className="title-see-all"
                  onClick={() =>
                    navigate("/explore", {
                      state: { genreId: genreId, title: "All Tracks" },
                    })
                  }
                >
                  {" "}
                  See All
                </span> */}
                </div>
                {suggestionsData?.data?.length > 0 ? (
                  <div className="track-list">
                    {suggestionsData?.data?.map((e, i) => {
                      return (
                        <div className="track-details" key={e?.title}>
                          <span className="track-desc">
                            <div className="track-image">
                              <SvgLoader
                                svgName={`genre-${e?.genre.length > 0
                                  ? e?.genre[0]?.logo_url?.split(" ")[0]
                                  : "no-image-found"
                                  }-icon`}
                                // svgName="no-image-found"
                                height={30}
                                width={30}
                              />
                            </div>
                            <div
                              className="title-5"
                              onClick={() =>
                                navigate(`/track-details?${e?.id}`)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {e?.title} <br />{" "}
                              <span className="song-type">
                                Genre:{" "}
                                {e?.genre
                                  ?.map((e) => String(e.name))
                                  ?.join(", ")}
                              </span>
                            </div>
                          </span>
                          <div className="play-count">
                            {isSuggestion &&
                              currentSongIndex === i &&
                              (isPlaying ? (
                                <HiPause
                                  className="pause-btn"
                                  size={30}
                                  color="#dcaa3a"
                                  onClick={() => onPlayPause(e, i)}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <HiPlay
                                  size={30}
                                  color="#dcaa3a"
                                  onClick={() => onPlayPause(e, i)}
                                  style={{ cursor: "pointer" }}
                                />
                              ))}

                            {(!isSuggestion || currentSongIndex !== i) && (
                              <HiPlay
                                size={30}
                                color="#dcaa3a"
                                onClick={() => {
                                  setIsSuggestion(true);
                                  playSong(e, i);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                            <RiMusic2Line />

                            <span className="viewed-count">
                              {e?.play_count}
                            </span>
                          </div>
                          <div className="track-stats">
                            <span>
                              <HeartOutlined /> {e?.liked_count}
                            </span>
                            <span>
                              <DownloadOutlined /> {e?.download_count}
                            </span>
                            <span>
                              <ClockCircleOutlined />{" "}
                              {/* {moment(e?.duration, "mm:ss.SSS").format("mm:ss")} */}
                              {e?.duration
                                ? Math.ceil(e?.duration) + " Sec"
                                : "-"}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="title-3"> No Tracks Present</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>{" "}
    </div>
  );
};

export default Search;
