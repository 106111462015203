import { combineReducers } from "@reduxjs/toolkit";
import { modalReducer } from "./Modal/logic";
import { sessionReducer } from "./Status/logic";
import { customNotificationReducer, notificationReducer } from "../common/Notification/logic";
import { getImagesReducer } from "./pics/Pics.logic";
import { postSignupReducer } from "../pages/signup/logic";
import { getHomeCardsReducer, getRecentlyAddedReducer, getSuggestionsReducer } from "../pages/search/logic";
import { getCharactersListReducer, getGenresListReducer, getMoodsListReducer, getThemesListReducer, getTrackListReducer, storeTrackIdReducer } from "../pages/explorePage/logic";
import { bmqAcceptedBriefRequestReducer, getAccessRequestListReducer, getBriefRequestListReducer, tracksRecievedReducer } from "../pages/ClientAdmin/logic";
import { postLoginReducer } from "../pages/login/logic";
import { getEditsDetailsReducer, getTrackDetailsReducer } from "../pages/trackDetails/logic";
import { getGalleryCountReducer, getMyBriefsReducer, getMyCollectionReducer, getMyDownloadsReducer, getMyLikesReducer, getMyNotesReducer, getPlayListReducer } from "../pages/MyGallery/logic";

const rootReducer = combineReducers({
  modal: modalReducer,
  session: sessionReducer,
  notification: notificationReducer,
  customNotification: customNotificationReducer,
  images: getImagesReducer,
  signupData: postSignupReducer,
  loginData: postLoginReducer,
  homeCardData: getHomeCardsReducer,
  trackListData: getTrackListReducer,
  clientAdminAccessRequestData: getAccessRequestListReducer,
  clientAdminBriefRequestData: getBriefRequestListReducer,
  bmqAcceptedBriefRequestData: bmqAcceptedBriefRequestReducer,
  storeTrackId: storeTrackIdReducer,
  genresList: getGenresListReducer,
  themesList: getThemesListReducer,
  moodsList: getMoodsListReducer,
  charactersList: getCharactersListReducer,
  recentlyAddedTracks: getRecentlyAddedReducer,
  getTrackDetailsData: getTrackDetailsReducer,
  getEditsDetailsData: getEditsDetailsReducer,
  tracksReceivedData: tracksRecievedReducer,
  getGalleryCountData: getGalleryCountReducer,
  getMyBriefsData: getMyBriefsReducer,
  getPlayListData: getPlayListReducer,
  getMyCollectionData: getMyCollectionReducer,
  getMyDownloadsData: getMyDownloadsReducer,
  getMyLikesData: getMyLikesReducer,
  getMyNotesData: getMyNotesReducer,
  suggestionsData: getSuggestionsReducer,
});

export default rootReducer;
