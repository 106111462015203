/* eslint-disable indent */
import { Button, Card } from "antd";
import React from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import TopNavigation from "../../components/TopNavigation/TopNavigation.jsx";
import { getCookie } from "../../common/utils/index.js";

const Home = () => {
    const navigate = useNavigate();
    const userPermission = getCookie("permissions");

    return (
        <div className="login-page" style={{ padding: 0 }}>
            {" "}
            <Header showSearch={true} />
            <div className="home-page-components">
                <TopNavigation />
            </div>
            <div className="home-page-card-wrap">
                <Card className="home-page-card">
                    <div className="home-page-card-inner">
                        <div className="card-title">Search</div>
                        <div className="card-content">
                            Explore Tracks: Browse through a diverse collection of tracks and
                            use filters to refine your search by mood, genre, theme, and more.
                            Discover the perfect soundtrack for any moment.
                        </div>
                    </div>
                    <Button className="card-btn" onClick={() => navigate("/search")}>
                        Search
                    </Button>
                </Card>
                <Card className="home-page-card">
                    <div className="home-page-card-inner">
                        <div className="card-title">Store</div>
                        <div className="card-content">
                            Track Submission: A dedicated space for external agencies to
                            upload and store custom audio tracks created on request.
                            Effortlessly manage and organize the tracks you contribute to the
                            platform, making them accessible to users as part of the library.
                        </div>
                    </div>
                    <Button
                        className="card-btn"
                        onClick={() => navigate("/uploaded-tracks")}
                        disabled={!userPermission?.includes("upload_track")}
                    >
                        Store
                    </Button>
                </Card>
                <Card className="home-page-card">
                    <div className="home-page-card-inner">
                        <div className="card-title">Spark</div>
                        <div className="card-content">
                            Track Request: Submit a request for custom tracks tailored to your
                            specific needs. Specify criteria such as mood, genre, theme, use
                            case, duration, and more to help us deliver the perfect audio
                            track for your project.
                        </div>
                    </div>
                    <Button
                        className="card-btn"
                        onClick={() => navigate("/request-new-track")}
                        disabled={!userPermission?.includes("request_brief_edit_stem")}
                    >
                        Spark
                    </Button>
                </Card>
            </div>
        </div>
    );
};

export default Home;
