/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Row,
  Switch,
} from "antd";
import axios from "axios";
import { getCookie, handleLogout } from "../../common/utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAccessRequestListAction } from "../../pages/ClientAdmin/logic";
import moment from "moment";

export default function AccessRequestDetailsModal({
  showAccessRequestDetailsModal,
  setShowAccessRequestDetailsModal,
  selectedAccessRequest,
  roles,
  permissionList,
}) {
  const dispatch = useDispatch();
  const user_details_col1_span = 16;
  const user_details_col2_span = 8;

  const permissionsToShowAsToggles = [
    "download_stems",
    "upload_track",
    "download_song",
    "view_song",
    "request_brief_edit_stem",
    "download_edits",
  ];
  const [visible, setVisible] = useState(false);
  const [clientList, setClientList] = useState();
  const [selectedClients, setSelectedClients] = useState("");

  useEffect(() => {
    if (!clientList) {
      getAllClients();
    }
  }, []);

  const getAllClients = async () => {
    try {
      const response = await axios.get(`${process.env.apiUrl}clientList`, {
        headers: {
          Authorization: getCookie("accessToken"),
          accept: "application/json",
        },
      });
      if (response.data.status == 200 && response.data.response) {
        // console.log("Fetched clients Successfully", response.data.response);
        let clients = [
          // {
          //   label: 'All',
          //   value: '',
          // },
        ];

        if (Array.isArray(response.data.response)) {
          response.data.response.forEach((cl) => {
            clients.push({
              label: cl.name,
              value: cl.id,
            });
          });
        }
        setClientList(clients);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      // console.log("Error in gettign client list", err);
    }
  };

  const initialPermissionState = permissionsToShowAsToggles.reduce(
    (acc, key) => {
      acc[key] = selectedAccessRequest?.permission?.includes(key) || false;
      return acc;
    },
    {}
  );

  const [permissionToggles, setPermissionToggles] = useState(
    initialPermissionState
  );
  const [validUpto, setValidUpto] = useState(false);

  useEffect(() => {
    let newTg = [];
    newTg = permissionsToShowAsToggles.reduce((acc, key) => {
      acc[key] = selectedAccessRequest?.permission?.includes(key) || false;
      return acc;
    }, {});
    setPermissionToggles(newTg);

    setValidUpto(
      selectedAccessRequest?.valid_till
        ? moment(selectedAccessRequest?.valid_till)
        : false
    );

    return () => {
      setPermissionToggles(initialPermissionState);
    };
  }, [selectedAccessRequest]);

  const handleOnApprovalDurationChange = (e) => {
    setValidUpto(e);
  };

  const approveAccessRequest = async (id, type) => {
    try {
      let encodedDate;
      if (type != "REJECT") {
        if (validUpto) {
          let date = new Date(validUpto);
          let formatedDate = date.toISOString();
          encodedDate = encodeURIComponent(formatedDate);
        } else {
          toast.error("Please mention valid upto date", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }
      }

      let selectedPermissions = [];
      for (const key in permissionToggles) {
        if (Object?.prototype?.hasOwnProperty?.call(permissionToggles, key)) {
          if (permissionToggles[key]) {
            selectedPermissions.push(key);
          }
        }
      }

      let url = "";
      if (type === "APPROVE") {
        url = `${
          process.env.apiUrl
        }admin/accessRequest?status=APPROVED&id=${id}&valid_till=${encodedDate}&permission=${selectedPermissions}&client_tobe_handled=${
          selectedClients?.value || ""
        }`;
      } else if (type == "REJECT") {
        url = `${process.env.apiUrl}admin/accessRequest?status=REJECTED&id=${id}`;
      } else if (type === "UPDATE") {
        // above api is been updated to have access request id and then update the user, currently it pudates the permissions of the logged in user
        url = `${
          process.env.apiUrl
        }permissions?valid_till=${encodedDate}&id=${id}&permission=${selectedPermissions}&client_tobe_handled=${
          selectedClients?.value || ""
        }`;
      }

      const response = await axios.put(url, [], {
        headers: {
          Authorization: getCookie("accessToken"),
          accept: "application/json",
        },
      });
      if (response.status === 200 || response.data.data) {
        toast.success(
          response?.data?.message ||
            `Access Request ${
              type === "UPDATE" ? "Updated" : "Approved"
            } Successfully`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
        dispatch(getAccessRequestListAction());
        setShowAccessRequestDetailsModal(false);
      }
    } catch (err) {
      console.log("Error in Approving Request", err);
      if (err?.response?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      } else {
        toast.error(
          err.response?.data?.message ||
            `Error in ${
              type === "UPDATE"
                ? "Updating"
                : type == "REJECT"
                ? "Rejecting"
                : "Approving"
            } Request`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    }
  };

  const handleAccessRequestAccept = (e, requestDetails) => {
    approveAccessRequest(requestDetails.id, "APPROVE");
  };

  const handleAccessRequestReject = (e, requestDetails) => {
    approveAccessRequest(requestDetails.id, "REJECT");
  };

  const handlePermissionSwitchChange = (value, type) => {
    setPermissionToggles((prev) => ({ ...prev, [type]: value }));
  };

  const handleAccessRequestUpdate = (e, requestDetails) => {
    approveAccessRequest(requestDetails.id, "UPDATE");
  };

  const clientItems =
    clientList &&
    clientList?.map((item, index) => ({
      key: item.value,
      label: item.label,
      onClick: () => {
        setSelectedClients(item);
      },
    }));

  const handleClose = () => {
    setVisible(false);
  };

  return selectedAccessRequest ? (
    <div className="access-request-details-modal">
      <Modal
        wrapClassName="access-request-details-modal-wrap"
        title="Access Request"
        centered
        open={showAccessRequestDetailsModal}
        onOk={() => setShowAccessRequestDetailsModal(false)}
        onCancel={() => setShowAccessRequestDetailsModal(false)}
        width={1000}
        footer={false}
      >
        <Divider />

        <div className="access-request-user-details">
          <Row>
            <Col span={user_details_col1_span}>Name:</Col>
            <Col span={user_details_col2_span}>
              {selectedAccessRequest.name}
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>Organisation Name:</Col>
            <Col span={user_details_col2_span}>
              {selectedAccessRequest.organization_name}
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>Business Email:</Col>
            <Col span={user_details_col2_span}>
              {selectedAccessRequest.email}
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>Purpose:</Col>
            <Col span={user_details_col2_span}>
              {selectedAccessRequest.purpose}
            </Col>
          </Row>
          <Row>
            <Col span={user_details_col1_span}>Referee Email:</Col>
            <Col span={user_details_col2_span}>
              {selectedAccessRequest.referee_email}
            </Col>
          </Row>
        </div>

        <Row className="permission-title">
          <Col span={24}>Permissions</Col>
        </Row>

        <Divider />

        <div className="permissions-contents">
          {roles?.includes("BMQ Admin") && (
            <Row>
              <Col span={user_details_col1_span}>Client to be Handled:</Col>
              <Col
                span={user_details_col2_span}
                style={{ marginBottom: "10px" }}
              >
                {/* <input
                  type="text"
                  placeholder="Text (Infosys)"
                  className="arm-permission-client-input-text"
                /> */}
                <div className="client-dropdown">
                  <div
                    className="collapse-wraper"
                    style={{
                      border: "1px solid",
                      borderRadius: "6px",
                      width: "220px",
                      height: "44px",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 15px",
                    }}
                  >
                    <Dropdown
                      overlay={
                        <Menu onClick={handleClose} items={clientItems} />
                      }
                      visible={visible}
                      onVisibleChange={setVisible}
                      trigger={["click"]}
                      className="sort-by-filter"
                    >
                      <span>
                        {" "}
                        {selectedClients?.label ||
                          selectedAccessRequest?.client_tobe_handled?.name ||
                          "Client"}
                      </span>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col span={user_details_col1_span}>Approval Duration:</Col>
            <Col span={user_details_col2_span}>
              <DatePicker
                format={{
                  format: "DD MMMM YYYY",
                }}
                showNow={false}
                onChange={handleOnApprovalDurationChange}
                value={validUpto}
                disabledDate={(current) => {
                  let today = moment().format("YYYY-MM-DD");
                  return current && current < moment(today, "YYYY-MM-DD");
                }}
              />
            </Col>
          </Row>
          {permissionList &&
          Array.isArray(permissionList) &&
          permissionList.length > 0
            ? permissionList.map((permission) => {
                if (permissionsToShowAsToggles.includes(permission.value)) {
                  return (
                    <Row key={permission.value}>
                      <Col span={user_details_col1_span}>
                        {permission.label}
                      </Col>
                      <Col span={user_details_col2_span}>
                        No
                        <Switch
                          onChange={(value) => {
                            handlePermissionSwitchChange(
                              value,
                              permission.value
                            );
                          }}
                          className="toggle-switch"
                          checked={permissionToggles[permission.value]}
                        />
                        Yes
                      </Col>
                    </Row>
                  );
                }
              })
            : null}

          <Row className="access-request-modal-buttons">
            <Col span={7}></Col>
            {selectedAccessRequest.status == "APPROVED" ? (
              <Col span={10} className="align-center">
                <Button
                  onClick={(e) => {
                    // change permission api call
                    handleAccessRequestUpdate(e, selectedAccessRequest);
                  }}
                  className="arm-btn-approve"
                  size="large"
                >
                  Update Request
                </Button>
              </Col>
            ) : (
              <>
                <Col span={5}>
                  <Button
                    onClick={(e) => {
                      handleAccessRequestAccept(e, selectedAccessRequest);
                    }}
                    className="arm-btn-approve"
                    size="large"
                  >
                    Approve
                  </Button>
                </Col>
                <Col span={5}>
                  <Button
                    className="arm-btn-reject"
                    onClick={(e) => {
                      handleAccessRequestReject(e, selectedAccessRequest);
                    }}
                    size="large"
                    disabled={selectedAccessRequest.status == "APPROVED"}
                  >
                    Reject
                  </Button>
                </Col>
              </>
            )}
            <Col span={7}></Col>
          </Row>
        </div>

        <div></div>
      </Modal>
    </div>
  ) : null;
}
