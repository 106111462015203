/* eslint-disable indent */
import Rcookie from "react-cookies";
import queryString from "query-string";
import auth0 from "auth0-js";
// import { Navigate } from "react-router";
// import { toast } from "react-toastify";
import axios from "axios";
// import { useState } from "react";

export const PATHS = [
  ...Object.keys(process.env.APPS).map(
    (item) => process.env.APPS[item].mainRoute
  ),
];

export const setCookie = (value, key) => {
  const { domain } = process.env;
  const expires = new Date();
  expires.setDate(new Date().getDate() + 1);
  Rcookie.save(`${process.env.app}_${key}_${process.env.type}`, value, {
    path: "/",
    domain,
    expires,
  });
};

export const removeCookies = (flag) => {
  const cookies = document.cookie.split(";");
  const { domain } = process.env;
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    PATHS.forEach((item) => {
      if (flag) {
        if (name.includes(`${process.env.app}_`)) {
          document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
        }
      } else {
        document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
      }
      return 0;
    });
  }
};

// export const generateHashUrl = (value, navigate) => {
//   navigate(`/search?${queryString.stringify(value)}`, { replace: true });
//   navigate(0);
// };

export const getCookie = (key, app) =>
  Rcookie.load(`${app || process.env.app}_${key}_${process.env.type}`);

export function ucFirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

const webAuth = new auth0.WebAuth({
  domain: process.env.auth0.domain,
  clientID: process.env.auth0.clientId,
  audience: process.env.auth0.audience,
  response_type: "token id_token",
});

export const handleLogout = () => {
  // const [loading, setLoading] = useState(false);
  // const { loading, setLoading } = props;

  // console.log(loading, setLoading);
  if (window.location.pathname !== "/signup") {
    // setLoading(true);
    axios
      .post(`${process.env.apiUrl}logout`, [], {
        headers: {
          Authorization: getCookie("accessToken"),
        },
      })
      .then(() => {
        // setLoading(false);
        console.log("object");
        removeCookies("accessToken");
        removeCookies("permissions");
        localStorage.removeItem("loggedin");
        webAuth.logout({
          returnTo: `${process.env.appUrl}login`,
          clientID: process.env.auth0.clientId,
        });
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
        removeCookies("accessToken");
        removeCookies("permissions");
        localStorage.removeItem("loggedin");
        webAuth.logout({
          returnTo: `${process.env.appUrl}login`,
          clientID: process.env.auth0.clientId,
        });
        // toast.error(err?.response?.request?.statusText || "Error", {
        //   position: "top-center",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        // });
      });

    // toast.error("session expire please relogin", {
    //   position: "top-center",
    //   autoClose: 2000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    // });
    // setTimeout(() => {
    //   Navigate("/login");
    // }, [1000]);
  }
  return null;
};

export default function apiCall(
  url,
  method,
  body = {},
  authReq = true,
  customHeaders = {}
) {
  let obj = {};
  if (method === "POST" || method === "PUT" || method === "DELETE") {
    obj = {
      method,
      url,
      body,
    };
  } else {
    obj = {
      method,
      url: `${url}${body && Object.keys(body).length
        ? `?${queryString.stringify(body)}`
        : ""
        }`,
    };
  }
  const headers = {
    Authorization: Rcookie.load(
      `${process.env.app}_accessToken_${process.env.type}`
    )
      ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`)
      : handleLogout(),
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": `${process.env.applicationUrl}`,
    // "Access-Control-Allow-Origin": "*",
    // Authorization: "Bearer yJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImU2SmJFeHd0RDBmVERqcDVVRDNkNyJ9.eyJpc3MiOiJodHRwczovL3N0YWdpbmctc29uaWN2YXVsdC5qcC5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NjY4ZTMxZTFjMTU1NmI0MGUzYjczN2IzIiwiYXVkIjpbInN0YWdpbmctc29uaXEtdmF1bHQtYXBpIiwiaHR0cHM6Ly9zdGFnaW5nLXNvbmljdmF1bHQuanAuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTcyMzY0MTIwMSwiZXhwIjoxNzIzNzI3NjAxLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiZ3R5IjoicGFzc3dvcmQiLCJhenAiOiJ2TkIwbTVYZzIyNTh2Wnl1REN0NW96aWVVbVlLSk9oNiIsInBlcm1pc3Npb25zIjpbImFkbWluIiwiVXNlciJdfQ.iqS6RR37BjYYBE5-g5GNbBhYGg4r_Joek7bU_1WWIaft1y584vjby_pzSn7s8dHxXBoRsSJ8afttAy24HTzVo1t_iIH7Wh8maCBHeiV2nzU_lEX0xvA6saGk3svC81dRlqSU5SbG0lb6uDOCoM5oWMfN6dqzxODSNUcaVsoEIgPBH6F8IUWtbN02XCcg2MasQOhtYW9xE4JDwU9YJMtdLWV_cYRfoBDkPG-OI_oJ5qsizIgGIJAgixsxlfdYxsFwTrf43OThEVjJlxxTML8W3EOguAwCBArRO0zXnmsGPdRObR85fMtT-ZAdJX4xFmkIqhtkmTMByZPyG4xkT-W3OA",

    ...customHeaders,
  };
  Object.entries(headers).forEach((en) => {
    if (en[1] === undefined || (!authReq && en[0] === "Authorization")) {
      delete headers[en[0]];
    }
  });
  return {
    ...obj,
    headers,
  };
}

export const checkPermission = (value) => {
  if (getCookie("permissions")) {
    return getCookie("permissions").indexOf(value) > -1;
  }
  return false;
};
