/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Checkbox, Divider, Form, Input, Tooltip, Upload } from "antd";
import React, { useEffect, useState } from "react";
import SvgLoader from "../../common/SvgLoader";
import { getCookie, handleLogout } from "../../common/utils";
import axios from "axios";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { FaRegFileAlt } from "react-icons/fa";
import Loader from "../Loader/Loader.jsx";
import { HiPause, HiPlay } from "react-icons/hi";
import WavesurferPlayer from "@wavesurfer/react";
import { useDispatch, useSelector } from "react-redux";
import { getThemesListAction } from "../../pages/explorePage/logic.js";

export default function UploadedTracksForm({
  setAllTrackData,
  uploading,
  setUploading,
  setUploadingTrack,
  uploadingTrack,
  setUploadingLicence,
  uploadingLicence,
  allTrackData,
  setAudioBlob,
  audioBlob,
  setLicenceBlob,
  licenceBlob,
  setShowUploadModal,
  // setTrackTitle,
  // trackTitle,
  blobSize,
}) {
  const [stepForm] = Form.useForm();

  const [trackName, setTrackName] = useState("");
  const [licenseName, setLicenceName] = useState("");
  const [trackTitle, setTrackTitle] = useState("");
  const [sendForApprovalLoading, setSendForApprovalLoading] = useState(false);
  const [currentSongIndex, setCurrentSongIndex] = useState(1);
  const [trackLoading, setTrackLoading] = useState(false);
  const [audioBlob1, setAudioBlob1] = useState();
  const [wavesurfer1, setWavesurfer1] = useState(null);
  const [isPlaying1, setIsPlaying1] = useState(false);
  const [selectedPurpose, setSelectedPurpose] = useState([]);
  const blankAudio = "/music/silent_quarter-second.mp3";

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getThemesListAction({}));
  }, []);

  const { data: themesList } = useSelector((state) => state?.themesList);

  const onChangeThemeTopic = (e, idx) => {
    if (!e?.target?.checked) {
      setSelectedPurpose((prev) => prev.filter((item) => item !== idx));
    } else {
      setSelectedPurpose((prev) => [...prev, idx]);
    }
  };

  const onFinish = (values) => {
    // stepForm
    //   .validateFields()
    //   .then(() => {
    //     setFormData((prev) => ({ ...prev, ...values }));
    //     setUploadSucess(true);
    //   })
    //   .catch((errorInfo) => {
    //     console.log('Failed:', errorInfo);
    //   });
    // console.log({ formData });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleValueChange = (v) => {
    if (v.track_title) {
      setTrackTitle(v.track_title);
    }
  };

  const handleUploadAction = async (file) => {
    try {
      setUploading(true);
      const blob = new Blob([file?.file], { type: file?.file?.type });
      setAudioBlob(blob);
      const response = await axios.post(
        `${process.env.apiUrl}admin/get_mood_list`,
        { file_url: file.file },
        {
          headers: {
            Authorization: getCookie("accessToken"),
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      if (response?.status === 200 && response.data.data) {
        // console.log("Upload Successful", response.data);
        // let data = response.data.data;
        // data.moods = data.moods.random();
        setAllTrackData(response.data.data);
        // setShowUploadTrackToProcess(false);
        setUploading(false);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      console.log("Uppload Track Error", err);
    }
  };

  const handleUploadFile = ({ file }) => {
    setUploadingTrack(true);
    setTrackName(file?.name);
    // const blob = new Blob([file?.file], { type: file?.file?.type });
    setAudioBlob(file);
  };

  const handleUploadLicence = ({ file }) => {
    setUploadingLicence(true);
    setLicenceName(file?.name);
    // const blob = new Blob([file?.file], { type: file?.file?.type });
    setLicenceBlob(file);
  };

  const handleDelete = () => {
    setAllTrackData(false);
  };

  const sendForApproval = async () => {
    setSendForApprovalLoading(true);

    const formData = new FormData();
    formData.append("title", trackTitle);
    formData.append("file", audioBlob);
    formData.append("type", "Track");
    // formData.append("licence", licenceBlob);
    formData.append("purpose", selectedPurpose);

    try {
      setUploading(true);
      const response = await axios.post(
        `${process.env.apiUrl}uploadAgentTrackView`,

        formData,

        {
          headers: {
            Authorization: getCookie("accessToken"),
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      if (response?.status === 200 && response.data.data) {
        toast.success("Track Uploaded Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setShowUploadModal(false);
        setTrackName("");
        setLicenceName("");
        setAudioBlob("");
        setLicenceBlob();
        setUploading(false);
        setSendForApprovalLoading(false);
        setSelectedPurpose([]);
        setTrackName("");
      }
    } catch (err) {
      if (err.response?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      console.log("Uppload Track Error", err);
      toast.error(err?.response?.data?.error);
      setSendForApprovalLoading(false);
    }
  };

  const handlePlayCurrent = () => {
    // wavesurfer && wavesurfer.playPause();
    setCurrentSongIndex(1);

    setTrackLoading(true);

    setIsPlaying1(true);
    // const blob = new Blob(audioBlob);
    // setAudioBlob1(audioBlob);
    setTrackLoading(false);

    setTrackLoading(false);
  };

  const onReady1 = (ws) => {
    setWavesurfer1(ws);
    setIsPlaying1(true);
  };

  const onPlayPause1 = () => {
    wavesurfer1?.playPause();
    // setCurrentSongIndex(null);
  };

  return (
    <div className="upload-track-for-processing">
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        // onSubmit={onFinish}
        form={stepForm}
        onValuesChange={handleValueChange}
      >
        <div className="step-1">
          <div className="title-wrapper">
            <div style={{ display: "flex" }}>
              <SvgLoader svgName="track-upload-icon" height={60} width={60} />
            </div>
            <Form.Item>
              <div className="upload-back-btn-wraper">
                {sendForApprovalLoading ? (
                  <div style={{ margin: "-40px 60px 0px 0" }}>
                    <Loader height={0} marginTop={0} />
                  </div>
                ) : (
                  <Button
                    className="upload-back-btn"
                    onClick={sendForApproval}
                    disabled={!audioBlob}
                  >
                    Send For Approval
                  </Button>
                )}
              </div>
            </Form.Item>
          </div>

          <Divider />

          <Form.Item
            className="track-title-form-item"
            label=""
            name="track_title"
          >
            <Input
              className="track-title-input"
              placeholder="Type Track Name"
              onChange={(e) => setTrackTitle(e?.target?.value)}
            />
          </Form.Item>

          <div className="checkbox-wrap-upload">
            {audioBlob ? (
              <div className="file-upload-card">
                <SvgLoader svgName={"track-thumbnail"} width={40} height={40} />
                <div className="file-upload-card-text">
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        className="file-upload-text"
                        style={{ fontSize: "14px" }}
                      >
                        {trackName}
                      </span>

                      <div className="file-upload-text-wrap">
                        <span
                          className="file-upload-text"
                          style={{ color: "#A9ACB4" }}
                        >
                          {audioBlob
                            ? (audioBlob?.size / 1000000).toFixed(2)
                            : "0"}{" "}
                          MB
                        </span>
                        <span className="file-upload-text">•</span>

                        <span className="file-upload-text">
                          <SvgLoader svgName={"done"} width={20} height={20} />
                          Completed
                        </span>
                      </div>
                    </div>

                    {/* <div>
                      {trackLoading && currentSongIndex === 1 ? (
                        <div style={{ width: "10%" }}>
                          <Loader height={0} marginTop={-5} />
                        </div>
                      ) : (
                        currentSongIndex === 1 &&
                        (isPlaying1 === true ? (
                          <HiPause
                            className="pause-btn"
                            size={60}
                            color="#fff"
                            onClick={() => onPlayPause1()}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <HiPlay
                            size={60}
                            color="#fff"
                            onClick={() => onPlayPause1()}
                            style={{ cursor: "pointer" }}
                          />
                        ))
                      )}
                      {currentSongIndex !== 1 && (
                        <HiPlay
                          size={60}
                          color="#fff"
                          onClick={() => handlePlayCurrent()}
                          style={{ cursor: "pointer" }}
                        />
                      )}

                      <WavesurferPlayer
                        height={0}
                        width={0}
                        waveColor="#363636"
                        progressColor="#dcaa3a"
                        autoplay={currentSongIndex === 1}
                        url={audioBlob}
                        onReady={(e) => onReady1(e)}
                        onPlay={() => setIsPlaying1(true)}
                        onPause={() => setIsPlaying1(false)}
                      />
                    </div> */}

                    <span onClick={() => setAudioBlob(undefined)}>
                      <RiDeleteBinLine
                        size={25}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <Form.Item label="" name="file_url">
                <Tooltip
                  title={trackTitle ? "" : "Please enter track name"}
                  color="#dcaa3a"
                >
                  <Upload
                    // customRequest={handleUploadAction}
                    customRequest={handleUploadFile}
                    showUploadList={false}
                    onChange={onFinish}
                    accept=".mp3,.flac,.wav"
                    previewFile={(blob) => {
                      console.log({ blob });
                    }}
                  >
                    <Button
                      disabled={!trackTitle || trackTitle === ""}
                      icon={
                        <SvgLoader
                          svgName="upload-track-outline-white-icon"
                          className="upload-white-icon"
                          height={40}
                          width={40}
                        />
                      }
                    >
                      <div>
                        <span style={{ color: "#fff" }}>
                          Choose a file or drag & drop it here
                        </span>{" "}
                        <br />
                        Supports MP3, FLAC, WAV formats, up to 50MB
                      </div>
                    </Button>
                  </Upload>
                </Tooltip>
              </Form.Item>
            )}
          </div>

          {/* {audioBlob && (
            <div className="checkbox-wrap-upload">
              <div className="track-details">
                Add License/ Terms and Conditions
              </div>
              {licenceBlob ? (
                <div className="file-upload-card">
                  <FaRegFileAlt size={50} color="#fff" />
                  <div className="file-upload-card-text">
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="file-upload-text"
                        style={{ fontSize: "20px" }}
                      >
                        {licenseName}
                      </span>

                      <span onClick={() => setLicenceBlob(undefined)}>
                        <RiDeleteBinLine
                          size={25}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </div>
                    <div className="file-upload-text-wrap">
                      <span
                        className="file-upload-text"
                        style={{ color: "#A9ACB4" }}
                      >
                        {licenceBlob
                          ? (licenceBlob?.size / 1000000).toFixed(2)
                          : "0"}{" "}
                        MB
                      </span>
                      <span className="file-upload-text">•</span>

                      <span className="file-upload-text">
                        <SvgLoader svgName={"done"} width={20} height={20} />
                        Completed
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <Form.Item label="" name="licence_url">
                  <Upload
                    // customRequest={handleUploadAction}
                    customRequest={handleUploadLicence}
                    showUploadList={false}
                    onChange={onFinish}
                    accept=".pdf"
                    previewFile={(blob) => {
                      console.log({ blob });
                    }}
                  >
                    <Button
                      icon={
                        <SvgLoader
                          svgName="upload-track-outline-white-icon"
                          className="upload-white-icon"
                          height={40}
                          width={40}
                        />
                      }
                    >
                      <div>
                        <span style={{ color: "#fff" }}>
                          Click to Upload or drag and drop
                        </span>{" "}
                        <br />
                        (Max. File size: 25 MB){" "}
                      </div>
                    </Button>
                  </Upload>
                </Form.Item>
              )}
            </div>
          )} */}
          <div style={{ margin: "1% 5%" }}>
            <div className="title-4">Select Purpose:</div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {themesList?.response?.themes?.map((item) => (
                <div style={{ width: "200px" }} key={item?.name}>
                  <Checkbox
                    className="collapse-checkbox"
                    onChange={(e) => onChangeThemeTopic(e, item?.name)}
                    checked={selectedPurpose.includes(item?.name)}
                  >
                    {item?.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
