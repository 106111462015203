/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import Header from "../../components/Header";
import SvgLoader from "../../common/SvgLoader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postSignupAction } from "./logic";
import { IoMdMusicalNote } from "react-icons/io";
import { toast } from "react-toastify";

function SignupPage() {
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState("signup");
  const dispatch = useDispatch();
  const signupResponse = useSelector((state) => state.signupData);
  const { error: signupDataError } = useSelector((state) => state.signupData);

  const handelRegisterUser = (e) => {
    dispatch(postSignupAction(e));
  };

  useEffect(() => {
    if (signupResponse.flag) {
      setShowContent("success");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }

    if (signupResponse.error) {
      toast.error(signupDataError?.message || "session expire please relogin", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [signupResponse]);

  return (
    <div className="login-page">
      <Header showSearch={false} />

      <div className="login-section">
        <div className="title-1">Brandmusiq | Soniqvault</div>
        <div className="title-2">
          Your studio in the cloud, for all things Soniq
        </div>
        {signupResponse.loading ? (
          <div className="login-content">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 48,
                  }}
                  spin
                />
              }
            />
          </div>
        ) : (
          <>
            {showContent === "signup" && (
              <Form name="basic" onFinish={handelRegisterUser}>
                <div className="login-content">
                  <div className="title-3">Sign Up</div>
                  <div style={{ color: "#fff" }}>
                    Please fill your information below
                  </div>
                  <Form.Item label="" name="name">
                    <Input
                      className="input-box"
                      placeholder="Name"
                      prefix={
                        <SvgLoader svgName="Profile" height={20} width={20} />
                      }
                    />
                  </Form.Item>
                  <Form.Item label="" name="organization_name">
                    <Input
                      className="input-box"
                      placeholder="Organization Name"
                      prefix={
                        <SvgLoader svgName="company" height={20} width={20} />
                      }
                    />
                  </Form.Item>
                  {/* <Form.Item label="" name="client">
                    <Select
                      placeholder={
                        <>
                          <IoMdMusicalNote size={25} />
                          &nbsp; Select Client
                        </>
                      }
                      // showSearch
                      // style={{
                      //     width: 200,
                      // }}
                      optionFilterProp="label"
                      // filterSort={(optionA, optionB) =>
                      //   (optionA?.value ?? "")
                      //     .toLowerCase()
                      //     .localeCompare((optionB?.value ?? "").toLowerCase())
                      // }
                      options={[
                        {
                          value: "Infosys",
                          label: (
                            <>
                              <IoMdMusicalNote size={20} />
                              &nbsp; Infosys
                            </>
                          ),
                        },
                        {
                          value: "Bmq",
                          label: (
                            <>
                              <IoMdMusicalNote size={20} />
                              &nbsp; BMQ
                            </>
                          ),
                        },
                      ]}
                    />
                  </Form.Item> */}
                  <Form.Item
                    label=""
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input
                      className="input-box"
                      placeholder="Business E-mail"
                      prefix={
                        <SvgLoader svgName="email" height={20} width={20} />
                      }
                    />
                  </Form.Item>
                  <Form.Item label="" name="purpose">
                    <Input
                      className="input-box"
                      placeholder="Purpose of Use"
                      prefix={<IoMdMusicalNote size={20} />}
                    />
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="ref_email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input
                      className="input-box"
                      placeholder="Referee E-mail"
                      prefix={
                        <SvgLoader svgName="Profile" height={20} width={20} />
                      }
                    />
                  </Form.Item>

                  <Button className="login-btn" htmlType="submit">
                    Sign Up
                    <RightOutlined />
                  </Button>
                  <div
                    style={{
                      borderTop: "1px solid #ECECF0",
                      width: "450px",
                      margin: "10px",
                    }}
                  ></div>
                  <div className="login-forgot">
                    <span style={{ color: "#fff" }}>
                      Already have an account?
                    </span>
                    <span
                      style={{ cursor: "pointer", color: "#dcaa3a" }}
                      onClick={() => navigate("/login")}
                    >
                      Login to your account
                    </span>
                  </div>
                </div>
              </Form>
            )}

            {showContent === "success" && (
              <div className="login-content">
                <SvgLoader svgName="active-icon" height={150} width={150} />
                <div className="title-3" style={{ textAlign: "center" }}>
                  Thank you for your request for <br />
                  access to the Soniq Vault
                </div>
                <div style={{ textAlign: "center" }}>
                  On approval, you will receive your access to the <br />
                  registered email id.
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SignupPage;
