/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import { Button, Col, Divider, Modal, Row, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import SvgLoader from "../../common/SvgLoader";
import axios from "axios";
import { getCookie, handleLogout } from "../../common/utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getTracksReceivedListAction } from "../../pages/ClientAdmin/logic";
import ReactApexChart from "react-apexcharts";
import { HiPause, HiPlay } from "react-icons/hi";
import WavesurferPlayer from "@wavesurfer/react";
import Loader from "../Loader/Loader.jsx";
import { FaInfoCircle } from "react-icons/fa";

export default function TracksReceivedDetailsModal({
  selectedTrackReceived,
  showTrackReceivedDetailsModal,
  setShowTrackReceivedDetailsModal,
}) {
  const dispatch = useDispatch();

  // const tracks = [
  //   {
  //     id: 1,
  //     title: 'Track Option 1',
  //     isCompleted: true,
  //     size: 2.3,
  //   },
  //   {
  //     id: 2,
  //     title: 'Track Option 2',
  //     isCompleted: true,
  //     size: 2.3,
  //   },
  //   {
  //     id: 3,
  //     title: 'Track Option 3',
  //     isCompleted: true,
  //     size: 2.3,
  //   },
  // ];

  // const reasons = [
  //   {
  //     id: 1,
  //     reason: 'Track Option Sent not Matching',
  //   },
  //   {
  //     id: 2,
  //     reason: 'Duration Mismatch',
  //   },
  //   {
  //     id: 3,
  //     reason: 'Zone of Sound/Moods Mismatch',
  //   },
  // ];

  useEffect(() => {
    if (selectedTrackReceived?.id) {
      getTrackOptions();
    }
  }, [selectedTrackReceived]);

  const [trackOptions, setTrackOptions] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState(false);
  const [showTrackSelection, setShowTrackSelection] = useState(true);
  const [showRejectionReasons, setShowRejectionReasons] = useState(false);
  // const [selectedReason, setSelectedReason] = useState(1);
  const [showViewMoreTrackDetails, setShowViewMoreTrackDetails] =
    useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [currentSongIndex, setCurrentSongIndex] = useState();
  const [trackLoading, setTrackLoading] = useState(false);

  const getTrackOptions = async () => {
    console.log({ selectedTrackReceived });
    try {
      const response = await axios.get(
        `${process.env.apiUrl}admin/trackOptionClientAdmin?brief_request_id=${
          selectedTrackReceived?.brief_request_id
            ? selectedTrackReceived?.brief_request_id
            : selectedTrackReceived?.id
        }&is_agency_user=${selectedTrackReceived?.is_agency_user}`,
        {
          headers: {
            Authorization: getCookie("accessToken"),
            accept: "application/json",
          },
        }
      );
      if (response.data.status == 200 && response.data.response) {
        setTrackOptions(response.data.response);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      }
      // console.log("Error in gettign track options", err);
    }
  };

  const approveTrack = async () => {
    try {
      let body = {
        track_ids: [selectedTrack],
        track_status: "APPROVED",
        brief_request_id: trackOptions && trackOptions[0]?.brief_request_id,
        reject_reason: "",
      };

      const response = await axios.patch(
        `${process.env.apiUrl}admin/trackOptionClientAdmin`,
        body,
        {
          headers: {
            Authorization: getCookie("accessToken"),
            accept: "application/json",
          },
        }
      );
      // console.log('Track approved Successfully', response.data.response);
      if (response.data.status == 200) {
        // console.log("reasons", response);
        toast.success(
          response?.data?.message || "Track Approved Successfully",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
        setShowTrackReceivedDetailsModal(false);

        // refresh list
        dispatch(getTracksReceivedListAction());
      }
    } catch (err) {
      // console.log("Error in approving track", err);
      if (err?.response?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      } else {
        toast.error("Error in Approving Track", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const rejectAllReceivedTracks = async () => {
    console.log({ selectedTrackReceived });
    try {
      let body = {
        track_ids: [],
        track_status: "REJECTED",
        brief_request_id: selectedTrackReceived?.brief_request_id
          ? selectedTrackReceived?.brief_request_id
          : selectedTrackReceived?.id,
        reject_reason: rejectionReason,
      };
      let track_ids = [];

      trackOptions.forEach((e) => {
        track_ids.push(e.id);
      });

      body.track_ids = track_ids;

      // console.log("body", body);
      const response = await axios.patch(
        `${process.env.apiUrl}admin/trackOptionClientAdmin`,
        body,
        {
          headers: {
            Authorization: getCookie("accessToken"),
            accept: "application/json",
          },
        }
      );

      // console.log("Track rejected Successfully", response.data);
      if (response.data.status == 200) {
        // console.log("reasons", response);
        toast.success(
          response?.data?.message || "Tracks Rejected Successfully",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
        setShowRejectionReasons(false);
        setShowTrackReceivedDetailsModal(false);
        setShowTrackSelection(true);

        // refresh list
        dispatch(getTracksReceivedListAction());
      }
    } catch (err) {
      // console.log("Error in rejecting all tracks", err);
      if (err?.response?.status === 401) {
        toast.error("Session Expired. Please login again");
        handleLogout();
      } else {
        toast.error("Error in Rejecting All Tracks", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const handleTracksReceivedAccept = (e) => {
    // console.log("Accept this track", selectedTrack);
    approveTrack();
  };

  const handleTracksReceivedRejectAll = () => {
    // console.log("Reject All tracks clicked");
    // show rejection reason screen with submit button
    setShowTrackSelection(false);
    setShowRejectionReasons(true);
  };

  const handleTrackChange = (e) => {
    // console.log("Track Selected: ", e.target.value);
    setSelectedTrack(e.target.value);
  };

  // const handleRejectionReasonChange = (e) => {
  //   console.log('Reason Selected: ', e.target.value);
  //   setSelectedReason(e.target.value);
  // };

  const handleSubmitRejectionWithReason = (e) => {
    // console.log('handleSubmitRejectionWithReason called: ', selectedReason);
    // after api call response close clear states, close modal
    rejectAllReceivedTracks();
  };

  const [rasaPredictionsKeys, setRasaPredictionsKeys] = useState([]);
  const [rasaPredictionsValues, setRasaPredictionsValues] = useState([]);
  const [uploadedTracksDetail, setUploadedTracksDetail] = useState();
  const [currentView, setCurrentView] = useState("details");
  const [heading, setHeading] = useState();
  const [thirdDetail, setThirdDetail] = useState();
  const blankAudio = "/music/silent_quarter-second.mp3";
  const [audioBlob, setAudioBlob] = useState();
  const [audioBlob1, setAudioBlob1] = useState();
  const [wavesurfer, setWavesurfer] = useState(null);
  const [wavesurfer1, setWavesurfer1] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaying1, setIsPlaying1] = useState(false);

  useEffect(() => {
    console.log({ uploadedTracksDetail });
    if (uploadedTracksDetail) {
      axios
        .get(
          `${process.env.apiUrl}playTrackOption?track_id=${uploadedTracksDetail?.id}`,
          {
            headers: {
              Authorization: getCookie("accessToken"),
            },
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const blob = new Blob([response.data]);

          setAudioBlob(URL.createObjectURL(blob));
        })
        .catch((error) => {
          // console.log(error);
          if (error?.response?.status === 401) {
            toast.error("Session Expired. Please login again");
            handleLogout();
          }
        });
    }
  }, [uploadedTracksDetail, currentView, showTrackSelection]);

  const handleViewMore = (e) => {
    // console.log({ e });
    setIsPlaying1(false);
    setWavesurfer1();
    setCurrentSongIndex();
    setShowTrackSelection(false);
    setShowViewMoreTrackDetails(true);
    setRasaPredictionsKeys(Object?.keys(e?.data?.rasa_predictions) || []);
    setRasaPredictionsValues(Object?.values(e?.data?.rasa_predictions) || []);
    setUploadedTracksDetail(e);
  };

  const config = {
    options: {
      plotOptions: {
        radar: {
          polygons: {
            strokeColor: "#ffffff",
            strokeWidth: 2,
          },
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            colors: ["#ffffff"],
            fontSize: "12px",
            fontFamily: "Montserrat-Regular",
            fontWeight: 600,
          },
          offsetY: 5,
        },
        range: 1,
      },
      yaxis: {
        show: false,
        // stepSize: 0.1,
      },

      colors: ["#17B3FF"],
      labels: rasaPredictionsKeys,
      stroke: {
        width: 2,
        colors: ["#ffffff"],
      },
      fill: {
        opacity: 0.6,
      },

      markers: {
        size: 4,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
            <div class="arrow_box"> 
                <span>
                    ${w.globals.labels[dataPointIndex]} 
                        : 
                    ${series[seriesIndex][dataPointIndex]} 
                </span>
            </div>`;
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
    series: [
      {
        name: "",
        data: rasaPredictionsValues,
      },
    ],
  };

  const onPlayPause = () => {
    wavesurfer?.playPause();
  };

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  const handleBack = () => {
    setIsPlaying(false);
    setWavesurfer();
    setCurrentSongIndex();

    if (currentView === "details") {
      setShowViewMoreTrackDetails(false);
      setShowTrackSelection(true);
    } else if (currentView === "third") {
      setCurrentView("details");
    }
    // else if (currentView === "upload") {
    //   setShowUploadModal(false);
    // }
  };

  const handlePlayCurrent = (song, idx) => {
    // wavesurfer && wavesurfer.playPause();
    setCurrentSongIndex(idx);

    setTrackLoading(true);

    axios
      .get(`${process.env.apiUrl}playTrackOption?track_id=${song?.id}`, {
        headers: {
          Authorization: getCookie("accessToken"),
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        setIsPlaying1(true);
        const blob = new Blob([response.data]);
        setAudioBlob1(URL.createObjectURL(blob));
        setTrackLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.statusText);
        if (err?.response?.status === 401) {
          toast.error("Session Expired. Please login again");
          handleLogout();
        }
        setTrackLoading(false);
      });
  };

  const onReady1 = (ws) => {
    setWavesurfer1(ws);
    setIsPlaying1(true);
  };

  const onPlayPause1 = () => {
    wavesurfer1?.playPause();
    // setCurrentSongIndex(null);
  };
  return (
    <div className="tracks-received-details-modal">
      <Modal
        wrapClassName="tracks-received-details-modal-wrap"
        title=""
        centered
        open={showTrackReceivedDetailsModal}
        onOk={() => setShowTrackReceivedDetailsModal(false)}
        onCancel={() => setShowTrackReceivedDetailsModal(false)}
        width={750}
        footer={false}
        closable={true}
        afterClose={() => {
          setSelectedTrack(false);
          setShowTrackSelection(true);
          setShowViewMoreTrackDetails(false);
          setShowRejectionReasons(false);
          setIsPlaying1(false);
          setWavesurfer1();
          setIsPlaying(false);
          setWavesurfer();
          setCurrentSongIndex();
          wavesurfer1?.pause();
          wavesurfer?.pause();
        }}
      >
        <div className="title-wrapper">
          <SvgLoader svgName="track-upload-icon" height={60} width={60} />
          <div className="title" title={selectedTrackReceived?.title}>
            {selectedTrackReceived?.title}
          </div>
        </div>

        <Divider />

        {!showViewMoreTrackDetails && (
          <div className="prompt">
            {showRejectionReasons
              ? "Provide the reason for Rejection"
              : "Kindly Approve the preferred track from the below options"}
          </div>
        )}

        {showTrackSelection &&
          Array.isArray(trackOptions) &&
          trackOptions.length > 0 && (
            <div className="track-selection-wrapper">
              {trackOptions.map((el, idx) => {
                return (
                  <div key={el?.id} className="track-radio-card">
                    <label className="radio-label">
                      <input
                        type="radio"
                        name="selected-track"
                        value={el.id}
                        onChange={handleTrackChange}
                        checked={el.id == selectedTrack}
                      />
                      <span className="custom-radio"></span>
                    </label>
                    <SvgLoader
                      svgName="track-thumbnail"
                      height={69}
                      width={69}
                    />
                    <div className="track-radio-card-text">
                      <div className="track-title">{el.title}</div>
                      <div className="file-upload-text-wrap">
                        <span
                          className="file-upload-text"
                          style={{ color: "#A9ACB4" }}
                        >
                          {/* {false ? (2000 / 1000000).toFixed(1) : '0'} MB */}
                          {(el.size / 1000000).toFixed(1)} MB
                          {/* {el.size} MB */}
                        </span>
                        <span className="file-upload-text">•</span>

                        <span className="file-upload-text">
                          {el.bpm == "0.0" ? (
                            <>Uploaded</>
                          ) : (
                            <>
                              <SvgLoader
                                svgName={"done"}
                                width={20}
                                height={20}
                              />
                              Completed
                            </>
                          )}
                        </span>
                        {el.bpm != "0.0" && (
                          <span
                            className="file-upload-text"
                            style={{ color: "#dcaa3a", cursor: "pointer" }}
                            onClick={() => handleViewMore(el)}
                          >
                            View More
                          </span>
                        )}
                      </div>
                    </div>
                    <div>
                      {trackLoading && currentSongIndex === idx ? (
                        <div style={{ width: "10%" }}>
                          <Loader height={0} marginTop={-5} />
                        </div>
                      ) : (
                        currentSongIndex === idx &&
                        (isPlaying1 === true ? (
                          <HiPause
                            className="pause-btn"
                            size={40}
                            color="#fff"
                            onClick={() => onPlayPause1(el, idx)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <HiPlay
                            size={40}
                            color="#fff"
                            onClick={() => onPlayPause1(el, idx)}
                            style={{ cursor: "pointer" }}
                          />
                        ))
                      )}
                      {currentSongIndex !== idx && (
                        <HiPlay
                          size={40}
                          color="#fff"
                          onClick={() => handlePlayCurrent(el, idx)}
                          style={{ cursor: "pointer" }}
                        />
                      )}

                      <WavesurferPlayer
                        height={0}
                        width={0}
                        waveColor="#363636"
                        progressColor="#dcaa3a"
                        autoplay={currentSongIndex === idx}
                        url={currentSongIndex === idx ? audioBlob1 : blankAudio}
                        onReady={(e) => onReady1(e)}
                        onPlay={() => setIsPlaying1(true)}
                        onPause={() => setIsPlaying1(false)}
                      />
                    </div>
                    {el?.purpose && el?.purpose?.length > 0 && (
                      <Tooltip
                        title={`Purpose: ${el?.purpose.join(", ")}`}
                        color="#dcaa3a"
                      >
                        <FaInfoCircle size={40} color="#dcaa3a" />
                      </Tooltip>
                    )}
                  </div>
                );
              })}
            </div>
          )}

        {showTrackSelection && (
          <Row className="tracks-received-modal-buttons">
            <Col span={7}></Col>
            <Col span={5}>
              <Button
                disabled={!selectedTrack || selectedTrack.length == 0}
                onClick={handleTracksReceivedAccept}
                className="trm-btn-approve"
                size="large"
              >
                Approve
              </Button>
            </Col>
            <Col className="trm-btn-reject" span={5}>
              <Button onClick={handleTracksReceivedRejectAll} size="large">
                Reject All
              </Button>
            </Col>
            <Col span={7}></Col>
          </Row>
        )}

        {showRejectionReasons && (
          <div className="rejection-reason-wrapper">
            {/* Radio buttons - Done */}
            {/* <div className="rejection-reasons-radio-list">
              {reasons.map((el) => {
                return (
                  <div className="reason-radio-div">
                    <label className="radio-label">
                      <input
                        type="radio"
                        name="selected-reason"
                        value={el.id}
                        onChange={handleRejectionReasonChange}
                        checked={el.id == selectedReason}
                      />
                      <span class="custom-radio"></span>
                      <span className="reason-text">{el.reason}</span>
                    </label>
                  </div>
                );
              })}
            </div> */}

            {/* Text Area - Done*/}
            <div className="other-reason-wrapper">
              <label htmlFor="other-reason" className="other-reason-label">
                Enter reason/comments
              </label>
              <br />
              <textarea
                className="other-reason-textarea"
                name="other-reason"
                rows="5"
                cols="46"
                placeholder="Enter your comments"
                value={rejectionReason}
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                }}
              ></textarea>
            </div>

            {/* Submit button */}
            <div className="rejection-reason-btn-wrapper">
              <Row className="tracks-received-modal-buttons">
                <Col span={4}>
                  <Button
                    disabled={!rejectionReason}
                    onClick={handleSubmitRejectionWithReason}
                    className="trm-btn-approve"
                    size="large"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}

        {showViewMoreTrackDetails && currentView === "details" && (
          <div className="radar-and-player">
            <div className="radar-and-tags" style={{ display: "flex" }}>
              <ReactApexChart
                options={config.options}
                series={config.series}
                type="radar"
                height="300"
                width={300}
                className="apex-chart-mood-radar"
              />

              <img
                src="/images/partition-line.png"
                style={{ height: "300px" }}
                alt=""
              />

              <div className="tags-wrapper">
                <div className="tag-line-wraper">
                  <div className="tag-title">Moods: </div>

                  {uploadedTracksDetail?.data?.mood_prob &&
                    Object?.entries(uploadedTracksDetail?.data?.mood_prob)
                      ?.slice(0, 3)
                      ?.map((item) => (
                        <Tag
                          color="#dcaa3a"
                          style={{ color: "#000" }}
                          key={item[0]}
                        >
                          {item[0]}
                        </Tag>
                      ))}
                  <div
                    className="tag-view-more"
                    onClick={() => {
                      setCurrentView("third");
                      setHeading("Moods");
                      setThirdDetail(uploadedTracksDetail?.data?.mood_prob);
                    }}
                  >
                    View More
                  </div>
                </div>
                <div className="tag-line-wraper">
                  <div className="tag-title">Genre: </div>
                  {uploadedTracksDetail?.data?.genre_class &&
                    Object?.entries(uploadedTracksDetail?.data?.genre_class)
                      ?.slice(0, 2)
                      ?.map((item, idx) => (
                        <Tag color="#603FDD" key={item[0]}>
                          {item[0]}
                        </Tag>
                      ))}
                  <div
                    className="tag-view-more"
                    onClick={() => {
                      setCurrentView("third");
                      setHeading("Genre");
                      setThirdDetail(uploadedTracksDetail?.data?.genre_class);
                    }}
                  >
                    View More
                  </div>
                </div>
                {/* <div className="tag-line-wraper">
                  <div className="tag-title">Theme: </div>
                  {uploadedTracksDetail?.data?.theme_prob &&
                    Object?.entries(uploadedTracksDetail?.data?.theme_prob)
                      ?.slice(0, 3)
                      ?.map((item, idx) => (
                        <Tag color="#AD2756" key={item[0]}>
                          {item[0]}
                        </Tag>
                      ))}
                  <div
                    className="tag-view-more"
                    onClick={() => {
                      setCurrentView("third");
                      setHeading("Theme");
                      setThirdDetail(uploadedTracksDetail?.data?.theme_prob);
                    }}
                  >
                    View More
                  </div>
                </div> */}
              </div>
            </div>
            <Divider />

            <div className="music-player-wrap">
              <div className="track-title">
                {console.log(uploadedTracksDetail)}
                {uploadedTracksDetail?.title
                  ? uploadedTracksDetail?.title
                  : "Music Title"}
              </div>
              {/* <div className="track-details">
              <span>
                <div>
                  Genre: {allTrackData?.GENRE?.map((item) => item + ", ")}
                </div>
                <div>
                  Rasa: {allTrackData?.RASA?.map((item) => item + ", ")}
                </div>
              </span>
              <span style={{ borderRight: "1px solid #fff" }}></span>
              <span>
                <div>BPM: {allTrackData?.BPM}</div>
              </span>
            </div> */}

              <div className="music-player-wave-wraper">
                {audioBlob &&
                  (isPlaying === true ? (
                    <HiPause
                      className="pause-btn"
                      size={40}
                      color="#fff"
                      onClick={() => onPlayPause()}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <HiPlay
                      size={40}
                      color="#fff"
                      onClick={() => onPlayPause()}
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                <div>
                  <div>
                    <WavesurferPlayer
                      height={60}
                      waveColor="#363636"
                      progressColor="#dcaa3a"
                      interact={audioBlob}
                      autoplay={false}
                      url={audioBlob || blankAudio}
                      onReady={(e) => onReady(e)}
                      onPlay={() => setIsPlaying(true)}
                      onPause={() => setIsPlaying(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="upload-back-btn-wraper">
              <Button className="upload-back-btn" onClick={handleBack}>
                Back
              </Button>
            </div>
          </div>
        )}

        {showViewMoreTrackDetails && currentView === "third" && (
          <div style={{ color: "#ffffff" }} className="view-more-tags-modal">
            <h3>{heading}</h3>
            {thirdDetail ? (
              <div style={{ height: "60vh", overflow: "auto" }}>
                {Object.entries(thirdDetail).map(([key, value]) => (
                  <>
                    <div className="value-wrap" key={key}>
                      <p className="capitalize-first-letter">{key}:</p>
                      <p>{(value + 0).toFixed(4)}</p>
                      {/* <hr /> */}
                    </div>
                    <Divider />
                  </>
                ))}
              </div>
            ) : (
              <div>No Data Found</div>
            )}
            <div className="upload-back-btn-wraper">
              <Button className="upload-back-btn" onClick={handleBack}>
                Back
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
